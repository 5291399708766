import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';
import { useSocket } from '../../hooks/Socket';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button/styles';
import { FaTrophy } from 'react-icons/fa';

interface SignInData {
  email: string;
  password: string;
}

interface IGame {
  name : string;
  points : number;
}

const SignIn: React.FC = () => {
  
  const {socket} = useSocket();
  const [gameList, setGameList] = useState<Array<IGame>>([]);

useEffect(() => {

socket.emit('requestGamingList');

socket.on('receiveGamilingList', (data) => {

  setGameList(data)
})

},[])

const getTrophy = (number ) => {

  if(number === 0){
    return <FaTrophy color="gold" size={30} style={{marginRight: '10px'}}/>
  }
  if(number === 1){
    return <FaTrophy color="silver" size={30} style={{marginRight: '10px'}}/>
  }
  if(number >= 2 && number <= 9 ){
    return <FaTrophy color="bronze" size={30} style={{marginRight: '10px'}}/>
  }


  return <></>;

}

  return (
    <>
   <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}> <Link to="/voce-no-congresso"><Button>RANKING</Button></Link>
   <Link to="/voce-no-congresso-regulamento"><Button>PREMIAÇÃO E REGULAMENTO</Button></Link>
    </div>
    <Container>

<h1 style={{width:'100%', margin: '40px 10px', textAlign:'center'}}>RANKING GERAL</h1>
      <table className="table">
        <tbody>
        {gameList.map( (usuario,index) => <tr><td><div style={{display: 'flex', alignItems: 'center'}}> {getTrophy(index)} {usuario.name}</div></td><td style={{textAlign:'center'}}>{usuario.points} ponto(s) </td></tr>)}
          
        </tbody>
      </table>
    </Container>
    </>
  );
};
export default SignIn;

import React, { ButtonHTMLAttributes } from 'react';

import { Button as StyledButton } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return <button className="defaultButton" {...rest}>{children}</button>;
};

export default Button;

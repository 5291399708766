export const content = [
    "A ADVOCACIA NO ENGAJAMENTO DA REFORMA POLÍTICA: CONHECENDO O SISTEMA ELEITORAL DISTRITAL",
    "A INCLUSÃO NO ENSINO SUPERIOR SE FAZ COM POLÍTICAS AFIRMATIVAS",
    "A LGPD E SEUS REFLEXOS NA PROPRIEDADE INTELECTUAL",
    "A TECNOLOGIA A SERVIÇO DOS DIREITOS HUMANOS NO ATENDIMENTO PELA ADVOCACIA DAS PESSOAS PRIVADAS DE LIBERDADE",
    "A VIRTUALIZAÇÃO DA PUBLICIDADE PROFISSIONAL: NOVAS POSSIBILIDADES COM O PROVIMENTO 205/2021",
    "AS NOVAS TECNOLOGIAS E O DIREITO DO CONSUMIDOR: INTELIGÊNCIA ARTIFICIAL, TRATAMENTO DE DADOS E O METAVERSO",
    "ATUAÇÃO PRÁTICA E JURÍDICA NOS CONSELHOS DE CLASSE",
    "AUTOCOMPOSIÇÃO: INOVAÇÕES RUMO À REDUÇÃO DA LITIGIOSIDADE  ",
    "BULLYING E CYBERBULLYING",
    "COMPLIANCE PARA ESCRITÓRIOS DE ADVOCACIA",
    "CONTRATOS DE HONORÁRIOS NA ERA DIGITAL",
    "DESAFIOS DA ARBITRAGEM NO AGRONEGÓCIO",
    "DESAFIOS PARA ALTA PERFORMANCE DO JURÍDICO CORPORATIVO",
    "EDUCAÇÃO JURÍDICA E EXAME DE ORDEM: INTERSECÇÃO E INOVAÇÃO JURÍDICA",
    "EMPRESAS E DIREITOS HUMANOS: A APLICAÇÃO DE ESG À LUZ DA AGENDA 2030 E O CAPITALISMO STAKEHOLDERS",
    "ESG, MUDANÇAS CLIMÁTICAS E INVESTIMENTOS",
    "FERRAMENTAS DE PRIVACIDADE PARA A ADVOCACIA",
    "FINTECHS, STARTUPS FINANCEIRAS E A ADVOCACIA NA ATUALIDADE",
    "GESTÃO E TECNOLOGIA COMO DIFERENCIAL NA ADVOCACIA",
    "IA E PODER JUDICIÁRIO. PERSPECTIVAS DOS EFEITOS DO USO PARA FINS DE DECISÃO E A ATIVIDADE DO ADVOGADO",
    "IMPRENSA LIVRE E DESINFORMAÇÃO NO PERÍODO ELEITORAL",
    "O EXERCÍCIO DA ADVOCACIA COMO ELEMENTO INDISPENSÁVEL À CONSECUÇÃO DA SEGURANÇA PÚBLICA.  ",
    "O HOMEM, A ADVOCACIA E OS DIREITOS DOS ANIMAIS",
    "O PAPEL DA ADVOCACIA NA DEFESA DA FEDERAÇÃO PERANTE O SUPREMO TRIBUNAL FEDERAL",
    "O PRINCÍPIO DA NÃO-DISCRIMINAÇÃO NO TRATAMENTO DE DADOS PESSOAIS",
    "OS DESAFIOS DA ADVOCACIA TRABALHISTA NO AMBIENTE DIGITAL",
    "PAINEL EXPOSITIVO DIREITOS HUMANOS ",
    "PERSPECTIVAS DA INSOLVÊNCIA EMPRESARIAL PARA ATUAÇÃO DOS ADVOGADOS",
    "PERSPECTIVAS PARA ATUAÇÃO DO ADVOGADO PREVIDENCIARISTA E TRIBUTARISTA",
    "PRERROGATIVAS DA ADVOCACIA E A LEI DO ABUSO DE AUTORIDADE: PERSPECTIVAS A PARTIR DA VIGÊNCIA DA LEI Nº 14.365/22",
    "SEGURO, TRATAMENTO DE DADOS PESSOAIS, DIREITOS DOS SEGURADOS E A ADVOCACIA",
    "SOCIEDADE ANÔNIMA DO FUTEBOL – SAF: Perspectivas e Desafios",
    "SUSTENTABILIDADE E OS DIREITOS HUMANOS",
    "TÉCNICAS DE COMUNICAÇÃO E NEGOCIAÇÃO",
    "TENDÊNCIAS E OPORTUNIDADES NO DIREITO MARÍTIMO E AERONÁUTICO",
    "TERCEIRO SETOR: DESAFIOS, TRIBUTAÇÃO E NOVAS PERSPECTIVAS",
    "TOKENIZAÇÃO DE NEGÓCIOS IMOBILIÁRIOS - ASPECTOS REGISTRAIS",
    "TRABALHANDO COM DIREITO INTERNACIONAL ECONÔMICO/COMÉRCIO INTERNACIONAL.",
    "TRIBUTAÇÃO E ESCRITÓRIOS DE ADVOCACIA: MÉTODOS, PERSPECTIVAS E DESAFIOS",
    "X ENCONTRO DE ADVOGADOS PREVIDENCIARISTAS"


]
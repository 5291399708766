import React from 'react';
import { FiCheckCircle, FiXCircle, FiInfo } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { Container, Header } from './styles';

export interface IResponseMessage {
  title: string;
  description?: JSX.Element;
  type: 'error' | 'success' | 'info' | 'clean';
  icon?: Function;
  headerMessage?: JSX.Element;
  active?: boolean;
}

export const ResponseMessage: React.FC<IResponseMessage> = ({
  title,
  description,
  type,
  icon,
  headerMessage,
  active,
}) => {
  const icons = {
    error: <FiXCircle />,
    success: <FiCheckCircle />,
    info: <FiInfo />,
    clean: '',
  };

  const messages = {
    error: 'Erro!',
    success: 'Sucesso!',
    info: 'Informação',
    clean: '',
  };

  return (
    <Container type={type} className="shadow">
      <Header>{icon ? icon() : icons[type]}</Header>
      <h3>{title}</h3>
      {description && <p>{description}</p>}
    </Container>
  );
};

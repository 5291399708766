import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
  FaChevronDown,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import AreaExposicao from '../AreaExposicao';
import AreaInstitucional from '../AreaInstitucional';
import { ContactIcons, scrollTo, WhatsappIcon } from '../../components/Menu';
import Whatsapp from '../../components/Whatsapp';
import { logo, authTitle } from '../../config';
import {
  Container,
  SecondaryContainer,
  ContainerWhite,
  GreyContainer,
  Capa,
  ProgramContainer,
  Sponsors,
  Photo,
} from './styles';

import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useModal } from '../../hooks/Modal';


import Publicas from './modules/publicas';

import { usePages } from '../../hooks/Pages';

import SponsorsList from '../Sponsors';
import Program from '../Program';
import Youtube from '../../components/Youtube';
import SignIn from '../SignIn';
import Speakers from '../Speakers';
import Apresentacao from '../SobrePresidente';
import LeadForm from '../LeadForm';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Home: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const formRef = useRef<FormHandles>(null);
  const [cardLayout, setCardLayout] = useState([] as Array<JSX.Element>);
  const { readPages } = usePages();

  const [showForm,setShowForm] = useState(false)

  const [pages, setPages] = useState(readPages());

  const handleSubmit = useCallback(
    async (data: SignInData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Preencha um email válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const { email, password } = data;

        await signIn({ email, password });
        history.push('/dashboard');
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login',
        });
      }
    },
    [signIn, addToast, history],
  );

  const buildCard: React.FC<IPublicData> = ({ icon, title, text }) => {
    return (
      <div
        onClick={() =>
          setCardLayout([<Publicas icon={icon} text={text} title={title} />])}
      >
        {icon({})}
        <p>{title}</p>
      </div>
    );
  };

  return (
    <>
      <Capa>
        <div style={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
        <div style={{padding: '0px', display:'flex', alignItems:'center', justifyContent:'center', position:'relative', flexWrap:'wrap'}} >



          
        <img className='fadeInAnimation' src="/apoio/capa3.png" style={{width: '100%', zIndex: 10, minWidth: '250px', opacity: 1, maxWidth: '800px', margin: '10px'}}/>
    
        <img className='fadeInAnimation' src="/apoio/data.png" style={{width: '30%', maxWidth: '250px', position: 'absolute', top: '20px', right: '0px', opacity: 1,  margin: '10px'}}/>
        

        </div>
       
 
       
  
        </div>
        <div className="p50 "  style={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center', minHeight:'auto', maxWidth:'500px', width: '100%',   }}>
        <div className='module'  style={{ display: 'flex', marginBottom: '30px', flexDirection:'column', alignItems: 'center', justifyContent:'center', minHeight:'auto',   width: '100%',   }}>
 
 <h2 style={{color: '#fff', fontSize: '20px'}}>Multiverso Experience</h2>
 <p style={{color: '#fff'}}>Cais do Embarcadero - Porto Alegre</p>

 </div>
     <div  style={{ display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent:'center', minHeight:'auto', maxWidth:'500px', width: '100%',   }}>
      <Link to="#inscricao" className='flyAnimation' onClick={(e) => scrollTo(e,'inscricao')} style={{display: 'flex', alignItems:'center', justifyContent:'center', width: '150px'}}>
<FaChevronDown className='arrow' style={{cursor:'pointer'}}   size={80}/>
</Link>
      
        <Link to="#inscricao" className="titleCapa" onClick={(e) => scrollTo(e,'inscricao')}>

          <p>Fique por dentro</p>
          <p>do maior evento</p>
          <p>da advocacia gaúcha:</p>

          
        </Link>
        </div>
</div>
    
      </Capa>
      <Capa id="inscricao">
      <div  style={{ display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent:'center', minHeight:'auto',   width: '100%',   }}>
 
      
        <div className="titleCapa2 computerOnly" style={{position: 'relative' }}  >

          <p>Seja o primeiro</p>
          <p>a receber</p>
          <p>nossas novidades!</p>

         
        
        </div>
       
</div>
<LeadForm/>
<img  src="/apoio/capa5.png" style={{width: '70%', opacity: 0.3,   minWidth: '250px',  position: 'absolute', bottom: '0px', left: '0px', zIndex: -1, margin: '0px 10px'}}/>
    
</Capa>  
   {/* <Apresentacao/> */}
   
    </>
  );
};
export default Home;

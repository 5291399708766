import styled from 'styled-components';
import { shade } from 'polished';
import { defaultTheme } from '../../config';

export const Button = styled.button`
  background: rgb(107, 2, 147) !important;
  color: ${defaultTheme.defaultColor} !important;
  height: 56px;

  border-radius: 0px;
  border: 0;
  padding: 0 5px;
  min-width: 100px;
  max-width: 300px;
  font-weight: 500;
  margin: auto;
  margin-top: 5px;

  transition: 0.2s all;

  border: 1px solid ${defaultTheme.defaultColor} !important;
  border-radius: 10px;
  margin-right: 5px !important;
  margin-left: 5px !important ;
  &:hover {
    background: ${defaultTheme.defaultColor} !important;
    color: rgb(107, 2, 147) !important;
  }
`;

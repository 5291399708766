import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../../../../components/ResponseMessage';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { Error } from './styles';
import {AnimatedDiv} from '../styles';

import api from '../../../../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../../../../config';

import { useToast } from '../../../../../../hooks/Toast';
import CheckboxInput from '../../../../../../components/Forms/CheckboxInput';

import { useLanguage } from '../../../../../../hooks/Language';

interface ICaptcha {
  getValue(): string;
}

interface IRecoverFormData {
  [key : string] : any;
}


interface IRead {
  [key: string]: any;
}

interface IOption{
  value: string;
  label: string;
}


interface ICaptcha {
  getValue(): string;
}

interface IParams {
  hash : string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const {hash } = useParams<IParams>();

  const [failure, setFailure] = useState(false);
  const [ready, setReady] = useState(false);

  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

const [term,setTerm] = useState({
  hash : '',
  header_text : '',
  term: ''
})

  const load = async () => {
    setLoading(true);

    if(hash){
      try{
    const response = await api.get(`/get-term/${hash}`);
    setTerm(response?.data);
    setLoading(false);
    setReady(true);
  }
  catch(err){
    setFailure(true);
    setLoading(false);
  }
    }
  }

useEffect(() => {
  load();
},[hash])

  const {addToast} = useToast();
  const {translate} = useLanguage();

  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');





  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('E-mail obrigatório')
          .email('Preencha um email válido'),

        document_number: Yup.string().required('CPF é obrigatório'),
        term: Yup.string().required('Pedimos que confirme se aceita o termo'),
      
      });
 

    
    data.term = data.term[0];

    
      await schema.validate(data, { abortEarly: false });

      
      const captchaValue =
      getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
      false;



      const newUser = await api
        .post(`/confirm_term/${hash}`, {
          ...data,
          captcha : captchaValue,
          term : data.term
        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao enviar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      setSuccess(true);
      setLoading(false);
     

      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  if(failure){
    return <AnimatedDiv> <ResponseMessage
    active
    type="error"
    title=""
    description={
      <p style={{color: '#fff'}}>
        <h2 className="titleH2" style={{color: '#fff'}}> Falha ao identificar o termo</h2>
        <br />
       Tente novamente em alguns minutos ou entre em contato com a organização

      </p>
    }
  /> </AnimatedDiv>
  }

  return (
    <>
  {loading === true && (
        <Loader message="Carregando..." position="fixed" />
      )}

      <aside>
    
        {success ? (
         <AnimatedDiv> <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <p style={{color: '#333'}}>
                <h2 className="titleH2" style={{color: '#333'}}> Termo assinado com sucesso!</h2>
                <br />
               Vocẽ receberá um email com uma cópia do termo.
        
              </p>
            }
          /> </AnimatedDiv>
        ) : (<>
           { ready && <AnimatedDiv>
                  <Form ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR'}}>
                  

<h2 style={{ textAlign: 'center', width: '100%', color: '#333', fontSize: '18px', maxWidth: '400px'}}>{term.header_text}</h2>

<p style={{ textAlign: 'justify', width: '100%', fontSize: '14px',color: '#333', maxWidth: '400px'}} dangerouslySetInnerHTML={{__html: term.term}}/>           
<br/><br/>
<Input name="name" placeholder={translate("Nome completo")} />
<Input name="email" placeholder={translate("E-mail")} />
<MaskedInput mask={'999.999.999-99'} name="document_number" placeholder={translate('CPF')} />

<div style={{marginBottom: '10px', padding: '15px', borderRadius: '10px'}}>
  <br/>
  <p style={{ color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '12px'}}>Você aceita o {term.header_text}?</p>
<CheckboxInput
              style={{ display: 'flex'  }}
              name="term"
              unique={true}
             
              options={[
                {
                  id: `Sim`,
                  label: translate('Sim'),
                  value: `Sim`,
                },
                {
                  id: `Não`,
                  label: translate('Não'),
                  value: `Não`,
                },
              ]}
            />

              </div>
                    <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

                    </aside>  <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <button className="defaultButton" type="submit">
                      Enviar confirmação

                  
                    </button>
                    </aside>
                  </Form></AnimatedDiv>}
        </>
        )}
      </aside>
    </>
  );
};

export default FormContainer;

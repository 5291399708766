import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiPhone } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaAddressCard,
  FaArrowRight,
} from 'react-icons/fa';

import { MdError, MdDateRange } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, recaptchaKey } from '../../../config';
import getCep from '../../../services/cep';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import { useLanguage } from '../../../hooks/Language';
import { countries } from '../../../pages/Products/modules/Login/countries';
import { useToast } from '../../../hooks/Toast';
import { AnimatedDiv } from '../../DashboardPages/core/components/Profile/styles';
import Axios from 'axios';
import { useAuth } from '../../../hooks/Auth';
import states from './states';

interface IRecoverFormData {
  name: string;
  email: string;
  repeat_email: string;
  password: string;
  phone: string;
  captcha: string;
  birth_date: string;
 country: string;
 extra_2 : string;
 extra_3 : string;
 hash : string;
 instituicao ?: any;
 document_number ?: string;
}

interface IOption{
  value: string;
  label: string;
}

interface ICaptcha {
  getValue(): string;
}

interface IParam {
  code: string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const {addToast} = useToast();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const [countryISO, setCountryISO] = useState('BR');
  const { translate} = useLanguage();
  const [cities,setCities] = useState<Array<IOption>>([]);
  const [stateValue,setStateValue] = useState('');

  const {code} = useParams<IParam>();
  
const {signIn} = useAuth();
  const callCityApi = async ( value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if(response){

      const values : Array<IOption> = [];

      response.data.map( city => {
        values.push( { value: city.nome, label : city.nome});
      })

      setCities(values);
    }


  }
  

  useEffect(() => {
    callCityApi(stateValue);
  },[stateValue])

  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    data.birth_date = data.birth_date
      ? data.birth_date.toString().replace(/[^0-9/]/g, '')
      : '';

    

    try {
      formRef.current?.setErrors({});

     
        const schema =   countryISO === 'BR' ? Yup.object().shape( {

          name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),


  


            phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),
            
          password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),
       
          document_number: Yup.string()
            .min(11, `${translate('CPF')} ${translate('obrigatório')}`)
            .required(`${translate('CPF')} ${translate('obrigatório')}`),
 
          birth_date: Yup.string()
            .min(10, translate('min 10 Preecha a Data de Nascimento'))
            .max(10, translate('max 10 Preecha a Data de Nascimento'))
            .required(`${translate('Data de Nascimento')} ${translate('obrigatório')}`),

           

           

        } ) : Yup.object().shape( {
  
          name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),

   
         phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),
          password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),

          document_number: Yup.string()
  
            .required(`${translate('CPF')} ${translate('obrigatório')}`),

            cpf_familiar: Yup.string()
  
            .required(`${translate('RG')} ${translate('obrigatório')}`),
            extra_5: Yup.string()
  
            .required(`${translate('ORGÃO EMISSOR DO RG')} ${translate('obrigatório')}`),
            
            company: Yup.string().required(`${translate('Informe a sua UNIMED')} ${translate('')}`),
            position: Yup.string().required(`${translate('Informe o seu cargo')} ${translate('')}`),
            education_id: Yup.string().required(`${translate('Escolaridade')} ${translate('obrigatória')}`),
            
          birth_date: Yup.string()
            .min(10, translate('min 10 Preecha a Data de Nascimento'))
            .max(10, translate('max 10 Preecha a Data de Nascimento'))
            .required(translate(`${translate('Data de Nascimento')} ${translate('obrigatória')}`)),
     
        });
        data.phone = data.phone.toString().replace(/[^0-9]/g, '');
        data.birth_date.toString().replace(/[^0-9/]/g, '');
        data?.document_number?.toString().replace(/[^0-9]/g, ''); 

      let {email, password, hash, country, extra_2, extra_3} = data;


      const pais = countries.findIndex(c => c.value === country);
      if(pais){
        extra_3 = countries[pais].dialCode;
        extra_2 = countries[pais].label;
      }

      await schema.validate(data, { abortEarly: false });

      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const response = await api.post(`/signup-cortesy`, {
        ...data,
        hash,
        captcha: captchaValue,
        extra_2,
        extra_3
      });


     
      await signIn({ email,password });
    
        
      window.scrollTo(0,0);
      if(response?.data?.confirmationCode){

        return window.location.href = `/payment/success/${response?.data?.confirmationCode}`;

      }

      setSuccess(true);
      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        console.log(err);
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
    
        err?.response?.data?.error || err?.response?.data?.message || 'Erro ao gerar cadastro',
    ]);
    
    }
    setLoading(false);
  };

  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    const state = cepResponse.uf
      ? states.find(st => {
          return st.uf === cepResponse.uf;
        })
      : false;

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
    
        formRef?.current?.setFieldValue('state', cepResponse.uf);
      
    }
  };
  const [company, setCompany] = useState('');

  const escolaridade = [
    { value: "ANALFABETO",label: "ANALFABETO", text :"ANALFABETO"},
   { value: "PRIMÁRIO INCOMPLETO (ATÉ 4º)",label: "PRIMÁRIO INCOMPLETO (ATÉ 4º)", text :"PRIMÁRIO INCOMPLETO (ATÉ 4º)"},
   { value: "PRIMÁRIO COMPLETO",label: "PRIMÁRIO COMPLETO", text :"PRIMÁRIO COMPLETO"},
   { value: "ENSINO FUNDAMENTAL INCOMPLETO",label: "ENSINO FUNDAMENTAL INCOMPLETO", text :"ENSINO FUNDAMENTAL INCOMPLETO"},
   { value: "ENSINO FUNDAMENTAL COMPLETO",label: "ENSINO FUNDAMENTAL COMPLETO", text :"ENSINO FUNDAMENTAL COMPLETO"},
   { value: "ENSINO MÉDIO INCOMPLETO",label: "ENSINO MÉDIO INCOMPLETO", text :"ENSINO MÉDIO INCOMPLETO"},
   { value: "ENSINO MÉDIO COMPLETO",label: "ENSINO MÉDIO COMPLETO", text :"ENSINO MÉDIO COMPLETO"},
   { value: "ENSINO SUPERIO INCOMPLETO",label: "ENSINO SUPERIO INCOMPLETO", text :"ENSINO SUPERIO INCOMPLETO"},
   { value: "ENSINO SUPERIOR COMPLETO",label: "ENSINO SUPERIOR COMPLETO", text :"ENSINO SUPERIOR COMPLETO"},
   { value: "PÓS GRADUAÇÃO - ESPECIALIZAÇÃO",label: "PÓS GRADUAÇÃO - ESPECIALIZAÇÃO", text :"PÓS GRADUAÇÃO - ESPECIALIZAÇÃO"},
   { value: "MESTRADO",label: "MESTRADO", text :"MESTRADO"},
   { value: "DOUTORADO",label: "DOUTORADO", text :"DOUTORADO"}
   
 ]

const unimeds = [
  { value: "UNIMED FEDERAÇÃO/RS", label: "UNIMED FEDERAÇÃO/RS", text :"UNIMED FEDERAÇÃO/RS"},
 { value: "UNIMED CENTRAL DE SERVIÇOS – RS", label: "UNIMED CENTRAL DE SERVIÇOS – RS", text :"UNIMED CENTRAL DE SERVIÇOS – RS"},
 { value: "UNIMED ALEGRETE/RS", label: "UNIMED ALEGRETE/RS", text :"UNIMED ALEGRETE/RS"},
 { value: "UNIMED ALTO DA SERRA", label: "UNIMED ALTO DA SERRA", text :"UNIMED ALTO DA SERRA"},
 { value: "UNIMED ALTO JACUÍ/RS", label: "UNIMED ALTO JACUÍ/RS", text :"UNIMED ALTO JACUÍ/RS"},
 { value: "UNIMED ALTO URUGUAI/RS", label: "UNIMED ALTO URUGUAI/RS", text :"UNIMED ALTO URUGUAI/RS"},
 { value: "UNIMED CENTRO/RS", label: "UNIMED CENTRO/RS", text :"UNIMED CENTRO/RS"},
 { value: "UNIMED ENCOSTA DA SERRA/RS", label: "UNIMED ENCOSTA DA SERRA/RS", text :"UNIMED ENCOSTA DA SERRA/RS"},
 { value: "UNIMED ERECHIM", label: "UNIMED ERECHIM", text :"UNIMED ERECHIM"},
 { value: "UNIMED FRONTEIRA NOROESTE/RS", label: "UNIMED FRONTEIRA NOROESTE/RS", text :"UNIMED FRONTEIRA NOROESTE/RS"},
 { value: "UNIMED ITAQUI RS", label: "UNIMED ITAQUI RS", text :"UNIMED ITAQUI RS"},
 { value: "UNIMED LITORAL SUL/RS", label: "UNIMED LITORAL SUL/RS", text :"UNIMED LITORAL SUL/RS"},
 { value: "UNIMED MISSÕES/RS", label: "UNIMED MISSÕES/RS", text :"UNIMED MISSÕES/RS"},
 { value: "UNIMED NORDESTE RS", label: "UNIMED NORDESTE RS", text :"UNIMED NORDESTE RS"},
 { value: "UNIMED NOROESTE/RS", label: "UNIMED NOROESTE/RS", text :"UNIMED NOROESTE/RS"},
 { value: "UNIMED PELOTAS/RS", label: "UNIMED PELOTAS/RS", text :"UNIMED PELOTAS/RS"},
 { value: "UNIMED PLANALTO CENTRAL/RS", label: "UNIMED PLANALTO CENTRAL/RS", text :"UNIMED PLANALTO CENTRAL/RS"},
 { value: "UNIMED PLANALTO MÉDIO", label: "UNIMED PLANALTO MÉDIO", text :"UNIMED PLANALTO MÉDIO"},
 { value: "UNIMED PORTO ALEGRE", label: "UNIMED PORTO ALEGRE", text :"UNIMED PORTO ALEGRE"},
 { value: "UNIMED REGIÃO DA CAMPANHA/RS", label: "UNIMED REGIÃO DA CAMPANHA/RS", text :"UNIMED REGIÃO DA CAMPANHA/RS"},
 { value: "UNIMED REGIÃO DA FRONTEIRA/RS", label: "UNIMED REGIÃO DA FRONTEIRA/RS", text :"UNIMED REGIÃO DA FRONTEIRA/RS"},
 { value: "UNIMED REGIÃO DA PRODUÇÃO/RS", label: "UNIMED REGIÃO DA PRODUÇÃO/RS", text :"UNIMED REGIÃO DA PRODUÇÃO/RS"},
 { value: "UNIMED SANTA MARIA/RS", label: "UNIMED SANTA MARIA/RS", text :"UNIMED SANTA MARIA/RS"},
 { value: "UNIMED URUGUAIANA/RS", label: "UNIMED URUGUAIANA/RS", text :"UNIMED URUGUAIANA/RS"},
 { value: "UNIMED VALE DAS ANTAS, RS", label: "UNIMED VALE DAS ANTAS, RS", text :"UNIMED VALE DAS ANTAS, RS"},
 { value: "UNIMED VALE DO CAÍ/RS", label: "UNIMED VALE DO CAÍ/RS", text :"UNIMED VALE DO CAÍ/RS"},
 { value: "UNIMED VALE DO SINOS", label: "UNIMED VALE DO SINOS", text :"UNIMED VALE DO SINOS"},
 { value: "UNIMED VALES DO TAQUARI E RIO PARDO", label: "UNIMED VALES DO TAQUARI E RIO PARDO", text :"UNIMED VALES DO TAQUARI E RIO PARDO"},
 { value: "UNICOOPMED", label: "UNICOOPMED", text :"UNICOOPMED"},
 { value: "UNIAIR TRANSPORTE AEROMÉDICO", label: "UNIAIR TRANSPORTE AEROMÉDICO", text :"UNIAIR TRANSPORTE AEROMÉDICO"},
 { value: "INSTITUTO UNIMED/RS", label: "INSTITUTO UNIMED/RS", text :"INSTITUTO UNIMED/RS"},
 { value: "OUTRO", label: "OUTRO", text :"OUTRO"}
 ];

  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

 
<aside style={{background:'#fff', display:'flex', padding:'20px', flexDirection:'column', alignItems: 'center', justifyContent:'center', margin: '30px', borderRadius: '20px'}}>
   


<aside style={{background:'#fff', display:'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center', margin: '30px', borderRadius: '20px'}}>
                      <Link style={{ display:'flex', flexDirection:'row', alignItems: 'center', justifyContent:'center' }} to={`/aplicar-codigo/${code ? code : ''}`}><strong>JÁ TENHO CADASTRO </strong><FaArrowRight color="#333" size={20}/></Link>
                      </aside>
                  <Form className="form" ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR', hash: code}}>
                    
                    <h1 className="defaultMarked" ><div/>{translate('Novo cadastro')}</h1>
                    <Input name="hash" placeholder={translate('Código do ingresso')} />
                     
                    <SelectSimple setCallback={(value) => setCountryISO(value) } options={countries} name="country" label={translate('Pais')}/>
                    
                    <Input name="name" placeholder={translate('Nome completo')} /> 
      
                    <Input name="email" placeholder={translate('E-mail')} />
                   
                   
                    <Input
                      icon={FiLock}
                      name="password"
                      type="password"
                      placeholder={translate('Cadastre sua senha de acesso')}
                    />
              

                 

                    { countryISO === 'BR' ? <MaskedInput
                      mask="(99) 99999.9999"
                      name="phone"
                      placeholder={translate('Telefone / Whatsapp')}
                    /> : <Input name="phone"
                    placeholder={translate('Telefone')}/>}

 <Input  name="cpf_familiar"
                placeholder={translate('RG')}/> 

<Input  name="extra_5"
                placeholder={translate('ORGÃO EMISSOR DO RG')}/> 



<SelectSimple name="company" label="UNIMED" options={unimeds} setCallback={(value) => setCompany(value)} />
{company === 'OUTRO' && <Input name="extra_4" placeholder="Qual?" />}

<Input name="position" placeholder="Cargo" />

<SelectSimple name="education_id" label="Escolaridade" options={escolaridade}   />

{ countryISO === 'BR' ? <Input style={{display:'none'}} name="document_type" value="cpf"
                placeholder={translate('Tipo de documento')}/> :  <SelectSimple name="document_type"
                label={translate('Tipo de Documento (Passaporte / Outro)')} options={[{label: translate('Passaporte'), value: 'passport'}, {label: translate('Outro'), value: 'other'}]} />  }


{ countryISO === 'BR' ? <Input style={{display:'none'}} name="document_type" value="cpf"
                placeholder={translate('Tipo de documento')}/> :  <SelectSimple name="document_type"
                label={translate('Tipo de Documento (Passaporte / Outro)')} options={[{label: translate('Passaporte'), value: 'passport'}, {label: translate('Outro'), value: 'other'}]} />  }



{ countryISO === 'BR' ? <MaskedInput
                      mask="999.999.999-99"
                      name="document_number"
                      placeholder={translate('CPF')}
                /> :  <Input name="document_number"
                placeholder={translate('Nº do documento')}/> }

                

                    <MaskedInput
                      mask="99/99/9999"
                      name="birth_date"
                      placeholder={translate('Data de Nascimento (DD/MM/AAAA)')}
                    />

     
<aside style={{display:'flex', justifyContent:'flex-start', width: '100%'}}>


<CheckboxInput
              style={{ display: 'flex'  }}
              name="term"
              unique={true}
              preText={(
                <>
                  {' '}
                  Estou ciente de que estou fornecendo ao {authTitle} e seus parceiros meus contatos gerais, os quais são dados pessoais protegidos pela Lei Geral de Proteção de Dados Brasileira, para gestão de relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.

                 
{' '}
                 
                </>
              )}
              options={[
                {
                  id: `Sim, autorizo a liberação dos meus dados para uso da ${authTitle} e seus parceiros`,
                  label: translate(`Sim, autorizo a liberação dos meus dados para uso da ${authTitle} e seus parceiros`),
                  value: `Sim, autorizo a liberação dos meus dados para uso da ${authTitle} e seus parceiros`,
                },
                {
                  id: `Não autorizo a liberação dos meus dados para uso da ${authTitle} e seus parceiros`,
                  label: translate(`Não autorizo a liberação dos meus dados para uso da ${authTitle} e seus parceiros`),
                  value: `Não autorizo a liberação dos meus dados para uso da ${authTitle} e seus parceiros`,
                },
              ]}
            />
</aside>
<p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {translate('Ao me cadastrar, afirmo que li e aceito a')}
{' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {translate('POLÍTICA DE PRIVACIDADE')}
{' '}
                  </Link>
{' '}
                </strong>
                {translate('do evento.')}
              </p>
              <aside style={{display:'flex', justifyContent:'center', width: '100%'}}>
              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />
</aside>

<aside style={{display:'flex', justifyContent:'center', width: '100%'}}>
  {errorMessage && <Error>{errorMessage.map( text => <div>{text}</div> )}</Error>}
</aside>
                    <Button type="submit">
                      {translate('Finalizar inscrição')}

                    </Button>

             
         
                  </Form>
           
          </aside>
    </>
  );
};

export default FormContainer;

import React from 'react';

import { Container, ContainerWhite } from './styles';

import Vimeo from '../../components/Vimeo';




const Decisao: React.FC = () => {

  return (
    <>


 
      <Container >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', flexWrap:'wrap'}}>
        <div style={{margin: '20px auto', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', maxWidth:'450px', padding: '30px', borderRadius: '20px', background: 'rgb(107, 2, 147)', color: '#fff'}}>
        <h2 style={{fontSize: '24px', maxWidth: '450px', marginBottom: '20px', textAlign: 'center'}}>
        Enquete finalizada
        </h2><br/>
        
        </div>

      
      </div>
<div className="decisaoHolder">

  
  <div style={{width: '100%',  margin: '10px',maxWidth: '500px'}}>
        <Vimeo id="631610004"/>
        </div>
        <div style={{width: '100%', margin: '10px', maxWidth: '500px'}}>
        <Vimeo id="631609844"/>
        </div>
    
      </div>
      </Container>
   
    </>
  );
};
export default Decisao;

import React, { useEffect } from 'react';
import { MdChat, MdChatBubble } from 'react-icons/md';
import { Container, Sponsor, SponsorGrid } from './styles';
import { Button } from '../../components/Button/styles';
import Sponsors from '../Sponsors';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../components/ImageBackgroundSlider';

const FooterLayout: React.FC = ({ children }) => {
  return (  <> 
  <div style={{zIndex:-1, width: '100%'}}>
 
  <Particles
  
        params={{
          particles: {
            lineLinked: {
              enable: true,
              distance: 550,
              color: '#9470e0' 
           
            },
            move: {
              speed: 0.5,
              direction: 'top',
              collisions: false,
              outModes: {
                default: 'out',
                left: 'out',
                right: 'out',
                bottom: 'out',
                top: 'out',
              },

             
            },
            opacity: {
              value: 1,
              random: false,
              animation: {
                enable: false
              }

            },
            shape: {
              
              type: 'images',
          
              image: [
                {src: '/apoio/ray.png', height: 100, width: 100},
               
            ]
             
            },
     
            color: { value: '#fff' },

            number: {
              density: {
                enable: true,
                value_area: 2400,
              },
              value: 40,
            },
            size: {
              value: 10,
              random: true,
            },
          },
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: -1
        }}
      />
 

      </div> 

      </>
  );
};

export default FooterLayout;

import styled from 'styled-components';
import { background } from '../../../config';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  padding-top: 0px;
  position: relative;
  z-index: 101;

  >div.wrapperBody {

background: url('/assets/Fundo2.jpeg') ;
background-position:  top center;

background-repeat: no-repeat;
background-size: cover;
background-attachment: fixed;
}
`;

export const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: url(${background}) no-repeat top left;
  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: -1;
`;

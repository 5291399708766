/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, Dispatch, useRef, useMemo, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FiArrowRight, FiArrowLeft, FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import axios from 'axios';
import pagarme from 'pagarme';
import { type } from 'os';
import { Link, useParams } from 'react-router-dom';
import api from '../../../../services/api';
import MaskedInput from '../../../../components/Forms/MaskedInput';
import Input from '../../../../components/Forms/Input';
import Button from '../../../../components/Button';
import SelectSimple from '../../../../components/Forms/SelectSimple';

import { authTitle, eventName, gateKey } from '../../../../config';
import imgBoleto from '../../../../assets/barcode.png';

import { AnimatedDiv } from '../../styles';
import { Content } from './styles';
import { useModal } from '../../../../hooks/Modal';
import { useToast } from '../../../../hooks/Toast';

import Whatsapp from '../../../../components/Whatsapp';

import { ActiveButton, BuyContext } from '../../index';
import Loader from '../../../../components/Loader';
import format from '../../../../utils/format';
import {
  ResponseMessage,
  IResponseMessage,
} from '../../../../components/ResponseMessage';

import UpdateUserContainer from '../../../../components/UserUpdateComponent';
import { useAuth } from '../../../../hooks/Auth';
import { useLanguage } from '../../../../hooks/Language';
import { FaCreditCard, FaReceipt } from 'react-icons/fa';
import AtualizarCadastroContainer from '../AtualizarCadastro';
import { uuid } from 'uuidv4';
import SignIn from '../../../SignIn';
import { string } from 'yup';

interface IOptions {
  text?: string;
  label?: string;
  value: string | number;
}

interface IComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
}

interface ICardProps {
  id?: string;
  card_holder_name: string;
  card_expiration_date: string;
  card_number: string;
  card_cvv: string;
  installments?: number;
  document_type: string;
}

interface IInstallment {
  label: string;
  value: string;
}

interface IParams {
  product_hash : string;
}

interface ICoupon{
  label: string;
  value : string;
  price : number;
}

const PaymentMethod: React.FC = () => {

  const [buyContext,setBuyContext] = useState<Record<string,any>>({});
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const {product_hash} = useParams<IParams>();
  const [couponHash,setCouponHash] = useState<Array<ICoupon>>([]);
  const [defaultValue, setDefaultValue] = useState(0);
  const [amount, setAmount] = useState(0.00);
  const [installmentValue, setInstallmentValue] = useState(1);
  const [quantity, setQuantity] = useState((product_hash === 'apoio' || product_hash === 'apoio-pos') ? 5 : 1);
  const [product, setProduct] = useState<Record<string,any>>({});

  const[productNotFound,setProductNotFound] = useState(false);



  const { addToast } = useToast();
  const { addModal } = useModal();

  const [responseMessage, setResponseMessage] = useState(
    {} as IResponseMessage,
  );

    const {translate} = useLanguage();

  const {user} = useAuth();

  const calculateAmount = (value,quantity)=>{
    if(quantity >3){
    return (value / (1 - ((quantity - 3) * 0.016)))}
    else{
      return value;
    }
  }

  const getQuantities = () => {

    const items : Array<IOptions> = [];

    for(let x = 1; x < 100; x = x + 1){

      if(product_hash === 'apoio' || product_hash === 'apoio-pos'){
        if( x < 5 || x > 5){}
        else{
          items.push({ value: x, label: x.toString() })
        }
      }
      else{
        items.push({ value: x, label: x.toString() })
      }

   
    }

    return items;
  }

  const load = useCallback( async(hash) => {

    if(user){

    try{
    const userInfo = await api.get(`/verify-user`);

    if(!userInfo){
      setUpdate(true);
      setLoading(false);
      return;

    }

    setReady(true);

  } catch (err) {
    setUpdate(true);
    setLoading(false);
   
    addToast({type:"error", title: translate("Seu cadastro precisa ser atualizado.")});
    return;
  }
  try{
    const response = await api.get(`/product/${product_hash}`);

    if(!response ){
     setProductNotFound(true);
      return;
    }

    const product = response?.data;
 
    let newCoupon : Array<ICoupon> = [];
    


    if(!product){
      setProductNotFound(true);
    setLoading(false);
   
    addToast({type:"error", title: translate("Produto não identificado"), description:translate('Este produto parece não estar mais ativo')});
    return;
    }


    setLoading(false);
    setDefaultValue(product.price);
    setAmount(product.price * quantity);
    setProduct(product);

    const nextContext : Record<string,any> = { products : [product], amount : product.price};

    if(user && user.country && user.country !== 'BR'){
      nextContext.billing = {};
      nextContext.billing.paymentMethod = 'credit_card';
    }
    setBuyContext(nextContext);


  

  } 
  catch (err) {
    setProductNotFound(true);
    setLoading(false);
   
    addToast({type:"error", title: translate("Produto não identificado"), description:translate('Este produto parece não estar mais ativo')});
    return;
    }

}
  },[user,buyContext,product_hash]);

  useEffect(() => {

    if(!product_hash){
       window.location.href = '/planos';
       return;
    }

   
    load(product_hash);
    setLoading(false);

  },[product_hash])

 



  useEffect(( ) => {
    if(quantity && defaultValue){
    setAmount( quantity *  defaultValue);
    }
  },[quantity,product,defaultValue,installmentValue])


  const handleChangePaymentMethod = useCallback(
    id => {
      setBuyContext({ ...buyContext, billing: { paymentMethod: id } });
    },
    [buyContext],
  );

  
  const installments = useCallback(() : Array<IInstallment> => {


    if(buyContext && buyContext.billing && buyContext.billing.paymentMethod === 'boleto'){
      return [{label : '1x', value : '1'}];
    }

    if(buyContext && buyContext.billing && buyContext.billing.paymentMethod === 'credit_card'){
    const installment : Array<IInstallment> = [];
    for (let x = 1; x <= 12; x += 1) {

      let currentAmount = amount;

      if(x >= 2){
        currentAmount = calculateAmount(amount,x) ;
      }

      if(amount && (currentAmount / x)) {
      installment.push({
        label: `${x}X ${format(
          parseInt(((currentAmount ) / x)?.toString(), 10),
        )}${ x >= 4 ? ' com juros': ''}`,
        value: x?.toString(),
      });}
    }

    
    return installment;}

    else{
      return [{label : '1x', value : '1'}]
    }
  }, [amount,buyContext,quantity]);

 

  const handleFinishCreditCard = useCallback(
    async (card: ICardProps) => {
      setLoading(true);
      const cardValidations = await pagarme.validate({ card });

      if (!cardValidations.card.card_number) {
        addModal({
          title: translate('Cartão inválido'),
          content: <>{translate('Verifique o número do seu cartão')}</>,
        });
        setLoading(false);
        return false;
      }
      

      await pagarme.client
        .connect({ encryption_key: gateKey })
        .then(async (client: Record<string, any>) => {
          const card_hash = await client.security.encrypt(card);

          return card_hash;
        })
        .then(async (card_hash: string) => {
          if (!card_hash) {
            setLoading(false);
            return false;
          }

          if(buyContext?.products?.[0]?.price){
            buyContext.products[0].price = calculateAmount(amount,card.installments).toFixed(0);
            }
  
            const context = {
              ...buyContext,
              amount : calculateAmount(amount,card.installments).toFixed(0),
              quantity: quantity,
              billing: {
                paymentMethod: 'credit_card',
                credit_card_hash: card_hash,
                installments: card.installments,
                document_type: card.document_type,
              },
            };
  

          await api
            .post(`/payment`, {
              ...context,
            })
            .then(response => {
              const { data } = response;

              if (data && data.status === 'success') {
                return window.location.href = `/payment/success/${data.hash}`;
              } else {
                addModal({ title: 'Erro', content: data.message, key: '1' });
              }
              setLoading(false);
            })
            .catch(err => {
              addToast({
                title: translate('Erro'),
                description: translate('Erro ao efetuar pagamento'),
                type: 'error',
              });
              setLoading(false);
              return false;
            });
        });
    },
    [addToast, buyContext, amount,defaultValue,couponHash],
  );

  const handleFinishBoleto = useCallback(
    async dataForm => {
      setLoading(true);

      const context = {
        ...buyContext,
        amount : amount,
        quantity: quantity,
        coupon_hash : dataForm?.coupon_hash || 0,
        billing: {
          paymentMethod: 'boleto',
          document_type: dataForm.document_type,
        },
      };



      await api
        .post(`/payment`, {
          ...context,
          
        })
        .then(response => {
          const { data } = response;
          if (data && data.status === 'success') {
            return window.location.href = `/payment/success/${data.hash}`;
          } else {
            addModal({ title: 'Erro', content: data.message, key: '1' });
          }

          setLoading(false);
        })
        .catch(err => {
           addToast({
                title: translate('Erro'),
                description: translate('Erro ao efetuar pagamento'),
                type: 'error',
              });
          setLoading(false);
          return false;
        });
    },
    [addToast, buyContext,amount,defaultValue,couponHash],
  );


  if(update){
    return <AnimatedDiv visible={true}>
      <AtualizarCadastroContainer/>
    </AnimatedDiv>
  }

  if(!user){
    return         <div className="onlineMessage" style={{display:'flex', minHeight:'auto', width:'auto', maxWidth: '400px',margin: '0px auto', flexDirection:'column',border: '2px solid #555', borderRadius: '20px',padding: '20px', alignItems:'center', justifyContent: 'center'}}>
         
         <p style={{color: '#555', textAlign: 'center',margin: '20px 0px'}}>
      {translate(
        'Você precisa logar ou se inscrever para finalizar a compra.',
      )}</p>
         <Link  to={`/planos/${product_hash}`}><button className="extraButton">Garantir minha inscrição</button></Link>
    <SignIn redirect={`/checkout/${product_hash}`}/><br/><br/>

  </div>
  }

  if(productNotFound){
   return <div className="onlineMessage" style={{display:'flex', width:'auto', maxWidth: '400px',margin: '0px auto', flexDirection:'column',border: '2px solid #555', borderRadius: '20px',padding: '20px',marginTop:'100px', alignItems:'center', justifyContent: 'center'}}>
         
    <h2 className="defaultMarked">{translate('Ingresso não encontrado')}</h2>
    <p style={{color: '#555', textAlign: 'center',marginTop: '20px'}}>
      {translate(
        'Verifique se este ingresso ainda está disponível.',
      )}
    </p><br/><br/>
    <Link to="/planos">
      <button className="shadowGreyButton" type="button">
        {' '}
        {translate('Voltar')}
      </button>
    </Link>
  </div>
  }

  return (
    <>
      {loading && <Loader message={`${translate('Carregando')}...`} />}
     {ready && <AnimatedDiv visible={true}>
     <Whatsapp
                message={translate('Suporte Técnico')}
                text={ `${translate('Estou finalizando minha inscrição no')} ${authTitle}`}
              />
        {responseMessage.active === true && (
          <ResponseMessage
            active
            type={responseMessage.type}
            title={responseMessage.title}
            description={responseMessage.description}
          />
        )}
        {responseMessage.active !== true && (
          <>
            <Content style={{width: '100%', maxWidth:'100%'}}>
             
            <ActiveButton step={3}/>
        
              <h2 style={{color:'#333', margin: '30px 0px'}}>{translate('Falta pouco! Escolha aqui a forma de pagamento')}</h2>
              <aside style={{display:'flex', width:'100%', maxWidth:'100%', flexDirection:'row', justifyContent:'center', flexWrap:'wrap', alignItems: 'stretch'}}>
              
                {user  &&  user.country === 'BR' && <button  style={{display:'flex', padding:'50px', alignItems:'center', width:'250px',  maxWidth:'250px', flexDirection:'row', margin:'10px', justifyContent:'center'}}
                  className={
                    buyContext?.billing?.paymentMethod === 'boleto'
                      ? 'shadowButton active'
                      : 'shadowButton'
                  }
                  type="button"
                  onClick={() => handleChangePaymentMethod('boleto')}
                >
                  <input
                    checked={buyContext?.billing?.paymentMethod === 'boleto'}
                    name="payment_method"
                    value="boleto"
                    type="checkbox"
                  />
                <FaReceipt size={30} style={{marginRight:'10px'}}/><p>{translate('Boleto')}</p>
                  
                </button>}
                <button
                style={{display:'flex', alignItems:'center', padding:'50px', flexDirection:'row', margin:'10px',width:'250px', maxWidth:'250px',  justifyContent:'center'}}
                  className={
                    buyContext?.billing?.paymentMethod === 'credit_card'
                      ? 'shadowButton active'
                      : 'shadowButton'
                  }
                  type="button"
                  onClick={() => handleChangePaymentMethod('credit_card')}
                >
                  <input
                    checked={
                      buyContext?.billing?.paymentMethod === 'credit_card'
                    }
                    name="payment_method"
                    value="credit_card"
                    type="checkbox"
                  />
                  <FaCreditCard size={30} style={{marginRight:'10px'}}/><p>{translate('Cartão de Crédito')}</p>
                  
                </button>
              </aside>
           
              <Content style={{display: buyContext?.billing?.paymentMethod ? 'flex' : 'none', paddingTop: '10px'}}>
              <aside>
                {buyContext?.billing?.paymentMethod === 'credit_card' && (
                  <Form className="form"
                    ref={formRef}
                    initialData={{ installments: installmentValue, quantity : quantity, coupon_hash : "3"}}
                    onSubmit={handleFinishCreditCard}
                  >
                    <h2>Preencha os campos abaixo</h2><br/>
                    <SelectSimple style={{zIndex: 100000}}
                      options={[
                        { value: user.country === 'BR' ? 'cpf' : 'other', label: translate('Pessoa Física') },
                        {
                          value: 'cnpj',
                          label: translate('Entidade / Pessoa Jurídica'),
                        },
                      ]}
                      name="document_type"
                      formRef={formRef}
                      label={translate('Emitir como')}
                    />

 <div style={{marginTop: '10px'}}/>
<aside style={{display:'none'}}>
                     <SelectSimple
                        setCallback={(value) => { setQuantity(parseInt(value));    } }
                      options={[ ...getQuantities()]}
                      name="quantity"
                      formRef={formRef}
                      label={translate('Quantos ingressos')}
                    /></aside> 
                    <Input name="card_number" placeholder={translate('Número do Cartão')} />
                    <Input
                      name="card_holder_name"
                      placeholder={translate('Nome no Cartão')}
                    />
                    <MaskedInput
                      mask="99/99"
                      name="card_expiration_date"
                      placeholder={translate('Validade')}
                    />
                    <Input name="card_cvv" placeholder="CVV" />
                    <SelectSimple
                    setCallback={(value) => { setInstallmentValue(value) }}
                      name="installments"
                      label={translate('Parcelas')}
                      options={installments()}
                    />
                 <h2 style={{margin: '10px 0px', color: '#333'}}>Total: R$ {format(
          parseInt((calculateAmount(amount,installmentValue) )?.toString(), 10),
        )}</h2>
                    <Button type="submit">
                      {translate('Finalizar Compra')}
                      <FiArrowRight />
                    </Button>
                 
                  </Form>
                )}

                {buyContext?.billing?.paymentMethod === 'boleto' && (
                  <Form className="form"
                    ref={formRef}
                    onSubmit={handleFinishBoleto}
                    initialData={{ document_type: 'cpf',quantity : quantity, coupon_hash : "3" }}
                  >
                
                    <SelectSimple style={{zIndex: 100000}}
                      options={[
                        { value: 'cpf', label: translate('Pessoa Física') },
                        {
                          value: 'cnpj',
                          label: translate('Entidade / Pessoa Jurídica'),
                        },
                      ]}
                      name="document_type"
                      formRef={formRef}
                      label={translate('Emitir como')}
                    />

 

<aside style={{display:'none'}}>
<SelectSimple
                        setCallback={(value) => { setQuantity(parseInt(value));    } }
                      options={[ ...getQuantities()]}
                      name="quantity"
                      formRef={formRef}
                      label={translate('Quantos ingressos')}
                    /></aside>
                    <h2 style={{margin: '10px 0px', color: '#333'}}>Total: R$ {format(
          parseInt((amount)?.toString(), 10),
        )}</h2>
                    <Button type="submit">
                    {translate('Finalizar Compra')}
                      <FiArrowRight />
                    </Button>
                  
                  </Form>
                )}
              </aside>
            </Content>

            </Content>
            
          </>
        )}
           <p style={{color: '#333', marginTop:'30px', cursor:'pointer'}}
           
           onClick={() =>
             addModal({
               title: '',
               content: <UpdateUserContainer />,
               key: 'userUpdateForm',
             })}
         >
           {translate('Clique aqui caso precise revisar seu cadastro')}
         </p>
      </AnimatedDiv>}
    </>
  );
};

export default PaymentMethod;

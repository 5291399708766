import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/speakers';
  const title = 'Palestrantes';
  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    company: { ref: 'company', column: 'company', label: 'Instituição' },
    position: { ref: 'position', column: 'position', label: 'Cargo' },
    category_id: {
      ref: 'category_id',
      column: 'category_response',
      includeColumn: 'title',
      label: 'Categoria',
      filter: true,
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    category_id: {
      alias: 'category_id',
      model: 'selectApi',
      name: 'category_id',
      label: 'Categoria',

      endpoint,
    },
    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },
    company: { model: 'input', type: 'text', name: 'company', label: 'Instituição' },
    position: { model: 'input', type: 'text', name: 'position', label: 'Cargo' },
    short_description: { model: 'input', type: 'text', name: 'description', label: 'Descritivo Curto' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Descritivo' },
    description_en: { model: 'richText', type: 'text', name: 'description_en', label: 'Descritivo Inglês' },
  };

  const formSchemaUpdate = {
   
    photo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'photo',
      label: 'photo',
    },
    ...formSchema
  };

  const formValidation: Record<string, ValidationSchema> = {
    name: {
      name: 'name',
      type: 'string',
      label: 'Palestrante',
      rules: [{ type: 'required' }],
    },
  };


  
  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema : formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;

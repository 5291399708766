import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../services/api';
import { date, hour, dateLanguage,simpleDate } from '../../utils/date';
import { Container } from './styles';
import {addHours} from 'date-fns';
import { useToast } from '../../hooks/Toast';
import { Form } from '@unform/web';

import { string } from 'yup';

import { useLanguage } from '../../hooks/Language';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Favorite from '../../components/ClassRoomContainer/modules/Favorite';
import { useAuth } from '../../hooks/Auth';
import { content } from './content';

interface IOption {
  value : string;
  label : string;
}

interface IProgram {
  date: Date;
  formatted_date?: string;
  start_hour: Date;
  formatted_start_hour?: string;
  end_date: Date;
  formatted_end_date?: string;
  title: string;
  category : string;
  room_reference_id_response: Record<string, any>;
  description: string;
}

interface IProgramFilter {
  formatted_date: string;
  program: Array<IProgram>;
  simpleDate ?: string;
}

const Program: React.FC = () => {
  const [programs, setPrograms] = useState<Array<IProgramFilter>>([]);
  const [referenceProgram, setReferenceProgram] = useState<Array<IProgramFilter>>([]);
  const [currentDay, setCurrentDay] = useState(0);

  const [search, setSearch] = useState('');
  const [currentProgram, setCurrentProgram] = useState('');
  const [roomList, setRoomList] = useState<Array<IOption>>([]);
  const { addToast} = useToast();
  const {user,handleApiErrors} = useAuth();
const {translate} = useLanguage();
  const [filter, setFilter] = useState({
    date : '',
    category : '',
    search : ''
  });

  const addFilter = (column, value) => {

    const newFilter = {...filter};
    newFilter[column] = value;

    setFilter(newFilter);

  }

  const newSearch = ( data ) => {

    const newFilter = {...filter};
    newFilter.search = search;

    setFilter(newFilter);

  }

  useEffect(() => {

    let programsContent : Array<IProgramFilter> = [];

    if(filter.date){
      programs.map( p => {
        if(p.simpleDate === filter.date){
          programsContent.push({...p});
        }
      })
    }
    else{
      programsContent = [...programs];
    }



    if(filter.category !== ""){
      const filteredProgram : Array<IProgramFilter> = [];
      programsContent.map(( item, index) => {
        filteredProgram.push({...item, program : []});
        return false;
      })
    
      programsContent.map( (p1) => {

        const index = filteredProgram.findIndex( p => p.simpleDate === p1.simpleDate);
       if(index >= 0){
        p1.program.map( p1_program => {
          const text = p1_program?.category?.toString()?.toLowerCase();
          const searchValue = filter?.category?.toString()?.toLowerCase();

          const indexText = text === searchValue;

    
          if(indexText){
            
            filteredProgram[index].program.push(p1_program);
          }
        }) }
      })

      programsContent = [...filteredProgram]; 
    }

 

    if(filter.search !== ""){
      const filteredProgram : Array<IProgramFilter> = [];
      programsContent.map(( item, index) => {
        filteredProgram.push({...item, program : []});
        return false;
      })
    
      programs.map( (p1) => {

        const index = filteredProgram.findIndex( p => p.simpleDate === p1.simpleDate);
       if(index >= 0){
        p1.program.map( p1_program => {
          const text = p1_program?.title?.toString()?.toLowerCase();
          const text2 = p1_program?.description?.toString()?.toLowerCase();
          const searchValue = filter?.search?.toString()?.toLowerCase();
          let indexText = text?.indexOf(searchValue);

          if(indexText < 0){
            indexText = text2?.indexOf(searchValue);

          }

          
          if(indexText >= 0){
            
            filteredProgram[index].program.push(p1_program);
          }
        }) }
      })

      programsContent = [...filteredProgram]; 
    }

    setReferenceProgram(programsContent);

  },[programs,filter])

  const checkUser = async(id) => {

    try{
      const response = await api.post(`/check-activity/${id}`);

      if(response && response.data){
        window.open(response?.data?.url,'_BLANK');
      }
      else{
addToast({type:"error", title: "Inscrição não encontrada"});
      }


    } catch (err) {
      addToast({type:"error", title: "Inscrição não encontrada"});
    }
  }

  useEffect(() => {
    const load = async () => {
      const program_ref: Array<IProgramFilter> = [];
      const rooms = {};
      
      try{
      const response = await api.get('/programs-list');
    
      if (response?.data?.rows) {
        const responseData = response.data.rows.reduce((prev, elem) => {
          elem.simpleDate = simpleDate(elem.date);
          elem.formatted_date = dateLanguage(elem.date);
          elem.formatted_start_hour = hour(elem.start_hour);
          elem.formatted_end_hour = hour(elem.end_hour);

          if(elem.category){
          rooms[elem.category] = '';
          }
        
          prev.push(elem);

          return prev;
        }, []);

        const itens : Array<IOption> = [];
        Object.keys(rooms).map( key => {
          itens.push({ label : key, value : key});
        })


        setRoomList(itens)

        if (responseData) {
          responseData.map(rep => {
            const index = program_ref.findIndex(
              day => day.formatted_date === rep.formatted_date,
            );

            if (index < 0) {
              program_ref.push({
                simpleDate: rep.simpleDate,
                formatted_date: rep.formatted_date,
                program: [{ ...rep }],
              });
            } else {
              program_ref[index].program.push({ ...rep });
            }
          });
        }

        setPrograms(program_ref);
        setReferenceProgram(program_ref);
      }}
      catch(err){
        handleApiErrors(err);
      }
    };
 //  load();
  }, []);

  const line = program => {
    return (
      <tr  onClick={() => setCurrentProgram(state => state === program.id ? '' : program.id)}>
        <td style={{width:'100%'}}>
   
            <span>
              {program.category ? <><aside className='category' style={{fontSize: '14px', fontWeight: 'bold', padding:'2px 5px', display:'inline-block',  margin: '3px 0px', marginBottom: '5px', color: '#fff'}}> {program.category} </aside><br/></> : <></> }
                          <strong className='titleProgram' style={{color: '#fff'}}>
{' '}

{program.title}
{' '}


 </strong>

 {program.description && <div className={"hover"}>
            <div style={{width: '100%'}}>


            <div className="miniPrograma" dangerouslySetInnerHTML={{ __html: program.description }} />

            </div>


          </div>}
            </span>
          
            </td>
                   
      </tr>
    );
  };
  const {length} = programs;
  return (
    <Container id="programacao">
      <h2 className="titleCapa" style={{color: '#fff',    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'}}><p>{translate('TEMÁTICAS')} </p></h2>
 
    
 

{/*
<div style={{display:'none', flexDirection:'row', flexWrap:'wrap', alignContent:'center', justifyContent:'center', width:'100%'}}>
<button style={{width: '130px', margin: '5px', background : filter.date === '' ? '#2a4832' : 'none' , color: filter.date === '' ? '#fff' : '#29a367', border: '2px solid #29a367' }} onClick={() => addFilter('date','')}>{translate('Todos os dias')}</button>
      {programs.map( filterday => {
        return filterday.simpleDate && <button style={{width: '60px', margin: '5px', background : filter.date === filterday.simpleDate ? '#29a367' : 'none', color: filter.date === filterday.simpleDate ? '#fff' : '#29a367', border: '2px solid #29a367' }}  onClick={() => addFilter('date',filterday.simpleDate)}>{translate(filterday.simpleDate)}</button>
      })}


<Form
          style={{ display: 'flex', alignItems: 'stretch', background:'none', margin:'5px' }}
          onSubmit={newSearch}
        >
          
           
          <input style={{height: '43px', padding: '10px'}}
            name="pesquisa"
            type="text"
            placeholder={`${translate('Escreva para pesquisar')}...`}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />

        <button style={{width: '100px',height: '43px', background:'#29a367', color: '#fff', borderRadius:'10px', border: '0px',  margin: '0px 5px'}} type="submit">{translate('Pesquisar')}</button>
        </Form>
    

 </div> 
    */}
<div>
<table>
           
           <tbody>

              {content.map((text) => {
                     return  <tr key={`theme-${text}`}   >
                      <td style={{width:'100%'}}>
                 
                          <span>
                            <strong className='titleProgram' style={{color: '#fff'}}>
              {' '}
              
              {text}
              {' '}
              
              
               </strong>
               </span>
               </td></tr>
              })}
             
             </tbody>
         </table>

</div>
    {/*  {referenceProgram.map( program => {
return (program.program.length > 0 ? <div>
<div className="programDayHeader" >
      
        
            
          </div>
          <table>
           
            <tbody>{program.program.map(items => line(items))}</tbody>
          </table>

</div> : <></>)
      })} */}

    </Container>
  );
};
export default Program;

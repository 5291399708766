import React from 'react';

import { Container, ContainerWhite } from './styles';

 
const Apresentacao: React.FC = () => {


  return (
    <>

 
      <Container id="apresentacao" className='fadeInAnimation' >
      
        <div >


<p className='shadow' style={{maxWidth: '500px', margin: '0px auto', padding: '20px', background: '#eee', borderRadius: '15px'}}>
<h2 style={{margin: '30px auto'}}>
        APRESENTAÇÃO
        </h2>

Nos últimos dois anos, muito tem se falado sobre resiliência.
Foram tempos desafiadores, que cobraram, de todos nós, perseverança para nos reinventarmos, para superar as adversidades. Mas, também, foi um momento em que nos mostramos ainda mais solidários, ávidos pelo conhecimento e dispostos a criar futuros melhores através da tecnologia, da educação, da arte e da saúde. 
Buscamos incansavelmente pela mudança e ressignificamos, assim, o nosso presente. Nesse processo contínuo de metamorfose, nos deparamos com novas possibilidades para viver e nos questionamos quais caminhos nos esperam. 

<br/><br/>
Em 50 anos de história, a Unimed Federação/RS segue construindo pontes para o futuro. E diante dos contextos que se fizeram presentes ao longo dessa trajetória, propõe a reflexão: afinal, para onde vamos? 
Com o intuito de continuarmos juntos nessa nova jornada, convidamos você para a Curso de Extensão: Pensando o AVC
fora do Vaso: – da metamorfose ao metaverso: para onde vamos? 
Uma oportunidade para debatermos sobre os impactos das transformações aceleradas pela pandemia e sobre os novos mundos a serem desbravados pelo Sistema Unimed.
Venha nos ajudar a construir novos 50 anos de crescimento e prosperidade para a Unimed, para você e para o mundo.
 

</p>
</div>
<div className="img" style={{width:'100%', maxWidth: '600px', padding: '25px', margin: '10px 0px'}}>
          <img src="/apoio/capa.png" style={{width: '100%'}}/>
        </div>
      </Container>
    </>
  );
};
export default Apresentacao;

import React, { useEffect, useState } from 'react';
import { urlWebsite } from '../../config';
import { useAuth } from '../../hooks/Auth';
import api from '../../services/api';
import {Container, Sponsor, Apoio, SponsorGrid, ApoioGrid } from './styles';




const apoios = [
{ image : '/assets/evento/apoio/1.jpg', url: ''},
{ image : '/assets/evento/apoio/2.jpg', url: ''},
{ image : '/assets/evento/apoio/3.jpg', url: ''},
{ image : '/assets/evento/apoio/4.jpg', url: ''},
{ image : '/assets/evento/apoio/5.jpg', url: ''},
{ image : '/assets/evento/apoio/6.jpg', url: ''},
{ image : '/assets/evento/apoio/7.jpg', url: ''},
{ image : '/assets/evento/apoio/8.jpg', url: ''},
{ image : '/assets/evento/apoio/9.jpg', url: ''},
{ image : '/assets/evento/apoio/10.jpg', url: ''},
{ image : '/assets/evento/apoio/11.jpg', url: ''},
{ image : '/assets/evento/apoio/12.jpg', url: ''},
{ image : '/assets/evento/apoio/13.jpg', url: ''},


];

export const sponsors = [
  { image : '/assets/evento/patrocinadores/1.jpg', url: ''},
{ image : '/assets/evento/patrocinadores/2.jpg', url: ''},
{ image : '/assets/evento/patrocinadores/3.jpg', url: ''},
{ image : '/assets/evento/patrocinadores/4.jpg', url: ''},
{ image : '/assets/evento/patrocinadores/5.jpg', url: ''},
{ image : '/assets/evento/apoio/14.jpg', url: ''}
]

interface ISponsor {
  url : string;
  link_url ?: string;
  width_mobile ?: string;
  width_computer ?: string;
  
}

interface ISponsors {
  title : string;
  position: number;
  sponsors : Array<ISponsor>;
  size ?: string;
}

const FooterLayout: React.FC = ({ children }) => {
  const {handleApiErrors} = useAuth();
  const [sponsors,setSponsors] = useState<Array<ISponsors>>([])

  useEffect(() => {
    
    const load = async() => {

  try{
     const response = await api.get('sponsors-list');
    if(response) {

      response.data.sort( (a,b) => {
        return a.position > b.position ? 1 : a.position < b.position ? -1 : 0;
      })

      setSponsors(response.data);
    }
  }
  catch(err){
    handleApiErrors(err);
  }

    return;
    }

load();

  },[])

  return (
<>
{ sponsors && sponsors.length > 0 && <Container id="patrocinadores" style={{margin: '20px 0px' }}>


  { sponsors && sponsors.map( lists => (


<div style={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'flex-start',  width: '100%', maxWidth: '1000px', margin: '10px 0px'}}>
<h2 style={{color: '#333', fontSize:'18px', marginTop: '10px', marginBottom: '10px'}}>{lists.title}</h2>
  <ApoioGrid size={lists.size}>
  {lists.sponsors && lists.sponsors.map( apoio => ( apoio.link_url ? <a href={apoio.link_url} target="_BLANK" style={{background: '#fff'}}><Apoio width_computer={apoio.width_computer} width_mobile={apoio.width_mobile}  src={`${urlWebsite}/${apoio.url}`} key={apoio.url} /></a> : <div style={{background: 'none'}} ><Apoio width_computer={apoio.width_computer} width_mobile={apoio.width_mobile} src={`${urlWebsite}/${apoio.url}`} key={apoio.url} /></div>) )}

  </ApoioGrid>
  </div>

  ))


  }
    
        </Container>}

        </>
  );
};

export default FooterLayout;

import React, { useState, createContext, useContext, useCallback } from 'react';
import { uuid } from 'uuidv4';
import api from '../services/api';
import mask from '../utils/mask';
import { apiUrl, getToken } from '../config';
import { useAuth } from './Auth';
import downloadit from '../utils/download';

interface IObject {
  [key: string]: string;
}

interface ISearchOrder {
  orderBy: string;
  direction?: 'asc' | 'desc';
}

export interface ISearchContainer {
  id: string;
  endpoint: string;
  search?: string;
  order?: ISearchOrder;
  currentPage?: number;
  pages?: number;
  limitItems?: number;
  where?: Record<string, any>;
  schema: Record<string, Record<string, any>>;
}

export interface ISearchPagination {
  pages: number;
  currentPage: number;
  limitItems?: number;
  countItems: number;
}

interface ISearchListPagination {
  [key: string]: ISearchPagination;
}

export interface ISearchRows {
  [key: string]: Record<string, any>;
}

interface ISearchContextData {
  addSearch(key: string, config: ISearchContainer): void;
  removeSearch(key: string): void;
  reloadSearch(key: string, type?: string): Promise<Array<Record<string,any>>>;
  reloadSearchAll(): Promise<void>;
  changeSearchWhere(key: string, where: Record<string, any>): Promise<void>;
  changeSearchOrder(
    key: string,
    order: string,
    direction: string,
  ): Promise<void>;
  changePage(key: string, page: number): Promise<void>;
  changeSearchText(key: string, text: string): Promise<void>;

  dataRows: ISearchRows;
  paginations: ISearchListPagination;
  containers: Array<ISearchContainer>;
}

const SearchContext = createContext<ISearchContextData>(
  {} as ISearchContextData,
);

const SearchProvider: React.FC = ({ children }) => {
  const [containers, setContainers] = useState<Array<ISearchContainer>>([]);
  const {handleApiErrors} = useAuth();
  const [dataRows, setDataRows] = useState<ISearchRows>({});
  const [paginations, setPaginations] = useState<ISearchListPagination>({});

  const prepareKey = useCallback(() => uuid(), []);

  const addSearch = useCallback(
    (
      key,
      {
        id,
        endpoint,
        order = { orderBy: 'id', direction: 'desc' },
        where = {},
        search = '',
        pages = 1,
        currentPage = 1,
        limitItems = 20,
        schema,
      }: ISearchContainer,
    ): string => {
      const getPaginations = { ...paginations };
      getPaginations[key] = { pages: 1, currentPage: 1, countItems: 0 };

      setPaginations({ ...getPaginations });

      setContainers(state => [
        ...state,
        {
          id,
          endpoint,
          order,
          where,
          search,
          pages,
          currentPage,
          limitItems,
          schema,
        },
      ]);

      setDataRows(stateRow => ({ ...stateRow, [id]: [] }));

      return id;
    },
    [],
  );

  const removeSearch = useCallback(
    async (id: string) => {
      await setContainers(
        containers.filter((container: ISearchContainer) => container.id !== id),
      );
    },
    [containers],
  );

  const reloadSearch = useCallback(
    async (id: string, type = 'json') => {
      const replaceContainers = [...containers];

      const index = replaceContainers.findIndex(
        (container: ISearchContainer) => container.id === id,
      );

      if (index >= 0) {
        const {
          endpoint,
          search,
          order = [],
          where,
          schema,
        } = replaceContainers[index];

        const currentPage =
          paginations[id] && paginations[id].currentPage
            ? paginations[id].currentPage
            : 1;

        const params = {
          search: search || '',
          order,
          where,
          page: currentPage,
        };

        if (type === 'xlsx') {
          const string = `search=${encodeURIComponent(
            search || '',
          )}&order=${encodeURIComponent(
            JSON.stringify(order),
          )}&page=${currentPage}&where=${encodeURIComponent(
            JSON.stringify(where),
          )}`;

          downloadit({ url: `${endpoint}?hash=${getToken()}&limit=100000&type=xlsx&${string}`});

       
        } else {
          const display : Array<IObject> = [];
     

          try{
            const response = await api.get(endpoint, { params });

            const data = response?.data?.rows ? response.data.rows : response.data;
        
            data.map(
            (items) => {
              const elem: IObject = {};
              Object.keys(schema).map(key => {
                elem[schema[key].column] = schema[key].mask
                  ? mask(items[schema[key].column], schema[key].mask || '')
                  : items[schema[key].column];
              });

              display.push(elem);

            },
            [],
          );
          console.log(response.data.count);
          calculatePages(id, response.data.count);
          }
          catch(err){
            handleApiErrors(err);
        }
          await setDataRows(state => ({ ...state, [id]: display }));
          return display;
        }
      }

      return [];
    },
    [containers],
  );

  const reloadSearchAll = useCallback(async () => {
    Promise.all(containers.map(container => reloadSearch(container.id)));
  }, [containers, reloadSearch]);

  const changeSearchText = useCallback(
    async (id, text) => {
      const replaceContainers = [...containers];

      const index = replaceContainers.findIndex(
        container => container.id === id,
      );

      replaceContainers[index].search = text;

      setContainers([...replaceContainers]);
      await reloadSearch(id);
    },
    [containers, reloadSearch],
  );

  const changePage = useCallback(
    async (id, page) => {
      const replacePagination = { ...paginations };

      if (replacePagination[id]) {
        replacePagination[id].currentPage = page;

        setPaginations({ ...replacePagination });
      }
      await reloadSearch(id);
    },
    [containers, reloadSearch],
  );

  const calculatePages = useCallback(
    async (id, count) => {
      const replacePagination = { ...paginations };

      if (replacePagination[id]) {
        const limit = (replacePagination[id].pages = Math.ceil(
          count / (replacePagination[id].limitItems || 20),
        ));
        replacePagination[id].countItems = count;

        setPaginations({ ...replacePagination });
      }
    },
    [containers, reloadSearch],
  );

  const changeSearchOrder = useCallback(
    async (id, order, direction) => {
      const replaceContainers = [...containers];

      const index = replaceContainers.findIndex(
        container => container.id === id,
      );

      if (replaceContainers[index] &&
        replaceContainers[index]?.order &&
        replaceContainers[index]?.order?.orderBy === order
      ) {
        direction =
          replaceContainers[index]?.order?.direction === 'asc' ? 'desc' : 'asc';
      }

      replaceContainers[index].order = { orderBy: order, direction };

      setContainers([...replaceContainers]);
      await reloadSearch(id);
    },
    [containers, reloadSearch],
  );

  const changeSearchWhere = useCallback(
    async (id, where) => {
      const replaceContainers = [...containers];

      const index = replaceContainers.findIndex(
        container => container.id === id,
      );

      replaceContainers[index].where = where;

      setContainers([...replaceContainers]);
      await reloadSearch(id);
    },
    [containers, reloadSearch],
  );

  return (
    <SearchContext.Provider
      value={{
        addSearch,
        removeSearch,
        reloadSearch,
        reloadSearchAll,
        changeSearchOrder,
        changePage,

        changeSearchText,
        changeSearchWhere,
        paginations,
        dataRows,
        containers,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

function useSearch(): ISearchContextData {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }

  return context;
}

export { useSearch, SearchProvider };

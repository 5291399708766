import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import CopyToClipboard from '../../../../components/CopyToClipboard';
import { urlWebsite } from '../../../../config';
import { FaLink } from 'react-icons/fa';
import { FiCopy, FiMail } from 'react-icons/fi';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/Toast';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}


const Dashboard: React.FC = () => {
  const endpoint = '/speakers_terms_response';
  const title = 'Termos recebidos';

  const {addToast} = useToast();
  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    term_id: { ref: 'term_id', column: 'term_id', label: 'Hash', show: false },
    term_title: { ref: 'term_title', column: 'term_title', label: 'Termo' },
    hash: { ref: 'hash', column: 'hash', label: 'Hash', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'E-mail' },
    document_number: { ref: 'document_number', column: 'document_number', label: 'CPF' },
    term: { ref: 'term', column: 'term', label: 'Aceite' },
  
    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Assinado em',
      mask: 'date',
    },
  };




  const formSchema = {
    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },
    email: { model: 'input', type: 'text', name: 'email', label: 'E-mail' },
    document_number: { model: 'input', type: 'text', name: 'document_number', label: 'CPF' },
   
  };

  const formSchemaUpdate = {

    ...formSchema
  };

  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'name',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };

const sendMail = async (hash) => {

  try{
  await api.post(`/terms_send_confirmation/${hash}`);
  addToast({ type: 'success', title: 'Enviado'});
  return;
  }
  catch(err){
    addToast({ type: 'error', title: 'Erro ao enviar'});
    return;
  }
}
  
  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
    
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema : formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
        (lineData: Record<string, any>) => {
          return <button className="lineIcon" title="Copiar link do termo"><CopyToClipboard Icon={FiCopy} text={`${urlWebsite}/accept_terms/${lineData?.term_id}`}/></button>
          
  
        },
        (lineData: Record<string, any>) => {
          return   <button className="lineIcon"  title="Enviar termo por email" type="button" onClick={() => sendMail(lineData.hash)}>
          <FiMail  />
        </button>
  
        },
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;

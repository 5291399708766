import api from "../services/api";

const downloadit = async ({url}) => {

    try{
      const response = await api.get(url,{  
        headers:
        {
            'Content-Disposition': "attachment; filename=template.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template.xlsx');
      document.body.appendChild(link);
      link.click();
  })
      .catch((error) => console.log(error));
    
    
    
    

      }catch(err) {
    
      
      }
      }

      export default downloadit;
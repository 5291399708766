import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
  FaChevronDown,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import AreaExposicao from '../AreaExposicao';
import AreaInstitucional from '../AreaInstitucional';
import { ContactIcons, scrollTo, WhatsappIcon } from '../../components/Menu';
import Whatsapp from '../../components/Whatsapp';
import { logo, authTitle, eventName } from '../../config';
import {
  Container,
  SecondaryContainer,
  ContainerWhite,
  GreyContainer,
  Capa,
  ProgramContainer,
  Sponsors,
  Photo,
} from './styles';

import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useModal } from '../../hooks/Modal';


import Publicas from './modules/publicas';

import { usePages } from '../../hooks/Pages';

import SponsorsList from '../Sponsors';
import Program from '../Program';
import Youtube from '../../components/Youtube';
import SignIn from '../SignIn';
import Speakers from '../Speakers';
import Apresentacao from '../SobrePresidente';
import LeadForm from '../LeadForm';
import SignUp from '../SignUp';
import Signup2 from '../SignUp2';
import ProgramComplete from '../ProgramComplete';
import { useLanguage } from '../../hooks/Language';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Home: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const formRef = useRef<FormHandles>(null);
  const [cardLayout, setCardLayout] = useState([] as Array<JSX.Element>);
  const { readPages } = usePages();

  const { translate, language } = useLanguage();

  const [showForm, setShowForm] = useState(false)

  const [pages, setPages] = useState(readPages());

  const handleSubmit = useCallback(
    async (data: SignInData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Preencha um email válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const { email, password } = data;

        await signIn({ email, password });
        history.push('/dashboard');
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login',
        });
      }
    },
    [signIn, addToast, history],
  );

  const buildCard: React.FC<IPublicData> = ({ icon, title, text }) => {
    return (
      <div
        onClick={() =>
          setCardLayout([<Publicas icon={icon} text={text} title={title} />])}
      >
        {icon({})}
        <p>{title}</p>
      </div>
    );
  };

  useEffect(() => {
    // addModal({ theme: 'whiteModal', title: '', content: <><img src="/apoio/01-11v2.jpeg" style={{ width: '100%', maxWidth: '600px' }} /></> })
  }, [])

  return (
    <>

      <Capa id="home" style={{ paddingTop: '0px' }}>


        <div style={{ display: 'flex', maxWidth: '970px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <div style={{ padding: '0px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', flexWrap: 'wrap' }} >

            <div className="titleCapa2  " style={{ position: 'relative', width: '100%', alignItems: 'flex-start' }}  >

              <h2 className='textShadow'>{translate('Pensando o AVC')} <br />{translate('fora do Vaso')}: </h2>
              <div className="separatorLine" />
              <h3 className='textShadow'>{translate('do planejamento à implementação de programas.')}</h3>

              <h3 className="marginUp textShadow">{translate('13 de setembro a 6 de dezembro de 2022')}</h3>
              <h3 className='textShadow'>{translate('Coordenação')}: Dra. Sheila Martins</h3>

              <h3 className="marginUpHalf alternateColor">{translate('Curso de Extensão Híbrido')} (40h)</h3>
              <h3 className='alternateColor'>{translate('5 Módulos')}</h3>
              <h3 className='alternateColor'>Faculdade Moinhos de Vento</h3>

              <Link className='marginUp mobileOnly selfCenter' to="/login">
                <button className='buttonInscrevase bold ' >{translate('ACESSAR AULA INAUGURAL')}</button>
              </Link>

              {/* <Link className='marginUpHalf' to="/inscricao">
<button className='buttonInscrevase bold ' >{translate('INSCRIÇÃO GRATUITA - AULA INAUGURAL')}</button>
  </Link> */}




              <a className='marginUpHalf inscrevasediv' href="https://thinkingavc.eventize.com.br/index.php?inscricao" target='_BLANK'>

                <button className='buttonInscrevase bold ' >{translate('INSCREVA-SE!')}</button>



              </a>



              <div className='languageDiv marginUp' style={{ width: 'auto' }}>

                <img src="/apoio/br.png" />
                <img src="/apoio/es.png" />
                <img src="/apoio/en.png" />
                <h3>{translate('Aulas disponíveis em 3 idiomas')}</h3>

              </div>

            </div>


          </div>




        </div>
        <div style={{ width: '100%', maxWidth: '300px' }} className="computerOnly">
          <Link className='marginUpHalf' to="/login">
            <button className='buttonInscrevase bold ' >{translate('ACESSAR CURSO')}</button>
          </Link>
        </div>

      </Capa>

      <Capa style={{ paddingTop: '0px' }}>


        <div style={{ display: 'flex', maxWidth: '1270px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <div style={{ padding: '0px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', flexWrap: 'wrap' }} >


            <div className="titleCapa2  " style={{ position: 'relative' }}  >

              <h3 className="marginUpHalf alternateColor">{translate('SOBRE O CURSO')}</h3>

              {language === 'ptBr' && <>
                <h3 className='textShadow marginUp'>O curso tem por objetivo apresentar e discutir modelos concretos de implementação
                  de programas de AVC no cenário nacional e internacional, abordando a integração de toda a rede assistencial do AVC, desde a atenção básica até o hospital de mais alta
                  complexidade, auxiliando na implantação da Linha de Cuidado do AVC. Por meio de
                  uma abordagem teórica e prática e com referências mundias, os participantes terão
                  a oportunidade de apresentar as suas propostas de modelos de implementação, que
                  também apoiarão a organização das redes de AVC locais.</h3>

                <h3 className='textShadow marginUpHalf'>
                  Em formato híbrido, os encontros ocorrerão por meio de uma plataforma on-line e de forma presencial nos dias 13 de setembro e 06 de dezembro. A Aula Inaugural será
                  realizada no dia 13 de setembro, às 19h, no Instituto Caldeira e o encerramento do curso será no dia 06 de dezembro, às 17h, composto por duas etapas: observership e
                  Grand Round no Hospital Moinhos de Vento.
                </h3>
              </>}

              {language === 'en' && <>
                <h3 className='textShadow marginUp'>The course aims to present and discuss models of implementation of stroke programs in the national and international scenario, addressing the integration of the entire stroke network, from primary care to the hospital of the highest complexity, assisting in the implementation of the Stroke Care Line. Through a theoretical and practical approach and with world references, participants will have the opportunity to present their proposals for implementation models that will be discussed with the help of the teachers of course who will also support the organization of local stroke networks.
                  <br /><br />With simultaneous translation in Portuguese, Spanish and English</h3>


              </>}


            </div>


          </div>




        </div>


      </Capa>

      <Capa id="palestrantes" style={{ paddingTop: '0px' }}>


        <div style={{ display: 'flex', maxWidth: '1270px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <div style={{ padding: '0px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', flexWrap: 'wrap' }} >


            <div className="titleCapa2  " style={{ position: 'relative' }}  >



              <Speakers />

              <h3 className="marginUpHalf alternateColor textShadow  ">{translate('Ficou interessado(a) no curso e deseja saber mais?')}</h3>
              <button className='buttonInscrevase bold marginUpHalf ' onClick={() => {
                addModal({ title: '', content: <LeadForm />, key: 'lead', theme: 'whiteModal' })
              }}>{translate('QUERO RECEBER NOTÍCIAS')}</button>
            </div>


          </div>




        </div>


      </Capa>



      <Capa id="investimento" style={{ paddingTop: '0px' }}>


        <div style={{ display: 'flex', maxWidth: '1270px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <div style={{ padding: '0px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', flexWrap: 'wrap' }} >


            <div className="titleCapa2  " style={{ position: 'relative' }}  >

              <h2 className='textShadow mini'>{translate('Pensando o AVC')} <br />{translate('fora do Vaso')}: </h2>
              <div className="separatorLineMini" />
              <h3 className='textShadow  mini' >{translate('do planejamento à implementação de programas.')}</h3>

              <h3 className="marginUp alternateColor textShadow  selfCenter">{translate('INVESTIMENTO')}</h3>
              <div className=' marginUpHalf' />
              <div className='investDiv selfCenter'>

                {language === 'ptBr' && <>
                  <h3 className='marginUpHalf'>R$ 1.200,00 <span>à vista</span></h3>
                  <h3>ou em até 6x sem juros</h3>
                </>}

                {language === 'en' && <>
                  <h3 className='marginUpHalf'>BRL 1,200.00 </h3>
                  <h3>in up to 6 interest-free installments</h3>
                </>}
              </div>



              <div className='languageDiv marginUp'>

                <img src="/apoio/br.png" />
                <img src="/apoio/es.png" />
                <img src="/apoio/en.png" />
                <h3>{translate('Aulas disponíveis em 3 idiomas')}</h3>

              </div>
              <a className='marginUp  inscrevasediv selfCenter' href="https://thinkingavc.eventize.com.br/index.php?inscricao" target='_BLANK'>

                <button className='buttonInscrevase bold ' >{translate('INSCREVA-SE!')}</button>



              </a>

            </div>




          </div>
        </div>

      </Capa>


      <Capa id="cronograma" style={{ paddingTop: '0px', marginBottom: '70px' }}>


        <div style={{ display: 'flex', maxWidth: '1270px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <div style={{ padding: '0px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', flexWrap: 'wrap' }} >


            <div className="titleCapa2  " style={{ position: 'relative' }}  >

              <h2 className='textShadow mini'>{translate('Pensando o AVC')} <br />{translate('fora do Vaso')}: </h2>
              <div className="separatorLineMini" />
              <h3 className='textShadow  mini' >{translate('do planejamento à implementação de programas.')}</h3>

              <h3 className="marginUp alternateColor textShadow  selfCenter">{translate('CRONOGRAMA')}</h3>

              <ProgramComplete />

              <div className='languageDiv marginUp'>

                <img src="/apoio/br.png" />
                <img src="/apoio/es.png" />
                <img src="/apoio/en.png" />
                <h3>{translate('Aulas disponíveis em 3 idiomas')}</h3>

              </div>
              <a className='marginUp  inscrevasediv selfCenter' href="https://thinkingavc.eventize.com.br/index.php?inscricao" target='_BLANK'>

                <button className='buttonInscrevase bold ' >{translate('INSCREVA-SE!')}</button>



              </a>


            </div>




          </div>
        </div>

      </Capa>


      <aside style={{ position: 'fixed', zIndex: 10000, bottom: '10px', right: '0px' }}>
        <Whatsapp number='5551999923616' message={language === 'en' ? 'SUPPORT' : 'SUPORTE'} text={`${translate('Olá! Estou no website do ')} ${translate(eventName)}`} />
      </aside>

    </>
  );
};
export default Home;

import React from 'react';
import { useModal } from '../../../../../../hooks/Modal';
import uuid from '../../UniqueHash';
import { IYup } from '../../../../../../utils/SchemaCreator';

import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaTrash } from 'react-icons/fa';
import  Button  from '../../Forms/Button';

interface ICreateModule {
  lineData: Record<string, any>;
  title: string;
  formSchema: Record<string, any>;
  endpoint: string;
  validationSchema: Record<string, IYup>;
  apiRef ?: AxiosInstance,
}

export default function DeleteModule({
  apiRef = api,
  lineData,
  endpoint,
}: ICreateModule): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const key = uuid();

  const handleConfirmDelete = async (id: string): Promise<void> => {
    const response = await apiRef.delete(`${endpoint}/${id}`);

    if (response) {
      addToast({ type: 'success', title: 'Removido com sucesso' });
      removeModal(key);
      reloadSearchAll();
    }
  };

  const handleDelete = (id: string): void => {
    addModal({
      title: 'Confirmar remoção?',
      key,
      content: (
        <Button type="button" onClick={() => handleConfirmDelete(id)}>
          Sim
        </Button>
      ),
      theme : "whiteModal"
    });
  };

  const moduleDelete = (idUser: string): JSX.Element => (
    <button className="lineIcon" title="Remover" type="button" onClick={() => handleDelete(idUser)}>
      <FaTrash/>
    </button>
  );

  return moduleDelete(lineData.id);
}

import Create from './Create';
import Delete from './Delete';
import Update from './Update';
import UpdateUnique from './UpdateUnique';
import Cracha from './Cracha';
import CrachaVenda from './CrachaVenda';
import CrachaLote from './CrachaLote';
import QrCode from './QrCode';
import Credenciar from './Credenciar';
const lineModules = {
    create : Create,
    delete : Delete,
    update : Update,
    updateUnique : UpdateUnique,
    cracha : Cracha,
    crachaVenda : CrachaVenda,
    crachaLote : CrachaLote,
    qrCode : QrCode,
    credenciar : Credenciar
}

export default lineModules;
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IconType } from 'react-icons';

import { MdChat } from 'react-icons/md';
import { AiOutlineLike } from 'react-icons/ai';
import { FiMail, FiPhone, FiArrowLeft, FiVideo, FiMessageCircle, FiMessageSquare } from 'react-icons/fi';
import { FaVideo, FaWhatsapp, FaFilePdf } from 'react-icons/fa';
import { useAuth } from '../../hooks/Auth';
import {
  Feira,
  Expositor,
  Content,
  ImageBackground,
  Container,
  ChatIcon,
  Title,
  Suport,
} from './styles';

import { ContactIcons } from '../../components/Menu';
import { urlWebsite } from '../../config';
import api from '../../services/api';
import Youtube from '../../components/Youtube';
import Vimeo from '../../components/Vimeo';
import { useToast } from '../../hooks/Toast';
import ImageGallery from '../../components/ImageGallery';
import VideoGallery from '../../components/VideoGallery';
import PrivateChat, { startChatApi } from '../../components/PrivateChat';
import { useSocket } from '../../hooks/Socket';
import LessonEmoji from '../../components/ClassRoomContainer/modules/LessonEmojis';
import ChatComponent from '../../components/Chat';
import { useLanguage } from '../../hooks/Language';

interface IPhotos {
  url: string;
}

interface IFiles {
  url: string;
  title ?: string;
  description?: string;
  type?: string;
}

interface IVideos {
  type_id: string;
  video_id: string;
}

interface IExpositor {
  id: number;
  background: string;
  capa: string;
  title: string;
  description: string;
  video_id: string;
  video_type: number;
  link_name: string;
  social: Record<string, any>;
  image_gallery: Array<IPhotos>;
  video_gallery: Array<IVideos>;
  category?: number;
  extra_text?: string;
  expositor_files :  Array<IPhotos>;
  capa_alternativa : string;
}

interface IProps {
  link_name : string;
}

interface IChatTrigger {
  room : number;
}

const ChatTrigger : React.FC<IChatTrigger> = ({room}) => {
  const [activeChat, setActiveChat] = useState(false);
  const {user} = useAuth();
  const {addToast} = useToast();
  const {translate} = useLanguage();

  return <> 
   <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '5px',
                      marginTop: '10px',
                      cursor: 'pointer'
                    }}

                    onClick={() => user ?  setActiveChat(!activeChat) : addToast({title: 'Para participar, faça login na plataforma', type:'info'})} 
                  >
                    <FiMessageSquare size={35}/>
                   
                  </div>


                  <div style={{position:'fixed', transition:'0.5s all', zIndex:10000, left:'0px', background:'rgba(0,100,100,0.7)', minHeight:'100vh', top: '0px', marginLeft: activeChat ? '0px' : '-100%' }}>
         <button style={{width:'100%', borderRadius:'0px', padding:'10px'}} className="defaultButtonReverse" onClick={() => setActiveChat(false)}>{translate('Fechar')}</button>

         {user && activeChat && <ChatComponent id={room} />}

         
         </div>
  </>

}

const ExpositorPage: React.FC = () => {
  const { user, handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { link_name } = useParams<IProps>();
  const { socket } = useSocket();
  const [visible, setVisible] = useState(false);
  const [expositors, setExpositors] = useState<IExpositor>({} as IExpositor);

  const {translate} = useLanguage();

  const [videoGallery, setVideoGallery] = useState<Array<IVideos>>([]);

  const [vote, setVote] = useState(false);
  useEffect(() => {
    const load = async () => {

      try{ 
      const expositores = await api.get(`/expositors-list-one/${link_name}`);


      const e = expositores.data;

        e.social = e.social2 ? JSON.parse(e.social2) : [];
        e.video_gallery = e.video_gallery2 ? JSON.parse(e.video_gallery2) : [];
        e.image_gallery = e.image_gallery2 ? JSON.parse(e.image_gallery2) : [];
        e.expositor_files = e.expositor_files2 ? JSON.parse(e.expositor_files2) : [];
        e.banner_gallery = e.banner_gallery2 ? JSON.parse(e.banner_gallery2) : [];



      setExpositors(e);
      setVideoGallery(e?.video_gallery || []);
      socket.emit('SetAttendance', { room: e?.id });

      }
      catch(err){
        handleApiErrors(err);
      }
    };

    load();
  }, []);

  const printFiles = (files) => {

        const item : Array<JSX.Element> = [];
    
      files.map( newItem => { 
        item.push(<a href={`/${newItem.url}`} target="_BLANK"> {newItem.type && newItem.type ==='pdf' && <FaFilePdf/> } {newItem.title} {newItem.description} </a>)});
    
      return item;
      }

  const startChat = (): void => {
    /* Iniciando Chat */
    if (!user) {
      addToast({
        title: 'Login necessário.',
        description: 'Para interagir, é necessário fazer login.',
        type: 'info',
      });

      return;
    }
    if (visible) {
      setVisible(false);
    } else {
      setVisible(true);
      startChatApi(socket, expositors.id, user);
    }
  };

  const voteExpositor = async id => {
    try{
    if (user && user.id) {
      const response = await api.get(`/expositors-vote/${id}`);

      if (response) {
        addToast({
          title: '',
          description: response.data.message,
          type:
            response.data.message === 'Voto Adicionado!' ? 'success' : 'info',
        });

        if (response.data.message === 'Voto Adicionado!') {
          setVote(true);
        } else {
          setVote(false);
        }
      }
    } else {
      addToast({
        type: 'info',
        title: 'Login necessário',
        description: 'É necessário fazer login para curtir',
      });
    }
  }
  catch(err){
    handleApiErrors(err);
  }
  };


  const getVideos = useCallback( () => {

    if(videoGallery?.length > 0){
return        <Container key="aaa"
style={{
  flexDirection: 'column',
  justifyContent: 'flex-start',
}}
>
<h2>Videos</h2>
<div className="separator" style={{ width: '80%' }} />


<VideoGallery key={'a'} data={videoGallery || []} />


</Container>
    }
    else{
      return <></>
    }
    

  },[videoGallery])

  const Private = useCallback(
    () => (
      <PrivateChat id_expositor={expositors.id} expositor_name={link_name} />
    ),
    [link_name, expositors],
  );

  return (
    <>
      {visible && expositors && expositors.id && <Private />}
      <Link
        style={{
          position: 'fixed',
          bottom: '5px',
          left: '5px',
          cursor: 'pointer',
          padding: '10px',
          zIndex: 200,
          background: '#fff',
          color: '#333',
          borderRadius: '10px',
          textDecoration: 'none',
        }}
        to={expositors.category === 1 ? '/#expositores' : '/#instituicoes'}
        onClick={e => {
          e.preventDefault();
          window.location.href = `/exposicao`;
        }}
      >
        <FiArrowLeft /> Voltar
      </Link>
{' '}
      {expositors && expositors.title && (
        <>
          <ImageBackground
            style={{ flexDirection: 'column' }}
            background={expositors?.background}
          >
            <Container id="fotos" style={{alignItems:'flex-start'}}>
              <div className="p50">
                <div className="size400">
                  <Title style={{ fontSize: '32px' }}>
                    {expositors.title}
{' '}
                  </Title>

                  <p
                    style={{ textAlign: 'justify' }}
                    dangerouslySetInnerHTML={{
                      __html: `${expositors.description} `,
                    }}
                  />
                  
                </div>
              </div>
              <div className="p50">
                <div style={{display:'flex', justifyContent:'center', flexWrap:'wrap', alignItems:'flex-start'}}>
                <ContactIcons
                
                  backgroundFill={false}
                  socialIcons={expositors.social}
                >
{/*<ChatTrigger room={expositors.id}/>*/}
                  </ContactIcons>
                </div>
                {expositors.social.email && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '5px',
                      marginTop: '10px',
                    }}
                  >
                    <FiMail />
                    <p style={{ marginLeft: '5px' }}>
                      {expositors.social.email}
                    </p>
                  </div>
                )}

                {expositors.social.telefone && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '5px',
                    }}
                  >
                    <FiPhone />
                    <p style={{ marginLeft: '5px' }}>
                      {' '}
                      {expositors.social.telefone}
                    </p>
                  </div>
                )}

                {expositors.social.zoom && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '5px',
                    }}
                  >
                    <a
                      href={expositors.social.zoom}
                      target="_BLANK"
                      rel="noReferrer"
                      style={{
                        display: 'flex',
                        color: '#fff',
                      }}
                    >
                      <FaVideo />
                      <p style={{ marginLeft: '5px' }}>
                        Acesse nossa Sala no Zoom
                      </p>
                    </a>
                  </div>
                )}

{expositors?.social?.button_text && expositors?.social?.button_link && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '5px',
                    }}
                  >
                    <a target="_BLANK" href={expositors.social.button_link}>
                    <button className="defaultButtonMini" style={{ marginLeft: '5px' }}>
                      {' '}
                      {expositors.social.button_text}
                    </button></a>
                  </div>
                )}

                {expositors.video_id &&
                  <div className="videoContainer" style={{ marginTop: '10px' }}>
                    {expositors.video_type === 1 ? (
                      <Youtube id={expositors.video_id} key={`video-${expositors.video_id}`} />
                    ) : (
                      <Vimeo id={expositors.video_id}  key={`video-${expositors.video_id}`} />
                    )}
                  </div>}

                  {expositors?.capa_alternativa && (
                    expositors?.social?.link_capa_alternativa ? 
                    <a href={expositors?.social?.link_capa_alternativa}  style={{ width: '80%', margin: 'auto' }} target="_BLANK">
                    <img
                    src={`${urlWebsite}/${expositors.capa_alternativa}`}
                    style={{ width: '100%', margin: 'auto' }}
                  />
                  </a>
                  :
                  <img
                  src={`${urlWebsite}/${expositors.capa_alternativa}`}
                  style={{ width: '80%', margin: 'auto' }}
                />

                  )}

               

{/* expositors.expositor_files && <div className="expositor_files"><p style={{width:'100%', textAlign: 'center'}}>Arquivos para download</p> {printFiles(expositors.expositor_files)} </div> */}
              </div>
         
              {expositors.category === -1 && (
                <ChatIcon onClick={() => startChat()}>
                  <MdChat />
                </ChatIcon>
              )}
            </Container>
            {expositors.extra_text && (
              <Container
                style={{
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <h2>Conteúdos</h2>
                <div className="separator" style={{ width: '80%' }} />
                <p
                  style={{ overflowWrap: 'anywhere', textAlign: 'justify' }}
                  dangerouslySetInnerHTML={{
                    __html: `${expositors.extra_text} `,
                  }}
                />
              </Container>
            )}

{getVideos()}
            {expositors?.expositor_files  && expositors?.expositor_files.length > 0 && (
              <Container
                style={{
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <h2>Arquivos de Apoio</h2>
                <div className="separator" style={{ width: '80%' }} />
                <ImageGallery reference="arquivos_estande" id={expositors.id} data={expositors.expositor_files || []} />
              </Container>
            )}

            {expositors?.image_gallery  && expositors?.image_gallery.length > 0 && (
              <Container
                style={{
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <h2>Fotos</h2>
                <div className="separator" style={{ width: '80%' }} />
                <ImageGallery reference="foto_estande" id={expositors.id} data={expositors.image_gallery || []} />
              </Container>
            )}
          </ImageBackground>

          {expositors?.social?.whatsapp && <a style={{padding: '10px', background:'#fff', color:'rgb(0,200,0)', borderRadius:'50%', position:'fixed', bottom: '15px', right: '15px', boxShadow:'3px 3px 15px rgb(0,250,0)' }} href={`https://api.whatsapp.com/send?l=pt&phone=${expositors.social.whatsapp}&text=${encodeURI(expositors?.social?.whatsapp_message ? expositors?.social?.whatsapp_message : `! Estou no seu estande no Fono 2021`)}`} target="_BLANK"><FaWhatsapp size={60}/></a>}
       
 
       
        </>
      )}
    </>
  );
};
export default ExpositorPage;

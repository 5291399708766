const states = [
    { value: 'AC', label: 'Acre (AC)'},
    { value: 'AL', label: 'Alagoas (AL)'},
    { value: 'AP', label: 'Amapá (AP)'},
    { value: 'AM', label: 'Amazonas (AM)'},
    { value: 'BA', label: 'Bahia (BA)'},
    { value: 'CE', label: 'Ceará (CE)'},
    { value: 'DF', label: 'Distrito Federal (DF)'},
    { value: 'ES', label: 'Espírito Santo (ES)'},
    { value: 'GO', label: 'Goiás (GO)'},
    { value: 'MA', label: 'Maranhão (MA)' },
    { value: 'MT', label: 'Mato Grosso (MT)' },
    { value: 'MS', label: 'Mato Grosso do Sul (MS)' },
    { value: 'MG', label: 'Minas Gerais (MG)' },
    { value: 'PA', label: 'Pará (PA)' },
    { value: 'PB', label: 'Paraíba (PB)' },
    { value: 'PR', label: 'Paraná (PR)' },
    { value: 'PE', label: 'Pernambuco (PE)' },
    { value: 'PI', label: 'Piauí (PI)' },
    { value: 'RJ', label: 'Rio de Janeiro (RJ)' },
    { value: 'RN', label: 'Rio Grande do Norte (RN)' },
    { value: 'RS', label: 'Rio Grande do Sul (RS)' },
    { value: 'RO', label: 'Rondônia (RO)' },
    { value: 'RR', label: 'Roraima (RR)' },
    { value: 'SC', label: 'Santa Catarina (SC)' },
    { value: 'SP', label: 'São Paulo (SP)' },
    { value: 'SE', label: 'Sergipe (SE)' },
    { value: 'TO', label: 'Tocantins (TO)' },
    { value: '', label: 'Não sou Brasileiro' },
  ];

  export default states;
import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import { background } from '../../config';
import theme from '../../styles/ThemeComponents';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

export const Container = styled.div`
  min-height: 90vh;
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 0px 10%;

  @media (max-width: 750px) {
    min-height: auto;
    align-items: center;
    padding: 0px;
    justify-content: center;
    width: 100%;
  }
`;

interface IBackground {
  background ?: string;
}

export const AnimatedDiv = styled.div<IBackground>`
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding: 20px 0px;

  @media (max-width: 750px) {
    align-items: center;
  }

  form {
    margin: 0px 0;
    width: 340px;
    max-width: 100%;
    text-align: center;

    @media (max-width: 750px) {
      padding: 0px 20px;
      width: 100%;
      max-width: 90%;
    }

    h2 {
      margin-bottom: 24px;
      color: #fff;
    }

    a {
      color: #fff;
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#fff')};
      }
    }
  }

  > a {
    color: #fff;
    display: block;
    margin-top: 24px;
    text-decoration: none;

    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }
`;

export const AnimatedDiv2 = styled.div<IBackground>`
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  background: none;
  padding: 20px 0px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.3);

  @media (max-width: 750px) {
    align-items: center;
  }

  form {
    margin: 30px 0;
    width: 340px;
    max-width: 100%;
    text-align: center;

    @media (max-width: 750px) {
      padding: 0px 20px;
      width: 100%;
      max-width: 90%;
    }

    h2 {
      margin-bottom: 24px;
      color: #fff;
    }

    a {
      color: #fff;
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#fff')};
      }
    }
  }

  > a {
    color: #fff;
    display: block;
    margin-top: 24px;
    text-decoration: none;

    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;

  z-index: 1;

  img {
    width: 300px;
    max-width: 100%;
    margin: 10px;
  }

  @media (max-width: 750px) {
    padding: 0;
    width: 100%;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: url(${background}) no-repeat top right;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const ErrorContainer = styled.div`
  width: 100%;
  background: rgb(50,50,50) !important;
  color: #fff;
  margin: 20px 0px;
  border-radius: 10px;
  padding: 10px;

  >h2,>p{color: #fff}

`;

import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';
import api from '../../services/api';
import Loader from '../../components/Loader';
import { Link } from 'react-router-dom';
 

const ShowMyCodes: React.FC = () => {

  const [cortesy,setCortesy] = useState<Array<Record<string,any>>>([]);
  const [ready,setReady] = useState(false);
  const [loading,setLoading] = useState(true);

  const load = async() => {
    const response = await api.get('/my-courtesy-codes');

    if(response){
      setCortesy(response.data);
      setReady(true);
      setLoading(false);
    }
    else{
      setCortesy([]);
      setReady(true);
      setLoading(false); 
    }
  }

useEffect(() => {
  load();
},[])

  return (
    <>
{loading && <Loader message='Carregando...'/>}

      {ready && <Container >
        <h2>
          Meus códigos de inscrição
        </h2>
        <p style={{margin:'20px 10px', fontSize:'16px', padding: '15px', maxWidth: '450px', textAlign:'center'}}>Quando você realiza a compra de mais de um ingresso no mesmo pedido, seus ingressos ficam disponíveis nesta página.</p>

      {cortesy.length < 1 ? <aside style={{ width: '350px', padding: '15px', background: '#fff', margin: '20px 10px', borderRadius:'20px', boxShadow: '2px 2px 5px rgba(0,0,0,0.3)', 
        display: 'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
          <strong>Nenhum código encontrado</strong></aside> : <>
      
      {cortesy.map( c => {
        return <Link title="Clique para utilizar" to={`/usar-codigo/${c.hash}`} style={{ width: '350px', padding: '15px', background: '#fff', margin: '20px 10px', borderRadius:'20px', boxShadow: '2px 2px 5px rgba(0,0,0,0.3)', 
        display: 'flex', flexDirection:'column', alignItems:'flex-start', justifyContent:'center'}}>
        <p style={{color: '#333'}}> Código:  <strong style={{fontSize:'12px'}}>{c.hash}</strong></p>
          <p style={{color: '#333'}}>Válido para: <strong>{c.quantity} ingressos</strong></p>
        </Link>
      })}
      
      </>}


      </Container>}
     
    </>
  );
};
export default ShowMyCodes;

import userEvent from '@testing-library/user-event';
import React, { useState, createContext, useContext, useEffect } from 'react';
import StateManager from 'react-select';

import { apiUrl, apiSocket } from '../config';
import { useAuth } from './Auth';

interface IPagesContextData {
  menu: Array<Record<string, any>>;
  pages: Array<Record<string, any>>;
  readPages: Function;
}

const PagesContext = createContext<IPagesContextData>({} as IPagesContextData);

const PagesProvider: React.FC = ({ children }) => {
  const {user} = useAuth();
  const [pages, setPages] = useState([
    { tag: 'home', status: 2 },
    { tag: 'patrocinadores', status: 1 },
    { tag: 'programacao', status: 1 },
    { tag: 'sobre', status: 1 },
    { tag: 'palestrantes', status: 1 },
    { tag: 'login', status: 2 },
    { tag: 'inscricao', status: 2 },
  ]);

  const [menu, setMenu] = useState([
    {
      title: 'HOME',
      target: '/',
      targetID: '',
      fluid: 1,
      external: 1,
      status: 1,
    },
    {
      title: 'SOBRE',
      target: '/',
      targetID: 'sobre',
      fluid: 2,
      external: 1,
      status: 1,
    },
    {
      title: 'CRONOGRAMA',
      target: '/',
      targetID: 'cronograma',
      fluid: 2,
      external: 1,
      status: 2,
    },
    {
      title: 'PALESTRANTES',
      target: '/',
      targetID: 'palestrantes',
      fluid: 2,
      external: 1,
      status: 2,

    },
    {
      title: 'INVESTIMENTO',
      target: '/',
      targetID: 'investimento',
      fluid: 2,
      external: 1,
      status: 2,
      className : ""
    },
    {
      title: 'PATROCÍNIO',
      target: '/patrocinadores',
      targetID: '',
      fluid: 1,
      external: 1,
      status: 1,
      className : ""
    },

    {
      title: 'TEMÁTICAS',
      target: '/tematica',
      targetID: '',
      fluid: 1,
      external: 1,
      status: 1,
      className : ""
    },
    {
      title: 'PROGRAMAÇÃO',
      target: '/',
      targetID: 'programacoes',
      fluid: 2,
      external: 1,
      status: 1,
      className : ""
    },
    {
      title: 'INSCRIÇÃO',
      target: 'https://thinkingavc.eventize.com.br/index.php?inscricao',
      targetID: '',
      fluid:1,
      external: 2,
      status: 2,
      className : ""
    },

    
    {
      title: 'LOCAL',
      target: '/',
      targetID: 'local',
      fluid: 2,
      external: 1,
      status: 1,
      className : ""
    },
    {
      title: 'BENEFÍCIOS',
      target: '/',
      targetID: 'gastronomia',
      fluid: 2,
      external: 1,
      status: 1,
      className : ""
    },
    {
      title: 'VAN OAB',
      target: '/',
      targetID: 'van',
      fluid: 2,
      external: 1,
      status: 1,
      className : ""
    },
    {
      title: 'CONTATO',
      target: '/',
      targetID: 'contato',
      fluid: 2,
      external: 1,
      status: 1,
      className : ""
    },
    {
      title: 'PERGUNTAS FREQUENTES',
      target: '/perguntas',
      targetID: '',
      fluid: 1,
      external: 1,
      status: 1,
    },
    {
      title: 'PALESTRAS',
      target: '/dashboard',
      targetID: '',
      fluid: 1,
      external: 1,
      status: user ? 1 : 1,
    },
    {
      title: 'NOTÍCIAS',
      target: '/noticias',
      targetID: '',
      fluid: 1,
      external: 1,
      status: 1,
    },
    {
      title: 'QUERO SABER MAIS!',
      target: '/',
      targetID: 'inscricao',
      fluid: 2,
      external: 1,
      status: 1,
    },
    {
      title: 'CERTIFICADOS',
      target: '/certificados',
      targetID: '',
      fluid: 1,
      external: 1,
      status: 1,
    },
    {
      title: 'TRABALHOS CIENTÍFICOS',
      target: '/poster',
      targetID: '',
      fluid: 1,
      external: 1,
      status: user ? 1 : 1,
    },

   {
      title: 'EXPOSIÇÃO',
      target: '/exposicao',
      className:"",
      targetID: '',
      fluid: 1,
      external: 1,
      status: user ? 1 : 1,
    },

    {
      title: 'ACESSAR',
      target: '/login',
      className:"",
      targetID: '',
      fluid: 1,
      external: 1,
      status: 2,
    },
 
  ]);

  useEffect(() => {


  
    setMenu([
      {
        title: 'HOME',
        target: '/',
        targetID: '',
        fluid: 1,
        external: 1,
        status: 1,
      },
      {
        title: 'SOBRE',
        target: '/',
        targetID: 'sobre',
        fluid: 2,
        external: 1,
        status: 1,
      },
      {
        title: 'CRONOGRAMA',
        target: '/',
        targetID: 'cronograma',
        fluid: 2,
        external: 1,
        status: 2,
      },
      {
        title: 'PALESTRANTES',
        target: '/',
        targetID: 'palestrantes',
        fluid: 2,
        external: 1,
        status: 2,
  
      },
      {
        title: 'INVESTIMENTO',
        target: '/',
        targetID: 'investimento',
        fluid: 2,
        external: 1,
        status: 2,
        className : ""
      },
      {
        title: 'PATROCÍNIO',
        target: '/patrocinadores',
        targetID: '',
        fluid: 1,
        external: 1,
        status: 1,
        className : ""
      },
  
      {
        title: 'TEMÁTICAS',
        target: '/tematica',
        targetID: '',
        fluid: 1,
        external: 1,
        status: 1,
        className : ""
      },
      {
        title: 'PROGRAMAÇÃO',
        target: '/',
        targetID: 'programacoes',
        fluid: 2,
        external: 1,
        status: 1,
        className : ""
      },
      {
        title: 'INSCRIÇÃO',
        target: 'https://thinkingavc.eventize.com.br/index.php?inscricao',
        targetID: '',
        fluid:1,
        external: 2,
        status: 2,
        className : ""
      },
      {
        title: 'LOCAL',
        target: '/',
        targetID: 'local',
        fluid: 2,
        external: 1,
        status: 1,
        className : ""
      },
      {
        title: 'BENEFÍCIOS',
        target: '/',
        targetID: 'gastronomia',
        fluid: 2,
        external: 1,
        status: 1,
        className : ""
      },
      {
        title: 'VAN OAB',
        target: '/',
        targetID: 'van',
        fluid: 2,
        external: 1,
        status: 1,
        className : ""
      },
      {
        title: 'CONTATO',
        target: '/',
        targetID: 'contato',
        fluid: 2,
        external: 1,
        status: 1,
        className : ""
      },
      {
        title: 'PERGUNTAS FREQUENTES',
        target: '/perguntas',
        targetID: '',
        fluid: 1,
        external: 1,
        status: 1,
      },
      {
        title: 'PALESTRAS',
        target: '/dashboard',
        targetID: '',
        fluid: 1,
        external: 1,
        status: user ? 1 : 1,
      },
      {
        title: 'NOTÍCIAS',
        target: '/noticias',
        targetID: '',
        fluid: 1,
        external: 1,
        status: 1,
      },
      {
        title: 'QUERO SABER MAIS!',
        target: '/',
        targetID: 'inscricao',
        fluid: 2,
        external: 1,
        status: 1,
      },
      {
        title: 'CERTIFICADOS',
        target: '/certificados',
        targetID: '',
        fluid: 1,
        external: 1,
        status: 1,
      },
      {
        title: 'TRABALHOS CIENTÍFICOS',
        target: '/poster',
        targetID: '',
        fluid: 1,
        external: 1,
        status: user ? 1 : 1,
      },
  
     {
        title: 'EXPOSIÇÃO',
        target: '/exposicao',
        className:"",
        targetID: '',
        fluid: 1,
        external: 1,
        status: user ? 1 : 1,
      },
  
      {
        title: 'ACESSAR',
        target: '/login',
        className:"",
        targetID: '',
        fluid: 1,
        external: 1,
        status: 2,
      },
   
    ])

    if(user &&  (user?.profile === 1 || user?.profile === 4)){
      setMenu((state) => [...state,     {
        title: 'GERENCIADOR',
        target: '/manager',
        targetID: '',
        fluid: 1,
        external: 1,
        status: 2,
      }])
    }

  },[user])

  const readPages = () => {
    const pagesResponse = pages.reduce((prev, elem) => {
      prev[elem.tag] = elem;

      return prev;
    }, {});

    return pagesResponse;
  };

  return (
    <PagesContext.Provider value={{ pages, menu, readPages }}>
      {children}
    </PagesContext.Provider>
  );
};

function usePages(): IPagesContextData {
  const context = useContext(PagesContext);

  if (!context) {
    throw new Error('usePages must be used within a PagesProvider');
  }

  return context;
}

export { usePages, PagesProvider };

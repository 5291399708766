import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { uuid } from 'uuidv4';
import QRCode from 'qrcode.react';
import { FaBarcode, FaQrcode } from 'react-icons/fa';
import { AxiosInstance } from 'axios';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../FormComponent';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import Button from '../../Forms/Button';

import { usePrinter } from '../../../../../../hooks/PrinterHook';
import QRCodeComponent from '../../QRCodeComponent';

interface ICreateModule {
  lineData: Record<string, any>;
  title: string;
  formSchema: Record<string, any>;
  endpoint: string;
  validationSchema: Record<string, IYup>;
  apiRef?: AxiosInstance;
}

export default function Cracha({
  apiRef = api,
  lineData,
  title,
  formSchema,
  validationSchema,
  endpoint,
}: ICreateModule): JSX.Element {
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { addPrint, printIt } = usePrinter();
  const { reloadSearchAll} = useSearch();
  const key = uuid();

  const print = async line => {
    removeModal('crachaKey')
   
    
    printIt();
    await api.post(`/credenciar`,{ hash : line.user_hash});
    reloadSearchAll();
    
  };

  const handlePrint = async (line): Promise<void> => {



    const category = line && line.facebook && line.facebook === 'organizacao' ? 'ORGANIZAÇÃO' : line.facebook;


    
   addPrint([
    <div className="qrHolder2" style={{display: 'flex', width:'100%', justifyContent: 'space-between', padding: '15px'}}>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minWidth:'6cm', flexDirection: 'column'}}>
    <h2 style={{fontSize: '16px', width:'100%', textAlign: 'center', color:'#000', paddingRight: '15px' }}>{line?.name?.toUpperCase() || ''}</h2>
    {line?.company && <h2 style={{fontSize: '12px',  width:'100%', marginTop: '10px', textAlign: 'center', color:'#000'}}>{line?.company?.toUpperCase() || ''}</h2>}
    { line?.facebook ? <h2 style={{fontSize: '12px',  width:'100%', marginTop: '10px', textAlign: 'center', color:'#000'}}>{line?.facebook?.toUpperCase() || ''}</h2>: <></>}
      
      </div>    
      
        <div style={{width:'100px', minWidth:'100px'}}><QRCode
    size={100} 
     value={line.user_hash}     renderAs="svg"/></div>
    </div>]
    );
   

print(line);

    
  };

  const handleQR = async (line): Promise<void> => {



    const category = line && line.facebook && line.facebook === 'organizacao' ? 'ORGANIZAÇÃO' : line.facebook;

 
   addPrint([
    <div className="qrHolder2" style={{display: 'flex', width: '100%', minWidth: '8.7cm',  justifyContent: 'space-between', alignItems: 'center',   padding: '15px',  paddingBottom: '0px',paddingTop: '30px'}}>
      <div style={{display: 'flex',width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
    <h2 style={{  fontFamily: 'Arial, Helvetica, sans-serif' ,fontSize: '20px', width:'100%',  textAlign: 'center', color:'#000', }}>{line?.name?.toUpperCase() || ''}</h2>
    {line?.company && <h2 style={{fontSize: '14px',  width:'100%', marginTop: '10px', textAlign: 'center', color:'#000'}}>{line?.company?.toUpperCase() || ''}</h2>}
    { line?.facebook ? <h2 style={{fontSize: '12px',  width:'100%', marginTop: '10px', textAlign: 'center', color:'#000'}}>{line?.facebook?.toUpperCase() || ''}</h2>: <></>}
      </div>      <div style={{width:'100px', minWidth:'100px'}}><QRCode
    size={100} 
     value={line.user_hash}     renderAs="svg"/></div>
    </div>]
    );
    const content =  <><h2 style={{color: '#fff'}}>CRACHÁ</h2><div className="qrHolder2" style={{background: '#fff', display: 'flex',  width: '9cm',  paddingLeft:'20px', paddingRight: '20px', justifyContent: 'space-between',  maxWidth:'8cm', margin: '0px auto',}}>
    <div style={{display: 'flex', width: '100%',  justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginRight: '20px'}}>
      <h2 style={{  fontFamily: 'Arial, Helvetica, sans-serif' ,fontSize: '18px', width:'100%', textAlign: 'center', color:'#000', }}>{line?.name?.toUpperCase() || ''}</h2>
    <h2 style={{fontSize: '12px',  width:'100%', marginTop: '10px', textAlign: 'center', color:'#000'}}>{line?.company?.toUpperCase() || ''}</h2>
    
   { line?.facebook ? <h2 style={{fontSize: '12px',  width:'100%', marginTop: '10px', textAlign: 'center', color:'#000'}}>{line?.facebook?.toUpperCase() || ''}</h2>: <></>}
      </div>
      <div style={{width:'100px', minWidth:'100px'}}><QRCode
    size={100} 
     value={line.user_hash}     renderAs="svg"/></div>
  </div> <Button onClick={() => print(line)}>Imprimir</Button></>;

    if (content) {
      const keyModal = addModal({ title, content, key: 'crachaKey' });
    }


    
  };

  const moduleQRCode = (line): JSX.Element => (

    <button className="lineIcon"  title="Imprimir Crachá" type="button" onClick={() => handleQR(line)}>
    <FaBarcode />
  </button>
 
  );

  return moduleQRCode(lineData);
}

import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle } from '../../../../../../config';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function ContactModule({

  lineData,
 
}: ICreateModule): JSX.Element {

    


  const moduleUpdate = (data: Record<string,any>): JSX.Element => (
    data.phone ? <Whatsapp style={{padding: '5px', display:'flex', justifyContent:'center', alignItems: 'center', width: '40px', minWidth: '40px'}} message="" text={`Olá ${data.name}! Nós somos do ${authTitle}.`} number={`55${data.phone.toString().replace(/[^0-9]/g, '')}`}/> : <></>
  );

  return moduleUpdate(lineData);
}

import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../hooks/Socket';
import { Button } from '../../core/components/Forms/Button/styles';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

export const UpdateCoursesModule: React.FC = () => {

  const {socket} = useSocket();
  const updateCourses = () => {
    socket.emit('SendUpdateCourses');
  }

  return <Button style={{margin:'5px auto'}} onClick={() => updateCourses()}>Atualizar Cursos para participantes</Button>
}

const Course: React.FC = () => {
  const endpoint = '/courses';
  const title = 'Cursos';
  const formSchema = {
    only_by_link: {
      model: 'selectSimple',
      name: 'only_by_link',
      label: 'Somente via Link?',
      
      options: [
        { label: 'Sim', value: 2},
        { label: 'Não', value: 1},


      ]
    
    },
    group_link: {
      model: 'input',
      name: 'group_link',
      label: 'Referência',
      defaultValue: '',
    },
    title: {
      model: 'input',
      name: 'title',
      label: 'Título',
      defaultValue: '',
    },
    type: {
      model: 'selectSimple',
      name: 'type',
      label: 'Tipo',
      
      options: [
        { label: 'Live', value: 'live'},
        { label: 'On Demand', value: 'ondemand'},


      ]
    
    },
    size: {
      model: 'selectSimple',
      name: 'size',
      label: 'Orientação',
      
      options: [
        { label: 'Quadrado', value: 'square'},
        { label: '16:9', value: '16:9'},


      ]
    
    },
    paid: {
      model: 'selectSimple',
      name: 'paid',
      label: 'Pago?',
      
      options: [
        { label: 'Pago', value: 'paid'},
        { label: 'Gratuito', value: 'free'},


      ]
    
    },
    lessons2: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong>
            (
{data.id})
{' '}
{data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong>
            (
{data.id})
{data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </>
      ),
      storageType: 'json',
      endpoint: `/lessons`,
      name: 'lessons2',
      label: 'Aulas',
    },
  };


  const formSchemaUpdate = {
    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto',
    },
    sponsors: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'sponsors',
      label: 'Patrocinadores',
      list: [
        { label: 'Imagem', name: 'url' },
        { label: 'Título', name: 'title' },
        { label: 'Descritivo', name: 'description' },
        { label: 'Link', name: 'link_url' },
        { label: 'Tipo 1 Quadrado, 2 Retangular', name: 'type' },
      ],
    },
    ...formSchema
  };
  const formValidation: Record<string, ValidationSchema> = {
  
  };


  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID' },
    group_link: { ref: 'group_link', column: 'group_link', label: 'Referência' },
    title: { ref: 'title', column: 'title', label: 'Título' },
    image: { ref: 'image', column: 'image', label: 'Capa do Curso' },
    lessons2: { ref: 'lessons2', column: 'lessons2', label: 'Aulas', show: false },
    size: { ref: 'size', column: 'size', label: 'Orientação' },
    type: { ref: 'type', column: 'type', label: 'Tipo' },
    paid: { ref: 'paid', column: 'paid', label: 'Pago?' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };



  return (
    <Container>
      <UpdateCoursesModule/>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Course;

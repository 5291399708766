import styled, { keyframes } from 'styled-components';

interface IBackground {
  background?: string;
}

export const Container = styled.div<IBackground>`
  min-height: auto;

  display: inline-block;
  background: none;
  color: #fff;
  padding: 10px;
  width: 100%;
  max-width: 1000px;
  margin: auto;

  > h2 {
    display: flex;
    justify-content: center;
  }

  table {
    width: 100%;
  }

  tr{
    transition: 0.5s all;
    &:hover{
      
      background: rgba(100,100,100,0.1);
      box-shadow:  2px 2px 5px rgba(0,0,0,0.3);
    }
  }

  td { 
    padding: 10px 5px;
  }

  .miniPrograma {
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    > figure {
      max-width: 100%;
      margin: 5px 0;
      width: 100%;
      > table {
        width: 100%;
        border-collapse: collapse;
        max-width: 100%;
        > tbody {
          max-width: 100%;
          > tr {
            border: 1px solid #ddd;
            padding: 5px 10px;
            > td {
              font-size: 10px;
              font-weight: 400;
              padding: 3px;
              min-width: 100px;
            }
            > td + td {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .programDayHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
    align-items: center;

    > span {
      min-width: 36px;
    }

    > h2 {
      display: flex;
      justify-content: center;
    }


  }

  @media (max-width: 750px) {
    padding: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;

    > tbody {
      > tr {
        border-top: 1px solid #555;
        padding: 5px 10px;

        > td {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 5px 0;
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            > a > {
              button {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
`;

import styled, { keyframes } from 'styled-components';

interface IBackground {
  background?: string;
}

const fade = keyframes`
from {
  opacity: 0;
  transform: translateY(-20px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

const rotate = keyframes`
from {

  transform: rotate(0deg);
}
to{
 
  transform:rotate(180deg);
}
`;

const rotateBack = keyframes`
from {

  transform: rotate(180deg);
}
to{
 
  transform:rotate(360deg);
}
`;

export const Container = styled.div<IBackground>`
  min-height: auto;

  display: flex;
  
  background: none;
  color: #fff;
  padding: 10px;
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  align-items: center;
 // border: 2px solid #333;
  margin: 40px auto;
  background: none;
  padding: 30px 20px;

  border-radius: 50px;

  @media (max-width: 750px) {
    padding: 30px 0px;
  }

  .hover {
    >svg{
     animation: ${fade} 0.5s forwards;
    }
  }

  .rotateSVGBack {
    >svg{
     animation: ${rotateBack} 0.5s forwards;
    }
  }

  .rotateSVG{
    animation: ${rotate} 0.5s ease-in forwards;
  }

  > h2, >h1 {
    display: flex;
    justify-content: center;
  }

  table {
    width: 100%;
  }

  tr{
    transition: 0.5s all;
    background: none;
 

    .boxColor{
      margin-right: 5px;
      width: 10px;
      height: 25px;
      min-width: 10px;
      min-height: 25px;
      background: #333 ;
      transform: skew(-20deg) ;
    }

    .titleProgram{
      border: 2px solid #c300a4;
      border-radius: 15px;
      color: #fff;
      padding: 10px;
      display: inline-block ;
      width: 100% ;
      max-width: 500px;
      background: linear-gradient(126deg, rgba(3, 124, 184, 0.4) 18%, rgba(23, 2, 193, 0.4) 48%, rgba(140, 55, 104, 0.4) 95%) ;
    }

    span,strong{
                color: #fff !important;
              }

     aside.category { 
                color: #fff !important;
                font-weight: bold;
                padding: 5px !important;
                text-transform: uppercase;
                margin-bottom: 10px !important;


              }

    .hourDiv{
      max-width: 140px;
      min-width: 140px;
      color: #333;
      font-weight: bold;
      display:flex;
      align-items:flex-start;
      justify-content: center;
      @media (max-width: 750px) {
        max-width:85px;
        min-width: 85px;
  }

    }

    .optionDiv{
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 750px) {
        flex-direction: column;
  }
    }

    &:hover{
      
     transform: translateX(20px) ;
      
    }

    >td {
      padding:   10px 5px  !important;
      display: flex ;
      justify-content:  flex-start;
      align-items: center;
     
 
 border-radius: 15px;
    }
  }

  td { 
    padding: 10px 5px;

    h2,h3,h4,strong{
      font-size: 18px;
    }
  }

  .miniPrograma {
    font-size: 13px;
    font-weight: 400;
    width: 100%;
    margin-top: 5px;

    strong{
      color : rgb(241, 102, 33);
    }
    > figure {
      max-width: 100%;
      margin: 5px 0;
      width: 100%;
      > table {
        width: 100%;
        border-collapse: collapse;
        max-width: 100%;
        > tbody {
          max-width: 100%;
          > tr {
            border: 1px solid #ddd;
            padding: 5px 10px;
            > td {
              font-size: 10px;
              font-weight: 400;
              padding: 3px;
              min-width: 100px;
 
          

            }
            > td + td {
              
              width: 100%;
            }
          }
        }
      }
    }
  }

  .programDayHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
    align-items: center;

    > span {
      min-width: 36px;
    }

    > h2 {
      display: flex;
      justify-content: center;
    }


  }

  @media (max-width: 750px) {
    padding: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;

    > tbody {
      > tr {
        border-top: 1px solid #555;
        padding: 5px 10px;

        > td {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 5px 0;
          
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            > a > {
              button {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
`;

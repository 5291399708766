import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import { dadosOrganizacao, logo } from '../../config';
import { ContactIcons, WhatsappIcon } from '../../components/Menu';
import Whatsapp from '../../components/Whatsapp';

import { Container, ContainerWhite } from './styles';

import ShowDiv from '../../components/ShowOnScroll';
import VideoBackground from '../../components/VideoBackground';
import TextPopper from '../../components/TextPopper';
import Scroller from '../../components/Scroller';

import Vimeo from '../../components/Vimeo';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Termos: React.FC = () => {
  return (
    <>
      <ContainerWhite id="termos">
        <div className="p50 p600" style={{ textAlign: 'justify' }}>
          <h2> PERGUNTAS FREQUENTES:</h2>
          <br />
          <br />
          <strong>1. O que é o Congresso Internacional de Voz Cantada?</strong>
          <br />
          

    
O I Congresso Internacional de Voz Cantada é o primeiro congresso internacional totalmente voltado à temática da voz cantada, realizado por uma Instituição brasileira, o Instituto de Formação em Voz.  

<br /><br />Seu objetivo é ampliar o alcance e intercâmbio de conhecimentos científicos entre os professores e profissionais do canto, possibilitando discussões sobre a aplicabilidade das descobertas na prática pedagógica e clínica da voz cantada.

<br /><br />O Congresso conta com a parceria com a Faculdade Novo Horizonte – FNH.  

<br /><br /><br /><strong>   2. O que é o PAS7+</strong>
<br />O 7th International Physiology & Acoustics of Singing - PAS7+ é um dos mais importantes eventos internacionais em voz cantada. Seu objetivo é proporcionar aos profissionais do canto (professores de canto, cientistas da voz, fonoaudiólogos, etc.) a oportunidade de interagirem com profissionais de outras áreas, relacionadas à fisiologia e acústica do canto, integrando o que há de mais atual no campo das pesquisas e descobertas científicas dessas disciplinas, à sua prática pedagógica. 

<br /><br />A 7ª edição do PAS é coordenada pelo Instituto de Formação em Voz e tem como parceira a  Universidad Nacional de Educación a Distancia - UNED/Madrid.

<br /><br /><br /><strong> 3. As palestras em outro idioma terão tradução simultânea? </strong>
<br />Sim. Tanto no I Congresso Internacional de Voz Cantada quanto no 7th International Physiology & Acoustics of Singing - PAS7+, todas as palestras e outros idiomas terão tradução simultânea para Português. 

<br /><br />A tradução simultânea do PAS para o Português é uma iniciativa inédita, e surge como contribuição do IFV à inclusão e ampliação de acesso da comunidade do canto brasileira ao conhecimento científico de ponta.

<br /><br /><br /><strong>   4. Haverá certificado de participação?</strong>
<br /> Sim. A participação no evento será certificada e o certificado estará disponível para download na própria plataforma, 1 (um) dia após sua conclusão.

<br /><br /><br /><strong>    5. As palestras serão ao vivo?</strong>
<br /> Sim. Todas as palestras acontecerão ao vivo, nas datas e horários da programação divulgada nos sites oficiais do evento.

<br /><br /><br /><strong>   6. As gravações ficarão disponíveis após o evento?</strong>
<br /> Sim. As gravações do Congresso Internacional de Voz Cantada (dias 29 de abril a 01 de maio) ficarão disponíveis na plataforma por 30 dias. O evento PAS, por decisão do seu próprio comitê não será gravado. 

<br /><br /><br /> <strong>   7. Quais os tipos de ingresso do evento? </strong>
<br /> <strong> LOTE 1: 17/03 a 25/03</strong>
<br />R$ 430,00 profissionais 
<br />R$ 385,00 estudantes de graduação e filiados à PROCANTO

<br /><br /><strong> LOTE 2: 26/03 a 15/04</strong>
<br />R$ 490,00 profissionais
<br />R$ 440,00 estudantes de graduação e filiados à PROCANTO

<br /><br /><strong> LOTE 3: 16/04 a 29/04 </strong>
<br />R$ 570,00 profissionais
<br />R$ 515,00 estudantes de graduação e filiados à PROCANTO

<br /><br /><br />   <strong>  8. Por onde será o acesso ao evento?</strong>
<br /> O acesso será realizado através da plataforma contratada para hospedagem do evento:  avc.faculdademoinhos.com.br e todas as orientações sobre o acesso serão recebidas no e-mail cadastrado no ato da inscrição. 
        </div>
      </ContainerWhite>
    </>
  );
};
export default Termos;

export const hash = '72f2fdd4-f7f7-48b6-9fd9-3fee1609b524';

// export const gateKey = '';
export const gateKey = '';
export const recaptchaKey = '6Ld9r7cZAAAAABw5wijiLD5PhlWqLivIN9NqFKi_';
export const apiUrl = `https://api-global.encontrodigital.com.br/${hash}`;
export const apiSocket = 'https://global-socket.encontrodigital.com.br/';
// export const apiSocket = 'http://localhost:8001';

//export const apiUrl = `http://localhost:8000/${hash}`;

export const urlWebsite = 'https://avc.faculdademoinhos.com.br';
export const supportNumber = `5551981476007`;
export const home = 'Home2';
// export const urlWebsite = 'http://localhost:3000';
export const eventName = 'Curso de Extensão: Pensando o AVC fora do Vaso';
export const logo = '/assets/logo.png';
export const background = '/assets/Fundo.jpg';
export const authTitle = 'Curso de Extensão: Pensando o AVC fora do Vaso';

export const social = {
  whatsapp: '',
  instagram: '',
  facebook: '',
  linkedin: '',
  youtube: '',
  twitter: '',
};

export const dadosOrganizacao = '';

export const defaultTheme = {
  defaultBackground: '#593484',
  defaultColor: 'none',
  logo: {
    minWidth: '450px',
  },
  menu: {
    background: '#593484',
    backgroundSticky: '#593484',
    backgroundMobile: 'none',
    color: '#fff',
    shadow: '0',
    logoColor: 'none',
  },
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(`@${authTitle}:token`);
  return token;
};

import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons';
import { Feira, Expositor, Container } from './styles';
import { ContactIcons } from '../../components/Menu';
import { urlWebsite } from '../../config';
import api from '../../services/api';
import { useInteraction } from '../../hooks/Interactions';
import { useAuth } from '../../hooks/Auth';
import CheckinQR from '../DashboardPages/core/features/CheckinQR';
import Checkins from '../DashboardPages/pages/Checkins';

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface IExpositor {
  id: number;
  background: string;
  capa: string;
  title: string;
  link_name: string;
  social : Record<string,any>;
}

const AreaExpositor: React.FC = () => {

const {user } = useAuth();

useEffect(() => {

  if(!user || (user.profile !== 9 && user.profile !== 8 && user.profile !== 5)){
    window.location.href = '/';
  }

},[])

  return (
    <Container>
     { user.profile === 9 ? <Checkins/> : <CheckinQR/>}
    </Container>
  );
};
export default AreaExpositor;

import styled from 'styled-components';
import { darken } from 'polished';

interface ILink {
  fontSize: string;
  iconSize: string;
}

export const Whats = styled.a<ILink>`
  z-index: 1;
  border-radius: 10px 0px 0px 10px;
  background: rgb(50, 150, 26);
  width: 150px;
  max-width: 100%;
  padding: 9px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: white !important;
  cursor: pointer;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    linear,
    right bottom,
    right top,
    color-stop(0, #18ae00),
    color-stop(0.66, rgb(50, 200, 26))
  );
  text-decoration: none;

  transition: 0.5s all;
  cursor: pointer;

  opacity: 0.8 ;

  &:hover {
    opacity: 1;
    font-weight: bolder;
  }

  .icon {
    width:20px;
    height:20px;
    
  }

  img{
    margin-right: 10px;
  }

  @media (max-width: 760px) {
    width: 150px;
  }
`;

import React from 'react';
import { Menu } from '../../../components/Menu';
import { Wrapper, Content } from './styles';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';
import PopupComponent from '../../../components/PopupContainer';
import { RoomChangeContainer } from '../../../components/RoomChange';

const AuthLayout: React.FC = ({ children }) => {
  const { menu } = usePages();
  return (<>
    <Wrapper>
      <Menu menuList={menu} fixedMenu={false} />
      {children}
     
      <PopupComponent />
      <RoomChangeContainer />
    </Wrapper>
     <FooterLayout />
    </>
  );
};

export default AuthLayout;

import React, { useEffect, useState } from 'react';
import api from '../../../../../../services/api';
import format from '../../../../../../utils/format';
import { ButtonStats } from './styles';

interface ICreateModule {
  lineData: Record<string, any>;
}

const SaleStats : React.FC = () => {

  const [total,setTotal] = useState('R$ 0,00');
  const [paid,setPaid] = useState('R$ 0,00');
  const [waiting,setWaiting] = useState('R$ 0,00');

  const [waitingFunds,setWaitingFunds] = useState('R$ 0,00');
  const [available,setAvailable] = useState('R$ 0,00');

  const load = async () => {

    const response = await api.get('/report/sales_price');

    

    if(response?.data?.counter && response?.data?.counter?.length > 0){

      const paidTotal = response?.data.counter.find( data => data.payment_status_id === 2);
      const waitingTotal = response?.data.counter.find( data => data.payment_status_id === 1);

      const paidValue = paidTotal?.net_total ? paidTotal?.net_total : 0;
      const waitingValue = waitingTotal?.net_total ? waitingTotal?.net_total : 0;

      const totalValue = parseInt(paidValue,10) + parseInt(waitingValue,10);

      setWaiting(`R$ ${format(waitingValue)}`);
      setPaid(`R$ ${format(paidValue)}`);
      setTotal(`R$ ${format(totalValue)}`);
      

    }

  }

  const load2 = async () => {
    const response = await api.get('/check-balance');

    setWaitingFunds( response?.data?.waiting_funds ? `R$ ${format(response?.data?.waiting_funds)}` : `R$ 0,00`);
    setAvailable( response?.data?.available ? `R$ ${format(response?.data?.available)}` : `R$ 0,00`);
  }

  useEffect(() => {
load();
load2();
  },[])

return <><div style={{display:'flex', width:'100%', alignItems:'center', justifyContent:'center', flexWrap:'wrap'}}>
<ButtonStats>Total:{` `} {total} (Líquido)</ButtonStats>
<ButtonStats>Pagos:{` `} {paid}</ButtonStats>
<ButtonStats>Aguardando:{` `} {waiting}</ButtonStats>
</div>

<h2>Vendidos</h2>
<div style={{display:'flex', width:'100%', alignItems:'center', justifyContent:'center', flexWrap:'wrap'}}>
<ButtonStats>Disponível para saque:{` `} {available} </ButtonStats>
<ButtonStats>A receber:{` `} {waitingFunds}</ButtonStats>
</div>
</>

} 

export default SaleStats;

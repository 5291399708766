import React from 'react';
import { Wrapper } from './styles';
import { Menu } from '../../../components/Menu';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';
import { useAuth } from '../../../hooks/Auth';
import PopupComponent from '../../../components/PopupContainer';
import { RoomChangeContainer } from '../../../components/RoomChange';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';

const DefaultLayout: React.FC = ({ children }) => {
  const page = window.location.pathname !== '/';
  const { menu } = usePages();

  const { token, user } = useAuth();




  return (
    <>
      <Wrapper>
        <div style={{position:'relative', zIndex:10, width:'100%'}}>
        <Menu menuList={menu} fixedMenu={false} />
        
        {children}
   
        <PopupComponent />
        <RoomChangeContainer />
        </div>
        <Particles
        params={{
          particles: {
            lineLinked: {
              enable: false,
              distance: 250
            },
            move: {
              speed: 15,
              direction: 'top-right',
              collisions: false,
              outModes: {
                default: 'out',
                left: 'out',
                right: 'out',
                bottom: 'out',
                top: 'out',
              },

             
            },
            opacity: {
              value: 0.4,
              random: false,
              animation: {
                enable: false
              }

            },
            shape: {
              type: 'circle',
              polygon: {
               
                nb_sides: 6,
                
              },
             
            },
     
            color: { value: 'rgb(171, 230, 255)' },

            number: {
              density: {
                enable: true,
                value_area: 2400,
              },
              value: 60,
            },
            size: {
              value: 2,
              random: true,
            },
          },
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 1
        }}
      />
      </Wrapper>
      <FooterLayout />

    
    </>
  );
};

export default DefaultLayout;

import React from 'react';
import CopyToClipboard from '../../../core/components/CopyToClipboard';
import { urlWebsite } from '../../../../../config';

interface ICreateModule {
    lineData: Record<string, any>;
  }
  
  export default function SpeakerLinkModule({
    lineData,
  }: ICreateModule): JSX.Element {

  const moduleCreate = (): JSX.Element => { 

    return lineData.hash ? <CopyToClipboard text={`${urlWebsite}/events/access/${lineData.hash}`}/> : <></>;
  }
     
  

  return moduleCreate();
}

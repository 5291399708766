import React from 'react';
import whatsIcon from '../../assets/whats.png';
import { useLanguage } from '../../hooks/Language';

import { Whats } from './styles';

interface IWhatsapp {
  number?: string;
  text?: string;
  message?: string;
  iconSize?: string;
  fontSize?: string;
  style?: Record<string,any>;
  className ?: string;
}

const Whatsapp: React.FC<IWhatsapp> = ({
  number = '5551981476007',
  text = 'Oi, estou em seu website!',
  message = 'Tire suas dúvidas pelo WhatsApp',
  iconSize = '30px',
  fontSize = '16px',
  className = '',
  style={}
}) => {
  const {translate} = useLanguage();
  return (
    <Whats
      iconSize={iconSize}
      fontSize={fontSize}
      href={`https://api.whatsapp.com/send?l=pt&phone=${number}&text=${encodeURI(
        text,
      )}`}
      className={`floater whatsapp ${className}`}
      style={style}
    >
      <img
        className="icon whats"
        src={whatsIcon}
        title="Whatsapp"
        alt="Whatsapp"
      />
      {translate(message)}
    </Whats>
  );
};

export default Whatsapp;

import React, { Dispatch, useRef, useCallback, useState, useEffect } from 'react';

import { Form } from '@unform/web';
import {
  FiMail,
  FiLock,
  FiUser,
  FiArrowRight,
  FiArrowLeft,
  FiKey,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FaArrowLeft } from 'react-icons/fa';
import { ISigla, readSigla } from '../../siglas';
import { useAuth } from '../../../../hooks/Auth';
import Input from '../../../../components/Forms/Input';
import MaskedInput from '../../../../components/Forms/MaskedInput';
import { useToast } from '../../../../hooks/Toast';
import { AnimatedDiv } from '../../styles';
import { Error, LoginContent } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import SelectSimple from '../../../../components/Forms/SelectSimple';

import Button from '../../../../components/Button';
import { ActiveButton, BuyContext } from '../../index';
import api from '../../../../services/api';
import TextareaInput from '../../../../components/Forms/TextareaInput';
import Loader from '../../../../components/Loader';
import { countries } from './countries';
import getCep from '../../../../services/cep';
import { useLanguage } from '../../../../hooks/Language';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import { authTitle, recaptchaKey } from '../../../../config';
import ReCAPTCHA from 'react-google-recaptcha';
import { MdError } from 'react-icons/md';
import { LanguageIcons } from '../../../../components/Menu';
import states from './states';
interface IRead {
  [key: string]: any;
}

interface ComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
  productId : string;
}

interface SignInData {
  email: string;
  password: string;
}

interface ICaptcha {
  getValue(): string;
}

interface LoginProps {
  [key: string]: string;
}

const LoginContainer: React.FC<ComponentProps> = ({
  buyContext,
  setBuyContext,
  setStep,
  step,
  productId
}) => {
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { productType } = buyContext;
  const [buyType, setBuyType] = useState('');
  const [countryISO, setCountryISO] = useState('BR');

  const [company, setCompany] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const {translate} = useLanguage();
  const [captKey, setCaptKey] = useState(1);
  const siglas = readSigla();
const [stepPosition,setStepPosition] = useState(2);
  const recaptchaRef = useRef(null);
  const [stateValue,setStateValue] = useState('');


  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const handleLogin = useCallback(
    async (data: SignInData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),
            password: Yup.string()
            .required(`${translate('Senha')} ${translate('obrigatório')}`)

          
        });

        await schema.validate(data, { abortEarly: false });

        const { email, password } = data;

        await signIn({ email, password });
        
        setLoading(false);
        window.location.href = `/checkout/${buyContext?.product?.hash_link}`;
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          setLoading(false);
        }
        setLoading(false);
        addToast({
          type: 'error',
          title: translate('Erro na autenticação'),
          description: translate('Email ou senha inválidos'),
        });
      }
    },
    [signIn, addToast, buyContext, setBuyContext, setStep],
  );



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLogin = useCallback(
    async (data: LoginProps) => {
      setLoading(true);




      if(stepPosition === 2){


        try{

        formRef.current?.setErrors({});


        const schema =   countryISO === 'BR' ? Yup.object().shape( {

          name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),
            cpf_familiar: Yup.string()
  
            .required(`${translate('RG')} ${translate('obrigatório')}`),
            extra_5: Yup.string()
  
            .required(`${translate('ORGÃO EMISSOR DO RG')} ${translate('obrigatório')}`),

            company: Yup.string().required(`${translate('Informe a sua UNIMED')} ${translate('')}`),
            position: Yup.string().required(`${translate('Informe o seu cargo')} ${translate('')}`),
            education_id: Yup.string().required(`${translate('Escolaridade')} ${translate('obrigatória')}`),


            phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),
            
          password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),
       
          document_number: Yup.string()
            .min(11, `${translate('CPF')} ${translate('obrigatório')}`)
            .required(`${translate('CPF')} ${translate('obrigatório')}`),
 
          birth_date: Yup.string()
            .min(10, translate('min 10 Preecha a Data de Nascimento'))
            .max(10, translate('max 10 Preecha a Data de Nascimento'))
            .required(`${translate('Data de Nascimento')} ${translate('obrigatório')}`),

           

           

        } ) : Yup.object().shape( {
  
          name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),

   
         phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),
          password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),

          document_number: Yup.string()
  
            .required(`${translate('CPF')} ${translate('obrigatório')}`),

            cpf_familiar: Yup.string()
  
            .required(`${translate('RG')} ${translate('obrigatório')}`),
            extra_5: Yup.string()
  
            .required(`${translate('ORGÃO EMISSOR DO RG')} ${translate('obrigatório')}`),
            
            company: Yup.string().required(`${translate('Informe a sua UNIMED')} ${translate('')}`),
            position: Yup.string().required(`${translate('Informe o seu cargo')} ${translate('')}`),
            education_id: Yup.string().required(`${translate('Escolaridade')} ${translate('obrigatória')}`),
            
          birth_date: Yup.string()
            .min(10, translate('min 10 Preecha a Data de Nascimento'))
            .max(10, translate('max 10 Preecha a Data de Nascimento'))
            .required(translate(`${translate('Data de Nascimento')} ${translate('obrigatória')}`)),
     
        });
        data.phone = data.phone.toString().replace(/[^0-9]/g, '');
        data.birth_date.toString().replace(/[^0-9/]/g, '');
        data.document_number.toString().replace(/[^0-9]/g, '');



        await schema.validate(data, { abortEarly: false });
        setLoading(false);
        setStepPosition(22);
        setErrorMessage([]);
        return;
      }
      catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          if (formRef && formRef.current && formRef.current.setErrors) {
            formRef.current.setErrors(errors);
          }
          setLoading(false);
  
          const errorsString = Object.keys(errors).reduce(
            (prev: Array<string>, key: string): Array<string> => {
              prev = [...prev, errors[key]];
              return prev;
            },
            [],
          );
  
          setErrorMessage(errorsString);
          setCaptKey(state => state + 1);
          return;
        }
  
        setLoading(false);
        return;
      }

return;
    }




      try {
        formRef.current?.setErrors({});


        const schema =   countryISO === 'BR' ? Yup.object().shape( {

          name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),

 
            cpf_familiar: Yup.string()
  
            .required(`${translate('RG')} ${translate('obrigatório')}`),
            extra_5: Yup.string()
  
            .required(`${translate('ORGÃO EMISSOR DO RG')} ${translate('obrigatório')}`),
            
            company: Yup.string().required(`${translate('Informe a sua UNIMED')} ${translate('')}`),
            position: Yup.string().required(`${translate('Informe o seu cargo')} ${translate('')}`),
            education_id: Yup.string().required(`${translate('Escolaridade')} ${translate('obrigatória')}`),
            phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),
          password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),
   
          document_number: Yup.string()
            .min(11, `${translate('CPF')} ${translate('obrigatório')}`)
            .required(`${translate('CPF')} ${translate('obrigatório')}`),

          birth_date: Yup.string()
            .min(10, translate('min 10 Preecha a Data de Nascimento'))
            .max(10, translate('max 10 Preecha a Data de Nascimento'))
            .required(`${translate('Data de Nascimento')} ${translate('obrigatório')}`),

            zipcode: Yup.string()
            .min(8, `${translate('CEP')} ${translate('obrigatório')}`)
            .max(8, `${translate('CEP')} ${translate('obrigatório')}`)
            .required(`${translate('CEP')} ${translate('obrigatório')}`),
          address: Yup.string().required(`${translate('Endereço')} ${translate('obrigatório')}`),
          address_number: Yup.string().required(`${translate('Endereço')} ${translate('obrigatório')}`),
          neighborhood: Yup.string().required(`${translate('Bairro')} ${translate('obrigatório')}`),
          city: Yup.string().required(`${translate('Cidade')} ${translate('obrigatório')}`),
          state: Yup.string()
            .max(2, translate('No máximo 2 caracteres'))
            .required(translate(`${translate('Estado')} ${translate('obrigatório')}`)),

        } ) : Yup.object().shape( {
  
          name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),
 
         phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),
          password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),

          document_number: Yup.string()
  
            .required(`${translate('CPF')} ${translate('obrigatório')}`),
            cpf_familiar: Yup.string()
  
            .required(`${translate('RG')} ${translate('obrigatório')}`),
            extra_5: Yup.string()
  
            .required(`${translate('ORGÃO EMISSOR DO RG')} ${translate('obrigatório')}`),
            
            company: Yup.string().required(`${translate('Informe a sua UNIMED')} ${translate('')}`),
            position: Yup.string().required(`${translate('Informe o seu cargo')} ${translate('')}`),
            education_id: Yup.string().required(`${translate('Escolaridade')} ${translate('obrigatória')}`),
          birth_date: Yup.string()
            .min(10, translate('min 10 Preecha a Data de Nascimento'))
            .max(10, translate('max 10 Preecha a Data de Nascimento'))
            .required(translate(`${translate('Data de Nascimento')} ${translate('obrigatória')}`)),

        
          address: Yup.string().required(`${translate('Endereço')} ${translate('obrigatório')}`),
          address_number: Yup.string().required(`${translate('Número do Endereço')} ${translate('obrigatório')}`),
          neighborhood: Yup.string().required(`${translate('Bairro')} ${translate('obrigatório')}`),
          city: Yup.string().required(`${translate('Cidade')} ${translate('obrigatório')}`),
          state: Yup.string()

            .required(`${translate('Estado')} ${translate('obrigatório')}`),
     
        });
        data.phone = data.phone.toString().replace(/[^0-9]/g, '');
        data.birth_date.toString().replace(/[^0-9/]/g, '');
        data.document_number.toString().replace(/[^0-9]/g, '');

        data.company_document_number.toString().replace(/[^0-9]/g, '');

        

        let {
          instituicao,
          category_id,
          crm,
          cpf_familiar,
          name,
          repeat_email,
          email,
          password,
          company,
          phone,
          position,
          document_type,
          document_number,
          know_about,
          birth_date,
          company_document_number,
          extra_1,
          extra_2,
          extra_3,
          extra_4,
          extra_5,
          education_id,
          especialidade,
          country,
          address,
          address_number,
          neighborhood,
          city,
          state,
          zipcode,
          gender,
          facebook,
          term
        } = data;


        await schema.validate(data, { abortEarly: false });

        

      

        const pais = countries.findIndex(c => c.value === country);
        if(pais){
          extra_3 = countries[pais].dialCode;
          //extra_2 = countries[pais].label;
        }

        const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

        const newUser = await api
          .post('/signup', {
            ...data,
            document_number: document_number.toString().replace(/[^0-9]/g, ''),
            term,
            captcha: captchaValue,
          })
          .catch(error => {
            if (error instanceof Yup.ValidationError) {
              const errors = getValidationErrors(error);
              formRef.current?.setErrors(errors);
              setLoading(false);
              setCaptKey(state => state + 1);
            }

            addToast({
              type: 'error',
              title:  error?.response?.data?.message ||
              error?.response?.data?.error ||
              'Erro ao gerar seu cadastro',
            });
            setLoading(false);
            return false;
          });

        if (!newUser) {
          setLoading(false);
          return false;
        }
        await signIn({ email, password });
        window.location.href = `/checkout/${buyContext?.product?.hash_link}`;
        
        setLoading(false);
        
        window.scrollTo(0,0);

        return false;
      }
        catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);
            if (formRef && formRef.current && formRef.current.setErrors) {
              formRef.current.setErrors(errors);
            }
            setLoading(false);
    
            const errorsString = Object.keys(errors).reduce(
              (prev: Array<string>, key: string): Array<string> => {
                prev = [...prev, errors[key]];
                return prev;
              },
              [],
            );
    
            setErrorMessage(errorsString);
            setCaptKey(state => state + 1);
            return;
          }
    
          setErrorMessage([
            err?.response?.data?.message ||
              err?.response?.data?.error ||
              'Erro ao gerar seu cadastro',
          ]);
        }
        setCaptKey(state => state + 1);
        setLoading(false);
      
    },
    [addToast, buyContext, setBuyContext, setStep, signIn,countryISO, stepPosition],
  );

  useEffect(() => {
    const product = buyContext?.products?.[0];

    if(product){
      const index = product?.hash_link.indexOf('presencial') >= 0 || product?.hash_link.indexOf('trilha') >= 0;

      if(index){
        setBuyType('new');
      }
    }


  },[])

  const escolaridade = [
     { value: "ANALFABETO",label: "ANALFABETO", text :"ANALFABETO"},
    { value: "PRIMÁRIO INCOMPLETO (ATÉ 4º)",label: "PRIMÁRIO INCOMPLETO (ATÉ 4º)", text :"PRIMÁRIO INCOMPLETO (ATÉ 4º)"},
    { value: "PRIMÁRIO COMPLETO",label: "PRIMÁRIO COMPLETO", text :"PRIMÁRIO COMPLETO"},
    { value: "ENSINO FUNDAMENTAL INCOMPLETO",label: "ENSINO FUNDAMENTAL INCOMPLETO", text :"ENSINO FUNDAMENTAL INCOMPLETO"},
    { value: "ENSINO FUNDAMENTAL COMPLETO",label: "ENSINO FUNDAMENTAL COMPLETO", text :"ENSINO FUNDAMENTAL COMPLETO"},
    { value: "ENSINO MÉDIO INCOMPLETO",label: "ENSINO MÉDIO INCOMPLETO", text :"ENSINO MÉDIO INCOMPLETO"},
    { value: "ENSINO MÉDIO COMPLETO",label: "ENSINO MÉDIO COMPLETO", text :"ENSINO MÉDIO COMPLETO"},
    { value: "ENSINO SUPERIO INCOMPLETO",label: "ENSINO SUPERIO INCOMPLETO", text :"ENSINO SUPERIO INCOMPLETO"},
    { value: "ENSINO SUPERIOR COMPLETO",label: "ENSINO SUPERIOR COMPLETO", text :"ENSINO SUPERIOR COMPLETO"},
    { value: "PÓS GRADUAÇÃO - ESPECIALIZAÇÃO",label: "PÓS GRADUAÇÃO - ESPECIALIZAÇÃO", text :"PÓS GRADUAÇÃO - ESPECIALIZAÇÃO"},
    { value: "MESTRADO",label: "MESTRADO", text :"MESTRADO"},
    { value: "DOUTORADO",label: "DOUTORADO", text :"DOUTORADO"}
    
  ]




const unimeds = [
{ value: "UNIMED FEDERAÇÃO/RS", label: "UNIMED FEDERAÇÃO/RS", text :"UNIMED FEDERAÇÃO/RS"},
 
  { value: "UNIMED CENTRAL DE SERVIÇOS – RS", label: "UNIMED CENTRAL DE SERVIÇOS – RS", text :"UNIMED CENTRAL DE SERVIÇOS – RS"},
  { value: "UNIMED ALEGRETE/RS", label: "UNIMED ALEGRETE/RS", text :"UNIMED ALEGRETE/RS"},
  { value: "UNIMED ALTO DA SERRA", label: "UNIMED ALTO DA SERRA", text :"UNIMED ALTO DA SERRA"},
  { value: "UNIMED ALTO JACUÍ/RS", label: "UNIMED ALTO JACUÍ/RS", text :"UNIMED ALTO JACUÍ/RS"},
  { value: "UNIMED ALTO URUGUAI/RS", label: "UNIMED ALTO URUGUAI/RS", text :"UNIMED ALTO URUGUAI/RS"},
  { value: "UNIMED CENTRO/RS", label: "UNIMED CENTRO/RS", text :"UNIMED CENTRO/RS"},
  { value: "UNIMED ENCOSTA DA SERRA/RS", label: "UNIMED ENCOSTA DA SERRA/RS", text :"UNIMED ENCOSTA DA SERRA/RS"},
  { value: "UNIMED ERECHIM", label: "UNIMED ERECHIM", text :"UNIMED ERECHIM"},
  { value: "UNIMED FRONTEIRA NOROESTE/RS", label: "UNIMED FRONTEIRA NOROESTE/RS", text :"UNIMED FRONTEIRA NOROESTE/RS"},
  { value: "UNIMED ITAQUI RS", label: "UNIMED ITAQUI RS", text :"UNIMED ITAQUI RS"},
  { value: "UNIMED LITORAL SUL/RS", label: "UNIMED LITORAL SUL/RS", text :"UNIMED LITORAL SUL/RS"},
  { value: "UNIMED MISSÕES/RS", label: "UNIMED MISSÕES/RS", text :"UNIMED MISSÕES/RS"},
  { value: "UNIMED NORDESTE RS", label: "UNIMED NORDESTE RS", text :"UNIMED NORDESTE RS"},
  { value: "UNIMED NOROESTE/RS", label: "UNIMED NOROESTE/RS", text :"UNIMED NOROESTE/RS"},
  { value: "UNIMED PELOTAS/RS", label: "UNIMED PELOTAS/RS", text :"UNIMED PELOTAS/RS"},
  { value: "UNIMED PLANALTO CENTRAL/RS", label: "UNIMED PLANALTO CENTRAL/RS", text :"UNIMED PLANALTO CENTRAL/RS"},
  { value: "UNIMED PLANALTO MÉDIO", label: "UNIMED PLANALTO MÉDIO", text :"UNIMED PLANALTO MÉDIO"},
  { value: "UNIMED PORTO ALEGRE", label: "UNIMED PORTO ALEGRE", text :"UNIMED PORTO ALEGRE"},
  { value: "UNIMED REGIÃO DA CAMPANHA/RS", label: "UNIMED REGIÃO DA CAMPANHA/RS", text :"UNIMED REGIÃO DA CAMPANHA/RS"},
  { value: "UNIMED REGIÃO DA FRONTEIRA/RS", label: "UNIMED REGIÃO DA FRONTEIRA/RS", text :"UNIMED REGIÃO DA FRONTEIRA/RS"},
  { value: "UNIMED REGIÃO DA PRODUÇÃO/RS", label: "UNIMED REGIÃO DA PRODUÇÃO/RS", text :"UNIMED REGIÃO DA PRODUÇÃO/RS"},
  { value: "UNIMED SANTA MARIA/RS", label: "UNIMED SANTA MARIA/RS", text :"UNIMED SANTA MARIA/RS"},
  { value: "UNIMED URUGUAIANA/RS", label: "UNIMED URUGUAIANA/RS", text :"UNIMED URUGUAIANA/RS"},
  { value: "UNIMED VALE DAS ANTAS, RS", label: "UNIMED VALE DAS ANTAS, RS", text :"UNIMED VALE DAS ANTAS, RS"},
  { value: "UNIMED VALE DO CAÍ/RS", label: "UNIMED VALE DO CAÍ/RS", text :"UNIMED VALE DO CAÍ/RS"},
  { value: "UNIMED VALE DO SINOS", label: "UNIMED VALE DO SINOS", text :"UNIMED VALE DO SINOS"},
  { value: "UNIMED VALES DO TAQUARI E RIO PARDO", label: "UNIMED VALES DO TAQUARI E RIO PARDO", text :"UNIMED VALES DO TAQUARI E RIO PARDO"},
  { value: "UNICOOPMED", label: "UNICOOPMED", text :"UNICOOPMED"},
  { value: "UNIAIR TRANSPORTE AEROMÉDICO", label: "UNIAIR TRANSPORTE AEROMÉDICO", text :"UNIAIR TRANSPORTE AEROMÉDICO"},
  { value: "INSTITUTO UNIMED/RS", label: "INSTITUTO UNIMED/RS", text :"INSTITUTO UNIMED/RS"},
  
  ];

  return (
    <AnimatedDiv  visible={step[1] === 3}>
      <LoginContent>
        {loading && <Loader message="Carregando" />}
        <div style={{width:'100%', justifyContent:'center'}}>
          {' '}
          <aside style={{width:'100%', maxWidth:'100%'}}>
            {buyType === '' && (
              <AnimatedDiv visible style={{paddingTop: '40px'}}>
                <ActiveButton step={11}/>
                 <div style={{ display: 'flex', flexDirection:'column', flexWrap:'wrap',  justifyContent:'center', alignItems:'stretch' }}>
                <button className='defaultButton' style={{margin: '15px', width:'100%', maxWidth:'340px', minHeight:'50px', padding: '10px'}}
                    
                    onClick={() => setBuyType('new')}
                  >
                    {translate('Novo Cadastro')}
                  </button>
                
                <p style={{width:'100%', background:'#333', height:'2px', position:'relative', margin: '20px 0px'}}> 
                  <p style={{position:'absolute',  left: '50%', transform:'translateX(-50%) translateY(-20px)', fontSize: '12px', background:'#333', color: '#fff', borderRadius:'50%', width: '40px', height:'40px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                    {translate('OU')}
                  </p>
                </p>
                
                <Form  className="form" style={{margin: '15px'}} ref={formRef} onSubmit={handleLogin}>
               <h2 style={{marginBottom: '20px'}}>{translate('Faça seu login')}</h2>
      
               <Input icon={FiMail} name="email" placeholder={translate('E-mail')} />
               <Input icon={FiKey} type='password' name="password" placeholder={translate('Senha')} />
        <aside className="form2" style={{display:'flex', width: '100%', justifyContent:'flex-end', alignItems:'stretch', marginTop: '20px', padding:'0px'}}>
             {/*  <Input
                 icon={FiLock}
                 name="password"
                 type="password"
                 placeholder={translate('Senha')}
             /> */}
               <button className="defaultButtonMini" style={{padding: '10px', margin: '10px auto', width: '200px'}} type="submit">{translate('LOGIN')}</button></aside>
               <aside style={{width:'100%', display:'flex', justifyContent:'flex-start'}}>
             {/*   <Link className="underline" style={{marginTop:'0px'}} to="/recuperar_senha">
               {translate('Esqueceu a senha? Clique aqui!')}
            </Link> */}
               </aside>
       
             </Form>
  
                
                </div>
                <p 
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    fontSize:'12px',
                    color: '#333',
                    marginTop: '20px'
                  }}
                  onClick={() => setStep([1,1])}
                 
                  
                >
                  <FaArrowLeft color="#333" />
{' '}
{translate('Voltar')}
</p>
              </AnimatedDiv>
            )}
            {buyType !== '' && (
              <AnimatedDiv visible>
  

                {buyType === 'login' && (
                  <Form  className="form" ref={formRef} onSubmit={handleLogin}>
               
                    <h1 className="defaultMarked"><div/>{translate('LOGIN')}</h1>
                    <Input icon={FiMail} name="email" placeholder={translate('E-mail')} />
             
                    <Input
                      icon={FiLock}
                      name="password"
                      type="password"
                      placeholder={translate('Senha')}
                    />
                    <Button type="submit">{translate('Entrar')}</Button>
                    <Link style={{ color: '#333' }} to="/recuperar_senha">
                    {translate('Esqueci minha senha')}
                    </Link>
                    <p 
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    fontSize:'12px',
                    color: '#333',
                    marginTop: '20px'
                  }}
                  onClick={() => setBuyType('')}
                 
                  
                >
                  <FaArrowLeft color="#333" />
{' '}
{translate('Voltar')}
</p>
                  </Form>
                  
                )}

                {buyType === 'new' && (
                  <Form className="form" ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR'}}>
                    <ActiveButton step={stepPosition}/>
                   {/*  <p style={{width: '100%', padding: '10px', margin: '20px 0px', color: '#333', display:'flex', justifyContent:'center', alignItems: 'center', background:'#fff'}}><span style={{marginRight: '5px'}}>{translate('Selecionar idioma')}: </span> <LanguageIcons/></p>
                  */}

                   


                  <div style={{width: '100%', background:'none', flexDirection:'column', display: stepPosition === 2? 'flex' : 'none'}}>
                  <h2 style={{width: '100%', fontSize: '18px', color: '#333', textAlign: 'center', margin:'0px auto', marginTop: '40px'}} ><div/>{translate('Novo cadastro')}</h2>
                 
                 
                    <SelectSimple setCallback={(value) => setCountryISO(value) } options={countries} name="country" label={translate('Pais')}/>
                    
                    <Input name="name" placeholder={translate('Nome completo')} /> 
      
                    <Input name="email" placeholder={translate('E-mail')} />
                   
                   
                    <Input
                      icon={FiLock}
                      name="password"
                      type="password"
                      placeholder={translate('Cadastre sua senha de acesso')}
                    />
              

                 

                    { countryISO === 'BR' ? <MaskedInput
                      mask="(99) 99999.9999"
                      name="phone"
                      placeholder={translate('Telefone / Whatsapp')}
                    /> : <Input name="phone"
                    placeholder={translate('Telefone')}/>}

 <Input  name="cpf_familiar"
                placeholder={translate('RG')}/> 

<Input  name="extra_5"
                placeholder={translate('ORGÃO EMISSOR DO RG')}/> 



<SelectSimple name="company" label="UNIMED" options={unimeds} setCallback={(value) => setCompany(value)} />
{company === 'OUTRO' && <Input name="extra_4" placeholder="Qual?" />}

<Input name="position" placeholder="Cargo" />

<SelectSimple name="education_id" label="Escolaridade" options={escolaridade}   />

{ countryISO === 'BR' ? <Input style={{display:'none'}} name="document_type" value="cpf"
                placeholder={translate('Tipo de documento')}/> :  <SelectSimple name="document_type"
                label={translate('Tipo de Documento (Passaporte / Outro)')} options={[{label: translate('Passaporte'), value: 'passport'}, {label: translate('Outro'), value: 'other'}]} />  }


{ countryISO === 'BR' ? <Input style={{display:'none'}} name="document_type" value="cpf"
                placeholder={translate('Tipo de documento')}/> :  <SelectSimple name="document_type"
                label={translate('Tipo de Documento (Passaporte / Outro)')} options={[{label: translate('Passaporte'), value: 'passport'}, {label: translate('Outro'), value: 'other'}]} />  }



{ countryISO === 'BR' ? <MaskedInput
                      mask="999.999.999-99"
                      name="document_number"
                      placeholder={translate('CPF')}
                /> :  <Input name="document_number"
                placeholder={translate('Nº do documento')}/> }

                

                    <MaskedInput
                      mask="99/99/9999"
                      name="birth_date"
                      placeholder={translate('Data de Nascimento (DD/MM/AAAA)')}
                    />



{errorMessage && (
              <Error>
                {errorMessage.map((item, index) => (
                  <div key={`error${index}`}>
                    <MdError />
                    {item}
                  </div>
                ))}
              </Error>
            )}
<aside style={{width: '100%', display:'flex', justifyContent:'center', alignItems: 'center'}}>
<Button>{translate('Continuar')}</Button></aside>

</div>
<div style={{width: '100%', background:'none', display: stepPosition === 22? 'flex' : 'none', flexDirection: 'column'}}>

                  <button onClick={() => setStepPosition(2)} type="button" className='defaultButtonMini'><FaArrowLeft size={20} style={{marginRight: '10px'}}/>Voltar</button>

<h2 style={{width: '100%', fontSize: '18px', color: '#333', textAlign: 'center', margin:'0px auto', marginTop: '40px'}} ><div/>{translate('Meu endereço')}</h2>
                 
 { countryISO === 'BR' ?
<MaskedInput

              setCallback={getCepFromApi}
              mask="99999999"
              name="zipcode"
              placeholder={translate('CEP')}
            /> : <Input name="zipcode" placeholder={translate('ZIP Code')} /> }
            <Input name="address" placeholder={translate('Endereço')} />
            <Input name="address_number" placeholder={translate('Número / Apartamento')} />
            <Input name="neighborhood" placeholder={translate('Bairro')} />
            <Input name="city" placeholder={translate('Cidade')} />
            { countryISO === 'BR' ?     <MaskedInput mask="aa" name="state" placeholder={translate('Estado')} /> : 

<Input name="state" placeholder={translate('Província / Região')} />}
   




<h2 style={{width: '100%', fontSize: '18px', color: '#333', textAlign: 'center', margin:'0px auto', marginTop: '40px'}}>Dados da empresa</h2>
<p style={{width: '100%', fontSize: '12px', color: '#333', textAlign: 'center', marginBottom: '20px'}} >Necessário para compra usando CNPJ</p>


{ countryISO === 'BR' ? <MaskedInput

                      mask="99.999.999/9999-99"
                      name="company_document_number"
                      placeholder={`${translate('CNPJ da Empresa / Entidade')}`}
                    /> : 
                    <Input name="company_document_number" 
                placeholder={`${translate('Nº de identificação da Empresa / Entidade') }`}/>}


<CheckboxInput
              style={{ display: 'flex'  }}
              name="term"
              unique={true}
              preText={(
                <>
                  {' '}
                  Estou ciente de que estou fornecendo ao {authTitle} e seus parceiros meus contatos gerais, os quais são dados pessoais protegidos pela Lei Geral de Proteção de Dados Brasileira, para gestão de relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.
{' '}
                 
                </>
              )}
              options={[
                {
                  id: `Sim`,
                  label: translate('Sim'),
                  value: `Sim`,
                },
                {
                  id: `Não`,
                  label: translate('Não'),
                  value: `Não`,
                },
              ]}
            />





<p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
          
{' '}
                <strong>
                  <Link to="/termos"
                  >
                  {translate('Ao me cadastrar, afirmo que li e aceito a ')}  {translate('POLÍTICA DE PRIVACIDADE')} {translate(' desta plataforma')}
{' '}
                  </Link>
{' '}
                </strong>
              
              </p>

              <div style={{display:'flex', alignItems: 'center', justifyContent:'center', width: '100%'}}>
              <ReCAPTCHA key={`recapt-${captKey}`} ref={recaptchaRef} sitekey={recaptchaKey} />
              </div>
              {errorMessage && (
              <Error>
                {errorMessage.map((item, index) => (
                  <div key={`error${index}`}>
                    <MdError />
                    {item}
                  </div>
                ))}
              </Error>
            )}
<aside style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
<Button type="submit">
                      {productType === 'free' ? translate('Finalizar') : translate('Continuar')}

                      <FiArrowRight />
                    </Button>
</aside>
                  
                    </div>
                  </Form>
                )}
              </AnimatedDiv>
            )}
          </aside>
        </div>
      </LoginContent>
    </AnimatedDiv>
  );
};

export default LoginContainer;

/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, Dispatch, useState, useCallback } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { useToast } from '../../../../hooks/Toast';
import { AnimatedDiv } from '../../styles';
import { ProductsContent } from './styles';
import { ActiveButton, BuyContext } from '../../index';
import api from '../../../../services/api';
import Loader from '../../../../components/Loader';
import format from '../../../../utils/format';
import { useLanguage } from '../../../../hooks/Language';
import { Link } from 'react-router-dom';

interface ProductProps {
  id: string;
  title: string;
  description: string;
  price: number;
  formattedPrice?: string;
  max_selectable_courses: number;
  type: 'free' | 'paid';
  benefits: string[];
  quantity: number;
  category_id?: number;
  available_quantities: number;
  limited_quantities: number;
  before_price: number;
  formattedPriceFull?: string;
  hash_link : string;
}

interface CoursesProps {
  id: string;
  title: string;
}

interface ComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
  productId?: string;
}

const ProductsContainer: React.FC<ComponentProps> = ({
  step,
  setStep,
  buyContext,
  setBuyContext,
  productId = '',
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
const {translate } = useLanguage();
  const [productList, setProductList] = useState<ProductProps[]>([]);

  useEffect(() => {
    const loadInfo = async (): Promise<void> => {
      const responseProducts =
        productId
          ? await api.get(`/product/${productId}`)
          : await api.get('/products-list');

      if (!responseProducts) {
        addToast({
          title: 'Ops, Falha de conexão.',
          description: `Por favor, recarregue a tela e tente novamente`,
          type: 'error',
        });
      }

      if(productId && responseProducts.data){
        responseProducts.data.rows = [responseProducts.data];
      }
      else if(productId && !responseProducts.data){
        addToast({
          title: 'Ops, Falha de conexão.',
          description: `Por favor, recarregue a tela e tente novamente`,
          type: 'error',
        });
        return;
      }

      const formatProducts: Array<ProductProps> = responseProducts.data.rows.map(
        (productValue: ProductProps) => {
          const prices = {
            ...productValue,
            formattedPrice: format(productValue.price),
          };

          if (productValue.before_price) {
            prices.formattedPriceFull = productValue.before_price
              ? format(productValue.before_price)
              : '0';
          }

          return prices;
        },
      );

      formatProducts.sort((a,b) => {
        return a.price < b.price ? 1 : a.price > b.price ? -1 : 1;
      })



      setProductList(formatProducts);

      setLoading(false);
    };

    loadInfo();
  }, [addToast]);

  const handleProduct = useCallback(
    productInfo => {
      productInfo.quantity = 1;

      const changeBuyContext: BuyContext = {
        ...buyContext,
        products: [productInfo],
        product : productInfo,
      };

      const maxCourses = productInfo.max_selectable_courses;
      const amount = changeBuyContext.products
        ? changeBuyContext.products.reduce(
            (prev, productMap) => prev + productMap.price * productMap.quantity,
            0,
          )
        : 0;

      setBuyContext({
        ...changeBuyContext,
        amount,
        maxCourses,
        productType: productInfo.type,
      });
      setStep([1, maxCourses > 0 ? 2 : 3]);
    },
    [buyContext, setBuyContext, setStep],
  );

  return (
    <>
      {loading ? (
        <Loader message="Carregando" />
      ) : (
        <AnimatedDiv visible={step[1] === 1} >
         
          <ProductsContent className='shadow' >
          <ActiveButton step={1}/>
             <h2>{translate('Selecione sua inscrição')}</h2>
          
            <aside style={{ display:'flex', padding: '10px', flexDirection: 'column', justifyContent: 'flex-start', alignItems:'center'}} >
              {productList.map(productInfo => (
                <div  onClick={() => productInfo &&
                  productInfo.limited_quantities === 2 &&
                  productInfo.available_quantities <= 0 ? false : handleProduct(productInfo) } style={{display:'flex', cursor:'pointer', background:'#fff', minHeight:'100px', margin: '5px',padding: '10px', alignItems:'center', justifyContent:'space-between', width:'100%', maxWidth: '400px'}}>
                 <aside style={{display:'flex', width: '100%', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                  <h2 style={{width:'100%', justifyContent: productInfo.hash_link === 'apoio' || productInfo.hash_link === 'apoio-pos' ? 'center' : 'flex-start', display:'flex', padding: '10px',  textAlign:'left'}}>{translate(productInfo.title)}
                  
                  </h2>
                 
                  <p style={{fontSize: '10px', padding: '10px', color: '#555', textAlign: 'left', width: '100%'}}>{productInfo.description}</p>
                  <aside style={{display:'flex', width: '100%', flexDirection:'column', justifyContent:'flex-end', alignItems:'flex-end'}}>
                  {(productInfo.hash_link === 'apoio' || productInfo.hash_link === 'apoio-pos') ? <></> : productInfo &&
                  productInfo.limited_quantities === 2 &&
                  productInfo.available_quantities <= 0 ? (
                    <button
                      className="defaultButtonMini redButton" style={{marginBottom:'0px'}}
                      type="button"
                    >
                      {translate('Vagas encerradas')}
                    </button>
                  ) : (
                    <button
                      className="defaultButtonMini" style={{marginBottom:'0px'}}
                     
                      type="button"
                    >
                       <p style={{margin: '0px 10px '}}>R$</p>
                      <p>{productInfo.formattedPrice}</p>
                    </button>
                  )}
                  </aside>
                  </aside>
                 
                </div>
              ))}


 

            </aside>
          { /*  <Link style={{ marginBottom:'30px', display:'flex', flexDirection:'row', alignItems: 'center', justifyContent:'center' }} to={`/usar-codigo`}><button className='defaultButton'>TENHO UM CÓDIGO DE INSCRIÇÃO </button></Link>
          
            <aside>
 
            {(productId === 'apoio' || productId === 'apoio-pos') ? 
            <>
            <h2> Lote 1: até 20 de JUNHO </h2>
            <table style={{width: '100%', maxWidth:'600px'}}>
              <tbody>
     
              <tr><td>Pós graduação </td><td>R$ 3.680,00</td></tr>
                <tr><td>Profissionais </td><td>R$ 6.080,00</td></tr>
              </tbody>
            </table>
 
            <h2> Lote 2: após 20 de junho </h2>
            <table style={{width: '100%', maxWidth:'600px'}}>
              <tbody>
      
                <tr><td>Pós graduação </td><td>R$ 4.320,00</td></tr>
                <tr><td>Profissionais </td><td>R$ 7.160,00</td></tr>
              </tbody>
            </table>
            
            </>
            : <>
     
           <h2> Lote 2: até 20 de junho </h2>
           <table style={{width: '100%', maxWidth:'600px'}}>
             <tbody>
             <tr><td>Graduação </td><td>R$ 490,00</td></tr>
               <tr><td>Pós graduação </td><td>R$ 920,00</td></tr>
               <tr><td>Profissionais </td><td>R$ 1.520,00</td></tr>
             </tbody>
           </table>

           <h2> Lote 3: após 20 de junho </h2>
           <table style={{width: '100%', maxWidth:'600px'}}>
             <tbody>
             <tr><td>Graduação </td><td>R$ 600,00</td></tr>
               <tr><td>Pós graduação </td><td>R$ 1.080,00</td></tr>
               <tr><td>Profissionais </td><td>R$ 1.790,00</td></tr>
             </tbody>
           </table>




                  </>}  </aside> */}
          </ProductsContent>
        </AnimatedDiv>
      )}
    </>
  );
};

export default ProductsContainer;

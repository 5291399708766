import React from 'react';

import { AuthProvider } from './Auth';
import { ToastProvider } from './Toast';

import { ModalProvider } from './Modal';
import { SearchProvider } from './Search';
import { ScrollProvider } from './Scroll';
import { SocketProvider } from './Socket';
import { PagesProvider } from './Pages';
import { InteractionProvider } from './Interactions';
import { LanguageProvider } from './Language';
import { PrinterProvider } from './PrinterHook';
const AppProvider: React.FC = ({ children }) => {
  return (
    <ToastProvider>
      <AuthProvider>
        <LanguageProvider>
        <PrinterProvider>
          <PagesProvider>
          <SocketProvider>
        <InteractionProvider>
     
          <ModalProvider>
            <SearchProvider>
              <ScrollProvider>{children}</ScrollProvider>
            </SearchProvider>
          </ModalProvider>
        
        </InteractionProvider>
      </SocketProvider>
      </PagesProvider>
      </PrinterProvider>
      </LanguageProvider>
    </AuthProvider>
  </ToastProvider>
  );
};

export default AppProvider;

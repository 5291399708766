import React from 'react';
import { FaChalkboardTeacher, FaFileExcel } from 'react-icons/fa';
import CopyToClipboard from '../../../core/components/CopyToClipboard';
import { apiUrl, getToken, urlWebsite } from '../../../../../config';
import userEvent from '@testing-library/user-event';
import { useAuth } from '../../../../../hooks/Auth';

interface ICreateModule {
    lineData: Record<string, any>;
  }
  
  
  export default function ExportList({
    lineData,
  }: ICreateModule): JSX.Element {

    const {user} = useAuth();

  const moduleList  = ({data}): JSX.Element => { 

    return <> { user && user.profile === 1 ? <a href={`${apiUrl}/getParticipants/${data.id}?hash=${getToken()}&type=xlsx`} target="_BLANK"> <FaFileExcel color="#333" size={30}  title="Exportar Lista geral"/></a> : <></>}
    { user && user.profile === 1 ? <a href={`${apiUrl}/getParticipantsMinutes/${data.id}?hash=${getToken()}&type=xlsx`} target="_BLANK"> <FaFileExcel color="rgb(0,150,100)" size={30} title="Exportar últimos 5 minutos"/></a> : <></>}
    </>;
  }
     
  

  return moduleList({data: lineData});
}

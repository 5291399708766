import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FiMail, FiArrowLeft, FiLock, FiMessageCircle } from 'react-icons/fi';
import {
  Link,
  useHistory,
  RouteComponentProps,
  useParams,
  withRouter,
} from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { logo, recaptchaKey } from '../../config';
import { Container, Content, Background, AnimatedDiv } from './styles';
import Input from '../../components/Forms/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/Toast';
import api from '../../services/api';
import { ResponseMessage } from '../../components/ResponseMessage';
import { useAuth } from '../../hooks/Auth';

interface IRecoverFormData {
  password: string;
  captcha: string;
}

interface ICaptcha {
  getValue(): string;
}

interface IMyComponentProps {
  key: string;
}

const ResetPassword = (props: RouteComponentProps): JSX.Element => {
  const { key } = useParams<IMyComponentProps>();
  const {handleApiErrors} = useAuth();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState<JSX.Element[]>();

  useEffect(() => {
    const load = async (): Promise<void> => {
      try{
      const response = await api.get(`recover-password/${key}`).catch(err => {
        setMessage([
          <ResponseMessage
            type="error"
            title="Token inválido"
            description={
              <span>Verifique o link que foi enviado para o seu email</span>
            }
          />,
        ]);
      });
    }
    catch(err){
      handleApiErrors(err);
    }

      setLoading(false);
    };
    load();
  }, [key]);

  const history = useHistory();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);

  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const handleSubmit = useCallback(
    async (data: IRecoverFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string()
            .required('O campo Senha é obrigatório')
            .min(6, 'O Campo senha precisa ter ao menos 6 caractéres'),
          confirm_password: Yup.string()
            .oneOf(
              [Yup.ref('password')],
              'As senha e a Confirmação precisam ser iguais',
            )
            .required('A Confirmação de Senha é obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const captchaValue =
          getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
          false;

        /*   if (!captchaValue) {
          addToast({
            type: 'error',
            title: 'Pressione o Captcha',
          });
          return;
        } */

        const response = await api.put(`/recover-password/${key}`, {
          password: data.password,
          captcha: captchaValue,
        });

        setMessage([
          <ResponseMessage
            active
            type="success"
            title="Senha Alterada com Sucesso"
            description={
              <p>Clique no link abaixo para acessar a plataforma.</p>
            }
          />,
        ]);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao solicitar nova senha',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Background />

      <Content>
        {!loading && (
          <AnimatedDiv style={{background: 'rgba(255,255,255,0.7)', borderRadius: '10px'}}>
            {message ? (
              message.map(responseMessage => responseMessage)
            ) : (
              <>
                <img src={logo} alt="Plataforma Online" />
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <h1>Recuperação de Senha</h1>

                  <Input
                    icon={FiLock}
                    type="password"
                    name="password"
                    placeholder="Senha"
                  />
                  <Input
                    icon={FiLock}
                    type="password"
                    name="confirm_password"
                    placeholder="Confirmar Senha"
                  />
             <aside style={{display:'flex', justifyContent: 'center', width: '100%'}}>  <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />
              
              </aside>
              <aside style={{display:'flex', justifyContent: 'center', width: '100%'}}>
                  <Button type="submit">Recuperar Senha</Button>
                  </aside>
                </Form>
              </>
            )}

            <Link to="/login">
              <FiArrowLeft />
              Acessar
            </Link>
          </AnimatedDiv>
        )}
      </Content>
    </Container>
  );
};

export default withRouter(ResetPassword);

import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

const EventsAdmin: React.FC = () => {
  const endpoint = '/events';
  const title = 'Eventos';

  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID', show: false },
    contador: { ref: 'contador', column: 'contador', label: 'Contador' },
    hash: { ref: 'hash', column: 'hash', label: 'Hash' },
    title: { ref: 'title', column: 'title', label: 'Categoria' },
    start_at: { ref: 'start_at', column: 'start_at', label: 'Data de início' },
    date_text: { ref: 'date_text', column: 'date_text', label: 'Data em texto' },
    image: { ref: 'image', column: 'image', label: 'Foto', show: false },
    status: {
      ref: 'status',
      column: 'status_response',
      includeColumn: 'title',
      label: 'Status',
      filter: true,
    },
    selected: {
      ref: 'selected',
      column: 'selected_response',
      includeColumn: 'title',
      label: 'Evento Atual?',
      filter: true,
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    contador: { model: 'input', type: 'text', name: 'contador', label: 'Contador' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Categoria' },
    start_at: { model: 'datePickerHour', type: 'text', name: 'start_at', label: 'Data' },
    date_text: { model: 'input', type: 'text', name: 'date_text', label: 'Data em texto' },
    status: {
      alias: 'status',
      model: 'selectApi',
      name: 'status',
      label: 'status',
      endpoint,
    },
    selected: {
      alias: 'selected',
      model: 'selectApi',
      name: 'selected',
      label: 'Evento atual?',
      endpoint,
    },
  };

  const formSchemaUpdate = {


    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto',
    },
    ...formSchema
   

 
  };

  const formValidation: Record<string, ValidationSchema> = {
 
  };

  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default EventsAdmin;

import React from 'react';

import { Container, ContainerWhite } from './styles';

 
const Comissao: React.FC = () => {


  return (
    <>

 
      <Container className='fadeInAnimation' >
        <h2>
          COMISSÃO ORGANIZADORA
        </h2>

<p>
    <ol>
<li>Anderson Trautman Cardoso</li>
<li>Barbara Selbach Oliveira</li>
<li>Carlos Augusto Peixoto Reis </li>
<li>Cristiane da Costa Nery </li>
<li>Douglas Marques Ferreira </li>
<li>Éderson Garin Porto </li>
<li>Fábio Raimundi </li>
<li>Fernando Mombelli </li>
<li>José Umberto Braccini Bastos </li>
<li>Jussandra Hickmann Andraschko</li>
<li>Maria Dionne de Araujo Felipe </li>
<li>Mariana Porto Koch </li>
<li>Melissa Guimarães Castello </li>
<li>Ricardo Bernardes Machado</li>
<li>Rosane Danilevicz </li>
<li>Simone Anacleto </li>

    </ol>



</p>
      </Container>
    </>
  );
};
export default Comissao;

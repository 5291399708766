import styled , {css}from 'styled-components';


export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  padding-top: 0px;
  background: none;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  min-height: auto;
  z-index: -1;
`;

export const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px 10px;

  color: #fff;
  min-height: 100px;
  z-index: 200;
  position: relative;
  bottom: 0px;
  left: 0px;
  background: none;

  @media (max-width: 750px) {

    justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  >div{
    max-width: 500px;
  }


      }

  > p {
    position: relative;
    z-index: 200;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  >div, >h2, > p{
    max-width: 1600px;

    text-align: center;
  }

  >h2{
    margin-top: 30px;
    margin-bottom: 10px;
  }
`;

export const SponsorGrid = styled.div<IBackground>`

width: 100%;
max-width: 1600px;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
justify-content: center;
margin: auto;


>div{
  width: 100%;
max-width: 1600px;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
justify-content: center;
margin: auto; 
}

@media (max-width: 1600px) {
  >div{
    max-width: 500px;
  }


      }

`;

interface IBackground {
  background ?: string;
}

interface IBackground {
  width_computer ?: string;
  width_mobile ?: string;
}

export const Apoio = styled.img<IBackground>`
 
 width: ${props => props.width_computer ? props.width_computer : '100'}px;

 @media (max-width: 750px) {
  width: ${props => props.width_mobile ? props.width_mobile : '100'}px;
    }

`;

export const Sponsor = styled.div<IBackground>`

background: url(${props => props.background}) no-repeat center;
background-size: cover;

width: 100px;
height: 66px;
margin:15px;

@media (max-width: 1200px) {
  width: 60px;
height: 40px;
      }
`;

interface ISize {
  size ?: string;
}

export const ApoioGrid = styled.div<ISize>`

width: 100%;
max-width: 1600px;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
justify-content: center;

div{
  margin: 10px 15px;
}

>a, >div {
  >div{


width: 120px;
height: 80px;
margin: 10px 15px;

@media (max-width: 750px) {
width: 90px;
height: 60px;
    }

${props => props.size === 'GG' && css`
width: 400px;
height: 150px;
margin:15px;

@media (max-width: 750px) {
width: 300px;
height: 100px;
    }

`}

${props => props.size === 'G' && css`
width: 300px;
height: 150px;
margin:15px;

@media (max-width: 750px) {
  width: 140px;
height: 110px;
      }

`}
${props => props.size === 'M' && css`
width: 200px;
height: 150px;
margin:15px;

@media (max-width: 750px) {
  width: 140px;
height: 110px;
    }

`}

${props => props.size === 'P' && css`
width: 120px;
height: 120px;
margin:15px;

@media (max-width: 750px) {
width: 70px;
height: 70px;
    }

`}

}
}


`;

interface IBackground {
  background ?: string;
}


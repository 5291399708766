import React from 'react';
import { Switch } from 'react-router-dom';
import { home } from '../config';
import Route from './routes';
import SignIn from '../pages/SignIn';
import Sobre from '../pages/Sobre';
import SignInAdmin from '../pages/SignInAdmin';
import SignUp from '../pages/SignUp';
import RecoverPassword from '../pages/RecoverPassword';
import ResetPassword from '../pages/ResetPassword';
import Products from '../pages/Products';

import VideosDashboard from '../pages/VideosDashboard';
import Dashboard from '../pages/DashboardPages';

import Home from '../pages/Home';
import Home2 from '../pages/Home2';
import AreaExposicao from '../pages/AreaExposicao';
import Expositor from '../pages/Expositor';
import Galeria from '../pages/Galeria';
import ExpositorChat from '../pages/ExpositorChat';
import SigninExpositor from '../pages/SigninExpositor';
import Program from '../pages/Program';

import ProgramComplete from '../pages/ProgramComplete';

import Poster from '../pages/Poster';

import Rank from '../pages/Rank';
import RankRegulamento from '../pages/RankRegulamento';

import Validate from '../pages/Validate';
import Zoom from '../components/ZoomStarter';
import SpeakerLink from '../pages/SpeakerLink';
import ModeratorLink from '../pages/ModeratorLink';
import Decisao from '../pages/Decisao';
import Speakers from '../pages/Speakers';
import SlidesImagens from '../pages/Slide';
import GreenRoom from '../pages/GreenRoom';
import Certificados from '../pages/Certificados';
import Termos from '../pages/Termos';
import Perguntas from '../pages/Perguntas';
import SpeakerTermComponent from '../pages/DashboardPages/core/features/SpeakerTermPage';
import Sponsors from '../pages/Sponsors';
import PaymentConfirmation from '../pages/Products/modules/PaymentConfirmation';
import PaymentMethod from '../pages/Products/modules/PaymentMethod';
import SignupCortesias from '../pages/SignupCortesias';
import Comissao from '../pages/Sobre';
import Presidente from '../pages/SobrePresidente';
import ShowMyCodes from '../pages/ShowMyCodes';
import SignupCortesiasAddCode from '../pages/SignupAddCode';
import SignUp2Convidados from '../pages/SignUp2Convidados';

import CheckinQR from '../pages/DashboardPages/core/features/CheckinQR';
import SignInExpositor from '../pages/SigninExpositor';
import AreaExpositor from '../pages/AreaExpositor';

const ValidPaths = {
  SignIn,
  SignInAdmin,
  SignUp,
  RecoverPassword,
  ResetPassword,
  Products,
  VideosDashboard,
  Home2,
  Home,
  Sobre,
};

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route
    
        path="/"
        exact
        component={ValidPaths[home] ? ValidPaths[home] : Home}
      />

<Route  path="/2" component={Home2}/>


<Route
        path="/checkin"
  
        isPrivate
 
        component={CheckinQR}
      />  

<Route
        path="/area-expositor"
  
        isPrivate
 
        component={AreaExpositor}
      />  
      <Route
        path="/checkin"
  
        isPrivate
 
        component={CheckinQR}
      />  

      <Route  path="/login-expositor" component={SignInExpositor} />


      <Route  path="/accept_terms/:hash" component={SpeakerTermComponent} />
      <Route path="/usar-codigo/:code" component={SignupCortesias} />
      <Route path="/usar-codigo" component={SignupCortesias} />
      <Route path="/empenho" component={SignUp} />
      <Route  path="/tematica" component={Program} />
      <Route isPrivate path="/meus-codigos" component={ShowMyCodes} />
      <Route path="/aplicar-codigo/:code" component={SignupCortesiasAddCode} />
      <Route path="/aplicar-codigo" component={SignupCortesiasAddCode} />
      <Route path="/checkout/:product_hash" component={PaymentMethod} />
      <Route path="/payment/success/:payment_hash" component={PaymentConfirmation} />
      <Route path="/cortesias" component={SignupCortesias} />
      <Route  path="/inscricao/:productId" component={Products} />
      {/* <Route  path="/inscricao" component={SignUp} /> */}
       <Route  path="/convite" component={SignUp2Convidados} />
       <Route  path="/comissao" component={Comissao} />
       <Route  path="/presidente" component={Presidente} />
       <Route  path="/perguntas" component={Perguntas} />
      <Route  path="/palestrantes" component={Speakers} />
      <Route  path="/login" component={SignIn} />
      <Route  path="/termos" component={Termos} />
      <Route template={'page'} isPrivate isSecret  path="/green-room/:id" component={GreenRoom} />
      <Route  path="/certificados" component={Certificados} />
      <Route  path="/patrocinadores" component={Sponsors} />
 
      <Route isPrivate path="/poster" component={Poster} />
      <Route isPrivate path="/voce-no-congresso-regulamento" component={Rank} />
      <Route isPrivate path="/voce-no-congresso" component={RankRegulamento} />
      <Route template="client" path="/validate/:hash" component={Validate} />
      <Route template="client" path="/events/access/:hash" component={SpeakerLink} />
       <Route path="/zoom" component={Zoom} />
       <Route  path="/recuperar_senha" component={RecoverPassword} />
      <Route  path="/reset_password/:key" component={ResetPassword} />
     <Route template="client" path="/moderator/access/:hash" component={ModeratorLink} />
   
      <Route template="exposicao" path="/exposicao" component={AreaExposicao} />
      <Route  path="/programacao" component={ProgramComplete} />
      <Route template="client" path="/admin" component={SignInAdmin} />
      <Route isPrivate path="/proximo_congresso" component={Decisao} />
      <Route isPrivate path="/login_expositor" component={SigninExpositor} />
      <Route   path="/apresentacao" component={Presidente} />
      <Route
        
        path="/expositores/:link_name"

        component={ExpositorChat}
      />
      <Route
        isPrivate
        path="/galeria/:link_name"
        template="page"
        component={Galeria}
      />
      <Route
        isPrivate
        path="/expositores_chat/:link_name"
        template="page"
        component={ExpositorChat}
      />
            <Route
        isPrivate
        path="/dashboard/category/:group_link"
        component={VideosDashboard}
      />
      <Route
        isPrivate
        path="/dashboard/:videoIdItem"
        component={VideosDashboard}
      />
      <Route path="/dashboard" isPrivate component={VideosDashboard} />

      <Route
        path="/manager/:tab"
        template="dashboard"
        isPrivate
        isSecret
        component={Dashboard}
      />
      <Route
        path="/manager"
        template="dashboard"
        isPrivate
        exact
        isSecret
        component={Dashboard}
      />
   
 
    </Switch>
  );
};

export default Routes;

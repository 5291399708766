import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { uuid } from 'uuidv4';
import QRCode from 'qrcode.react';
import { FaBarcode, FaCheck, FaQrcode } from 'react-icons/fa';
import { AxiosInstance } from 'axios';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../FormComponent';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import Button from '../../Forms/Button';

import { usePrinter } from '../../../../../../hooks/PrinterHook';
import QRCodeComponent from '../../QRCodeComponent';

interface ICreateModule {
  lineData: Record<string, any>;
  title: string;
  formSchema: Record<string, any>;
  endpoint: string;
  validationSchema: Record<string, IYup>;
  apiRef?: AxiosInstance;
}

export default function Credenciamento({
  apiRef = api,
  lineData,
  title,
  formSchema,
  validationSchema,
  endpoint,
}: ICreateModule): JSX.Element {

  const { addToast } = useToast();
  const {reloadSearchAll} = useSearch();



  const credenciar = async (line) => {
    
    try {
      const response = await api.post(`/credenciar`, {hash : line.user_hash});
    
      addToast({title:"Credenciado com sucesso", type:"success" });
      reloadSearchAll();
    } catch (error) {
      return addToast({ title : error?.response?.data?.message || 'Erro ao credenciar', description:"Cheque se o usuário não foi credenciado ao emitir o crachá", type: 'error'})
    }

  };

  const moduleQRCode = (line): JSX.Element => (

    <button className="lineIcon"  title="Credenciar usuário" type="button" onClick={() => credenciar(line)}>
    <FaCheck />
  </button>
 
  );

  return moduleQRCode(lineData);
}

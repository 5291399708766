import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';
import { useSocket } from '../../hooks/Socket';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button/styles';

interface SignInData {
  email: string;
  password: string;
}

interface IGame {
  name : string;
  points : number;
}

const SignIn: React.FC = () => {
  
  const {socket} = useSocket();
  const [gameList, setGameList] = useState<Array<IGame>>([]);

useEffect(() => {

socket.emit('requestGameList');

socket.on('receiveGameList', (data) => {
  setGameList(data)
})

},[])

  return (
    <>
   <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}> <Link to="/voce-no-congresso"><Button>RANKING</Button></Link>
    <Link to="/voce-no-congresso-regulamento"><Button>PREMIAÇÃO E REGULAMENTO</Button></Link>
    </div>
    <Container style={{padding: '20px', borderRadius: '20px',background: 'rgba(107, 2, 147,0.7)', maxWidth: '1000px', margin: '10px auto'}}>

<h1 style={{width:'100%', margin: '40px 10px', textAlign:'center'}}>Premiação*</h1>
<table className="table">
  <thead><tr><td>Pontuação</td><td style={{minWidth:'100px'}}>Ação</td></tr></thead>
        <tbody>
        <tr><td style={{minWidth:'100px'}}>1º Lugar</td><td>01 inscrição + 3 diárias de hospedagem, para participar do Congresso presencial 2022 (Hotel e datas a definir pela comissão organizadora)</td></tr>
        <tr><td style={{minWidth:'100px'}}>2º, 3º e 4º colocados</td><td>01 Inscrição no Congresso Brasileiro de Fonoaudiologia – 2022 – Presencial</td></tr>
        <tr><td style={{minWidth:'100px'}}>5º, 6º, 7º, 8º e 9º colocados</td><td>01 kit MindVox Premium com Squezze Térmica personalizada com a marca do evento</td></tr>
       
        </tbody>
      </table><br/><br/>
<p>
*Os prêmios serão nominais, intransferíveis, não sendo possível convertê-los em dinheiro ou qualquer outra forma.
*O Kit MindVox será enviado para o endereço do vencedor, pela empresa.</p><br/>
<h1 style={{width:'100%', margin: '40px 10px', textAlign:'center'}}>Regulamento</h1>
<br/>
<p>Para participar, é preciso estar inscrito no XXIX Congresso Brasileiro de Fonoaudiologia e acessar a plataforma virtual.</p>
<br/><br/>
<table className="table">
  <thead><tr><td>Pontuação</td><td style={{minWidth:'100px'}}>Ação</td></tr></thead>
        <tbody>
        <tr><td style={{minWidth:'100px'}}>2 pontos</td><td>Clicar no banner para acessar o estande do expositor</td></tr>
        <tr><td style={{minWidth:'100px'}}>2 pontos</td><td>Clicar em materiais dos estandes</td></tr>
        <tr><td style={{minWidth:'100px'}}>2 pontos</td><td>Assistir Talk Show Mitos e Verdades na Disfagia: o que a Ciência e a Experiência nos dizem dia 14/10 das 10:50 às 11:20</td></tr>
        <tr><td style={{minWidth:'100px'}}>2 pontos</td><td>Assistir palestra Avanços na Análise Acústica: da pesquisa à Clínica Vocal dia 14/10 das 13:00 às 13:40</td></tr>
        <tr><td style={{minWidth:'100px'}}>1 ponto</td><td>Comentário em pôster (por pôster comentado)</td></tr>
        <tr><td style={{minWidth:'100px'}}>1 ponto</td><td>Assistir minicursos</td></tr>
        <tr><td style={{minWidth:'100px'}}>1 ponto</td><td>Assistir palestras da grade</td></tr>
        <tr><td style={{minWidth:'100px'}}>1 ponto</td><td>Curtir palestra</td></tr>
        <tr><td style={{minWidth:'100px'}}>2 pontos</td><td>Assistir sala profissional 4.0</td></tr>
        <tr><td style={{minWidth:'100px'}}>2 pontos</td><td>Fazer perfil na área de networking</td></tr>
        </tbody>
      </table><br/><br/>
<p>Período para pontuação: de 13/10 até 25/10<br/><br/>
A pontuação será computada automaticamente pela plataforma virtual e a apuração final será no dia 26/10.</p>
    </Container>
    </>
  );
};
export default SignIn;

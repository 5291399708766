import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import contactModule from './Modules/ContactModule';
import { useAuth } from '../../../../hooks/Auth';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const Leads: React.FC = () => {
  const endpoint = '/lead-capture-page';
  const title = 'Leads';

  const {user } = useAuth();
  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'Email' },
    phone: { ref: 'phone', column: 'phone', label: 'Telefone' },
    country: { ref: 'country', column: 'country', label: 'País' },
    term: { ref: 'term', column: 'term', label: 'Termo' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },

    email: { model: 'input', type: 'text', name: 'email', label: 'Email' },
    phone: { model: 'input', type: 'text', name: 'phone', label: 'Telefone' },
     
  };

  const formValidation: Record<string, ValidationSchema> = {

  };

  
  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules:user.profile === 1 ? [ 
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ] : [],
    bodyModules:user.profile === 1 ? [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema : formSchema,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
        (lineData: Record<string, any>) =>
        contactModule({
          lineData
          
        }),
        
    ] : user.profile === 4 ? [    (lineData: Record<string, any>) =>
      lineModules.delete({
        lineData,
        title,
        formSchema,
        validationSchema: formValidation,
        endpoint,
      }),
      (lineData: Record<string, any>) =>
      contactModule({
        lineData
        
      }),] : [],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Leads;

import React from 'react';
import { FaIntercom, FaRadiationAlt } from 'react-icons/fa';
import CopyToClipboard from '../../../core/components/CopyToClipboard';
import { urlWebsite } from '../../../../../config';
import { useSocket } from '../../../../../hooks/Socket';

interface ICreateModule {
    lineData: Record<string, any>;
  }
  
  export default function SocketLinkModule({
    lineData,
  }: ICreateModule): JSX.Element {

    const {socket } = useSocket();

    const sendSocket = (line) => {

        socket.emit('updateLessonsToAll',line);
    }


  const moduleCreate = (): JSX.Element => { 

    return <FaIntercom size={30} onClick={() => sendSocket(lineData)}/>;
  }
     
  

  return moduleCreate();
}

import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons';
import { Feira, Expositor, Container } from './styles';
import { ContactIcons } from '../../components/Menu';
import { urlWebsite } from '../../config';
import api from '../../services/api';
import { useInteraction } from '../../hooks/Interactions';
import { useAuth } from '../../hooks/Auth';

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface IExpositor {
  id: number;
  background: string;
  capa: string;
  title: string;
  link_name: string;
  social : Record<string,any>;
}

const Expositores: React.FC = () => {
  const [expositors, setExpositors] = useState<Array<IExpositor>>([]);
  const { handleApiErrors } = useAuth();
const {addGame} = useInteraction();
  useEffect(() => {
    const load = async () => {

      const rows : Array<IExpositor> = [];

      try{
      const expositores = await api.get('/expositors-list/1');


      expositores.data.rows.map( e => {

        if(!e.social){
        e.social = e.social2 ? JSON.parse(e.social2) : [];
        }
        e.video_gallery = e.video_gallery2 ? JSON.parse(e.video_gallery2) : [];
        e.image_gallery = e.image_gallery2 ? JSON.parse(e.image_gallery2) : [];
        e.expositor_files = e.expositor_files2 ? JSON.parse(e.expositor_files2) : [];
        e.banner_gallery = e.banner_gallery2 ? JSON.parse(e.banner_gallery2) : [];


        rows.push(e);
      })
    }
    catch(err){
      handleApiErrors(err);
    }

      setExpositors(rows);
    };

    load();
  }, []);

  return (
    <Container>
      <div
        id="expositores"
        className="p600 "
        style={{ maxWidth: '400px', marginTop: '30px', textAlign: 'center' }}
      >
        <h2 style={{color: '#fff'}} className="">PATROCÍNIO DIAMANTE</h2>
      </div>

      <Feira>
        <div>
          {expositors.map(expo => {
            return (
              expo?.social?.link_image ?  <a style={{margin: '10px'}} href={expo?.social?.link_image} target="_BLANK"  onClick={ (e) => { e.preventDefault(); addGame({reference: 'acesso_expositor', id :  expo.id, points: 2}); window.location.href = expo?.social?.link_image;}}><img src={`${urlWebsite}/${expo.capa}`} style={{width: '100%', maxWidth:'300px', borderRadius:'10px'}}/></a> :
              <Expositor
              onClick={ (e) => { e.preventDefault(); addGame({reference: 'acesso_expositor', id :  expo.id, points: 2}); window.location.href = `/expositores/${expo.link_name}`;}}
                to={`/expositores/${expo.link_name}`}
                background={`${urlWebsite}/${expo.capa}`}
              >
                <div />
              </Expositor>
            );
          })}
        </div>
      </Feira>
    </Container>
  );
};
export default Expositores;

import { createGlobalStyle, keyframes } from 'styled-components';
import { defaultTheme } from '../config';


const glow = keyframes`
0% { 
  background-size: cover;
}
100%{
  background-size: cover 120%;
}

`

const fadeIn = keyframes`
0% { 
  transform: translateY(-30px);
  opacity: 0;
}
100%{
  transform: translateY(0px);
  opacity: 1;
}

`

const showIn = keyframes`
0% { 
 
  opacity: 0;
}
100%{
 
  opacity: 1;
}

`

export default createGlobalStyle`

@font-face {
    font-family: Philosopher;
    src: url("/assets/fonts/Philosopher-Regular.ttf") format("trueType");
}

@font-face {
    font-family: PhilosopherItalic;
    src: url("/assets/fonts/Philosopher-Italic.ttf") format("trueType");
}

@font-face {
    font-family: PhilosopherBold;
    src: url("/assets/fonts/Philosopher-Bold.ttf") format("truetype");
}

@font-face {
    font-family: PhilosopherBoldItalic;
    src: url("/assets/fonts/Philosopher-BoldItalic.ttf") format("truetype");
}


.fadeInAnimation {
  animation: ${fadeIn} 1s forwards;
}

.showAnimation {
  animation: ${showIn} 0.5s forwards;
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0
}

html{
  width: 100%;
  min-height: 100vh !important;
  overflow-x: hidden;


}

body {
 //background: url('/assets/Fundo.jpg') no-repeat center;
  background-position:  top;

background-attachment: fixed !important;
background-size: cover;
background: url('/assets/Fundo2.jpeg') no-repeat center;

//background: #0d0d0d;

background-position: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0 !important;
  min-height: 100vh !important;
  min-height: 100% !important;
  
  @media (max-width: 750px) {
    background-position: center;
  }

/*
  transition: background 15s;

  */

  color: #fff;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0 !important;
  min-height: 100vh !important;
  min-height: 100% !important;
  position: relative;



}

#myVideo{
    position: absolute;
    top: 0%;
    left: 0px;
    width: 100%;
     height: 100vh;
    overflow: hidden ;
 
  
    z-index: -1;
  
    

    display: flex ;
    justify-content: center;
    align-items: center;

    >video{
      opacity: 0.2 ;
      min-height: 113vh;
      margin-top: -7vh;
    
     
    }
  }

.ilustracao-back{
  position: fixed;
  bottom: -10px;
  right: 0px;
  width: 50%;
  z-index: -1;
  max-width: 400px;
  >img{
    width: 100%;
  }
}

#root{
  min-height: 100vh;
 
background: linear-gradient(180deg, rgba(110,56,142,0.8) 58%,   rgba(48,83,155,0.8) 95%);
 
}

.wrapperbody {


}

body, input, button{
  font-family: Helvetica,  sans-serif;
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6, strong{
font-weight: bold;
font-family:  'PhilosopherBold',Helvetica,  sans-serif;
color: #333;
}

h2{
color: #333;

      font-size: 16px;
}

p, span,a,button{
  font-family:'Philosopher',Helvetica,  sans-serif;
}
a{
  color: #333;
}
button{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  >svg {
    margin: 0 5px;
  }
}

.inscrevasediv{
  @media (max-width: 750px) {
         
        margin: 20px auto;
        }
}

.buttonInscrevase{
  background:  #fff ;
  color:#593484 ;
  padding: 5px 30px  ;
  border: 2px solid #fff;
  border-radius: 25px;
  transition: 0.5s all;
    font-size: 24px;
    

    @media (max-width: 750px) {
        font-size: 20px;
     
        }

  &:hover {
    background:#593484;
    color: #fff  ;
  }
}
.bold{
  font-family:'PhilosopherBold',Helvetica,  sans-serif;
}


.bolditalic {
  font-family:'PhilosopherBoldItalic',Helvetica,  sans-serif;
}

.italic {
  font-family:'PhilosopherItalic',Helvetica,  sans-serif;
}

.shadow{
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 5px;
}
}

.wave {

  width: 200%;
  margin-left: -20%;
  z-index:0;

  @media (max-width: 1250px) {
    width: 400%;
    margin-left: -50%;
  }

  @media (max-width: 750px) {
    width: 800%;
    margin-left: -50%;
  }



}



.computerOnly{
display: flex !important;
@media (max-width: 1200px) {
    display: none !important;
  }
}

.mobileOnly{
display: none !important;
@media (max-width: 1200px) {
    display: flex !important;
  }
}

button.defaultButton {
  background: #593484 ;
  color: #fff;
  padding: 5px 30px  ;
  border: 0px;
  border-radius: 25px;
  transition: 0.5s all;
    font-size: 24px;
    

    @media (max-width: 750px) {
        font-size: 20px;
     
        }

  &:hover {
    background: #fff;
    color: #593484 ;
  }
    }


button.defaultButtonRed {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      background: red !important;
      color: #fff  !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    .defaultButtonReverse {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      background: #f16621 !important;
      color: #fff  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    .defaultButtonReverseMini {
         margin-top: 0px;
      width: 100%;
      max-width: 200px;
      padding: 5px 10px;
      background: #333 !important;
      color: #fff  !important;
      color: #fff  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #fff  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    button.defaultButtonMini, button.buttonBlue {
         margin-top: 0px !important;
         padding: 5px !important;
      width:auto !important;
      min-width:auto !important;

      text-align: center !important;
      justify-content: center !important;
      background: #ccc !important;
      color: #003e15 !important;
      border:2px solid #333;

      display: flex  !important;
      justify-content: center !important;


      transition: background 0.5s;
      
      border-radius: 10px;

      &:hover {
        
      }
    }

    button.buttonBlue{
      background: rgb(0,100,150) !important;

      &:hover {
        background: rgb(0,120,180) !important;
      }
    }


    .closeButton{
      padding: 10px;
      border: 0;
      opacity: 0.8;
      background: #555;
      color: #fff;
      transition: 0.5s all;

      margin: 5px;
      &:hover{
        opacity: 1;
      }
    }


    a {
      text-decoration: none !important;
    }


    .lineIcon {
      margin:5px;
      background: #fff;
       border: 1px solid #333;
       border-radius: 50%;
       height: 35px;
       width: 35px;
       padding: 5px;
       
       >svg{
         width: 25px !important;
         font-size: 25px;

       }
       display: flex;
       align-items: center;
       justify-content: center;
    }

    .videoContainer{
      width: 100%;
      position: relative;
      height: auto;
   
    }

form {
    >div{
  
  background: #fff !important;
  color: #333  !important;
 
 

  span{
    color: #333  !important;
  }

  input {
    color: #333  !important;

    &::placeholder {
    color: #333  !important;
  }
  }
}
}

.buttonBox {
  background: rgba(100,0,200,0.4) !important;
  color: #fff !important;
  padding: 10px;
  height: auto !important;
  width: 230px !important;
  font-size: 12px !important;
  margin: 0px auto;
}
`;

import styled from 'styled-components';
import { background } from '../../../config';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  padding-top: 0px;
  background: none;
  background-size: cover;
  background-attachment: fixed;
  min-height: auto;
  position: relative;
  z-index: 101;


`;

export const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  width: 100%;
  min-height: 100vh;
  z-index: -1;
`;

export const Footer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 30px 0px;
  background: rgba(235, 235, 235, 1);
  color: rgb(0, 100, 150);
  min-height: 100px;
  z-index: 200;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0px;
  left: 0px;

  >.p50 {
    max-width: 50%;
    min-width: 50%;


  @media (max-width: 1200px) {
    max-width: 100%;
    min-width: 100%;
}
  }

  > p {
    position: relative;
    z-index: 200;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  >.sponsors {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    
  }
`;

interface IBackground {
  background ?: string;
}
export const Sponsor = styled.div<IBackground>`

background: url(${props => props.background}) no-repeat center;
background-size: cover;

width: 150px;
height: 150px;
margin: 5px;

@media (max-width: 1200px) {
  width: 130px;
height: 130px;
      }
`;
import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../config';
import { countries } from './countries';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../hooks/Language';
interface IRecoverFormData {
  [key : string] : any;
}


interface IRead {
  [key: string]: any;
}

interface IOption{
  value: string;
  label: string;
}


interface ICaptcha {
  getValue(): string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
const siglas = readSigla();
  const {addToast} = useToast();
  const {translate,language} = useLanguage();

  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');
  const [otherCompany, setOtherCompany] = useState('');

  const [cities,setCities] = useState<Array<IOption>>([]);
  const [stateValue,setStateValue] = useState('');
  const callCityApi = async ( value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if(response){

      const values : Array<IOption> = [];

      response.data.map( city => {
        values.push( { value: city.nome, label : city.nome});
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  },[stateValue])



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
       
        email: Yup.string()
          .required('Email obrigatório')
          .email('Preencha um email válido'),
         
        phone: Yup.string()
          .required('Telefone com DDD é obrigatório'),
       
      
 
        country: Yup.string().required('País é obrigatório'),
        state: Yup.string().required('Estado é obrigatório'),
        city: Yup.string().required('Estado é obrigatório'),

        know_about: Yup.string().required('Informe como soube do curso'),

        term: Yup.string().required('Informe se você autoriza o compartilhamento de seus dados.'),
      });
      data.phone = data.phone.toString().replace(/[^0-9]/g, '');

    
    




    
      await schema.validate(data, { abortEarly: false });

      


      const captchaValue =
      getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
      false;




      const newUser = await api
        .post('/signup', {
          ...data,
       
          captcha : captchaValue,
          term : data.term
        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      setSuccess(true);
      setLoading(false);
     

      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  return (
    <>
      {loading === true && (
        <Loader message={translate('Preparando seu cadastro...')} position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <p style={{color: 'rgb(0, 14, 64)'}}>
                <h2 className="titleH2" style={{color: 'rgb(0, 14, 64)'}}> {translate('Cadastro realizado com sucesso!')}</h2>
                <br />
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Link to="login">
                  <button className="defaultButton" type="button">
                    Acessar
                  </button>
                </Link>
                </div>
                <br />
                {translate('Aproveite e indique para seus amigos!')}
                <Whatsapp
                  text={`${translate('Olá! Acabei de me inscrever no')} ${authTitle}. ${translate('Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link:')} ${urlWebsite}`}
                  number=""
                  message={translate('Compartilhe!')}
                />
              </p>
            }
          /> 
        ) : (<>
           
                  <Form ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR'}}>
                    <h1 style={{color: '#333',marginBottom: '20px'}}>{translate('INSCRIÇÃO GRATUITA - AULA INAUGURAL')}</h1>

    

                    <Input name="name" placeholder="Nome completo" />
                    <Input name="email" placeholder="E-mail" />

                    { withPassword &&         <Input
                      icon={FiLock}
                      name="password"
                      type="password"
                      placeholder={translate('Cadastre sua senha de acesso')}
                    />}

{ countryISO === 'BR' ? <MaskedInput
                      mask="(99) 999999999"
                      name="phone"
                      placeholder="Telefone com DDD"
                    /> : <Input  name="phone" placeholder={'Telefone'} />}
                  
              

<SelectSimple setCallback={(value) => setCountryISO(value) } options={countries} name="country" label={'Pais'}/>

{ countryISO === 'BR' ?     <SelectSimple setCallback={ (value) => {
  setStateValue(value);
}} options={states} name="state" label="Estado"/> : 

<Input  name="state" placeholder={'Província / Região da Instituição'} />}  

<Input name="city" placeholder="Cidade" />
   


 <SelectSimple

                options={[
                  { label: 'WhatsApp', value: 'WhatsApp' },
                  { label: 'Indicação', value: 'Indicação' },
                  { label: 'Instagram', value: 'Instagram' },
                  { label: 'Facebook', value: 'Facebook' },
                  { label: 'Linkedin', value: 'Linkedin' },
                  { label: 'E-mail marketing', value: 'E-mail marketing' },
                  { label: 'SMS', value: 'SMS' },
                  { label: 'Workplace', value: 'Workplace' },
                  { label: 'Google', value: 'Google' },
                  { label: 'Outros', value: 'Outros' },
            
                         
                 
                ]}
                name="know_about"
                formRef={formRef}
                label="Como ficou sabendo do evento?"
              />



<div style={{marginBottom: '10px', padding: '15px', borderRadius: '10px'}}>
<CheckboxInput
              style={{ display: 'flex'  }}
              name="term"
              unique={true}
              preText={(
                <>
                  {' '}
                  {translate('Estou ciente de que estou fornecendo ao ')}{authTitle}{translate(' e seus parceiros meus contatos gerais, os quais são dados pessoais protegidos pela Lei Geral de Proteção de Dados brasileira, para gestão de relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.')}
{' '}
                 
                </>
              )}
              options={[
                {
                  id: `Sim, eu autorizo o compartilhamento dos meus dados`,
                  label: translate('Sim, eu autorizo o compartilhamento dos meus dados'),
                  value: `Sim, eu autorizo o compartilhamento dos meus dados`,
                },
                {
                  id: `Não autorizo o compartilhamento dos meus dados`,
                  label: translate('Não autorizo o compartilhamento dos meus dados'),
                  value: `Não autorizo o compartilhamento dos meus dados`,
                },
              ]}
            />

<p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {translate('Ao me cadastrar, afirmo que li a')}
{' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {translate('Política de Privacidade')}
{' '}
                  </Link>
{' '}
                </strong>
                {translate('desta plataforma.')}
              </p>
              </div>
                    <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

                    </aside>  <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <button className="defaultButton" type="submit">
                      {translate('Finalizar inscrição')}

                  
                    </button>
                    </aside>
                  </Form>
        </>
        )}
      </aside>
    </>
  );
};

export default FormContainer;

import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

const fadeFromRight = keyframes`
from {
  opacity: 0;
  transform:translateX(-50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

interface IBackground {
  background?: string;
}

export const Container = styled.div<IBackground>`
  min-height: 70vh;
  display: flex;
  padding-top: 100px;
  justify-content: flex-start;
  align-items: center;
  background: none;
  flex-direction: column;

  > div.itemName {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 300px;

    animation: ${fadeFromRight} 1s;

    > svg {
      font-size: 60px;
    }
    > p {
      font-size: 18px;
    }
  }

  > div.itemInfo {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: ${fadeFromLeft} 1s;
    max-width: 500px;
    padding: 30px;
    > p {
      font-size: 16px;
    }
  }
`;

export const Title = styled.h2`
  font-size: 60px;
  font-weight: bold;
  text-align: center;

  @media (max-width: 750px) {
    font-size: 42px;
  }
`;

export const AnimatedDiv = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  background: none;
  padding: 40px 0px;
  animation: ${fadeFromLeft} 1s;

  form {
    margin: 30px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4edef;
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4edef')};
      }
    }
  }

  > a {
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none;

    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#ff9000')};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;

  z-index: 1;

  img {
    width: 200px;
    max-width: 300px;
    margin: 10px;
  }
  p {
    font-size: 24px;
    margin-bottom: 40px;
  }

  @media (max-width: 450px) {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }

  .blocks {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    > div {
      height: 70px;
      padding: 0 10px;
      width: auto;
      background: rgb(0, 150, 100);
      border-radius: 8px;
      margin: 10px;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 14px;
      font-weight: bold;
    }
  }
`;

export const Cards = styled.div`
  width: 100%;
  max-width: 750px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: center;
    justify-content: center;
    border: 2px solid rgb(100, 0, 200);
    border-radius: 8px;
    padding: 10px;
    transition: 0.5s all;
    cursor: pointer;

    > svg {
      font-size: 45px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 15px;
    }
    > p {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }

    &:hover {
      transform: perspective(10.5cm) rotateX(15deg) rotateY(15deg);
      border: 5px solid rgb(100, 0, 200);
    }
  }
`;

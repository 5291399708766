/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, Dispatch, useRef, useMemo, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FiArrowRight, FiArrowLeft, FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import axios from 'axios';
import pagarme from 'pagarme';
import { type } from 'os';
import { Link, useParams } from 'react-router-dom';
import api from '../../../../services/api';

import Button from '../../../../components/Button';


import { eventName, gateKey, urlWebsite } from '../../../../config';
import imgBoleto from '../../../../assets/barcode.png';

import { AnimatedDiv } from '../../styles';
import { Content } from './styles';
import { useModal } from '../../../../hooks/Modal';
import { useToast } from '../../../../hooks/Toast';

import Whatsapp from '../../../../components/Whatsapp';

import { ActiveButton, BuyContext } from '../../index';
import Loader from '../../../../components/Loader';
import format from '../../../../utils/format';
import {
  ResponseMessage,
  IResponseMessage,
} from '../../../../components/ResponseMessage';

import UpdateUserContainer from '../../../../components/UserUpdateComponent';
import { useAuth } from '../../../../hooks/Auth';
import { useLanguage } from '../../../../hooks/Language';
import { FaCreditCard, FaReceipt } from 'react-icons/fa';
import AtualizarCadastroContainer from '../AtualizarCadastro';

interface IComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
}

interface ICardProps {
  id?: string;
  card_holder_name: string;
  card_expiration_date: string;
  card_number: string;
  card_cvv: string;
  installments?: number;
  document_type: string;
}

interface IInstallment {
  label: string;
  value: string;
}

interface IParams {
  payment_hash : string;
}

const PaymentConfirmation: React.FC = () => {

  const [buyContext,setBuyContext] = useState<Record<string,any>>({});
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const[productNotFound,setProductNotFound] = useState(false);

  const {payment_hash} = useParams<IParams>();

  const { addToast } = useToast();
  const { addModal } = useModal();

  const [responseMessage, setResponseMessage] = useState(
    {} as IResponseMessage,
  );

    const {translate} = useLanguage();

  const {user} = useAuth();

  const load = async(hash) => {

    if(user){

    try{
    const paymentInfo = await api.get(`/verify-sale/${payment_hash}`);

    if(!paymentInfo){
      setProductNotFound(true);
      return;
    }

    const {payment_method_id,boleto_url,category_id, hash_link, ticket, quantity} = paymentInfo.data;

    if(payment_method_id === 2){
    setResponseMessage({
      icon: () => <FiMail />,
      active: true,
      title: translate('Inscrição realizada com sucesso!'),
      type: 'success',
      description: (
        <>
          {translate('Clique no botão abaixo para acessar o boleto.')}
          <br />
          <a href={boleto_url} title="boleto" style={{margin: '10px auto', display: 'flex', justifyContent:'center'}}>
            <Button type="button">{translate('Boleto')} </Button>
          </a>
          <br />
          {translate('Ou no link abaixo')}:
          <br />
          <a  href={boleto_url} title={translate('boleto')} style={{wordWrap: 'break-word', marginTop:'20px'}}>
           <strong>{boleto_url}</strong> 
          </a>
          <br />
          <Link style={{margin: '10px auto', display: 'flex', justifyContent:'center'}} to={hash_link && hash_link.indexOf('trilha') >= 0 ? '/trilhas' : '/login'}>
            <Button type="button">{translate('ACESSAR')} </Button>
          </Link>
        </>
      ),
    });
  }
  else{
    setResponseMessage({
      icon: () => <FiMail />,
      active: true,
      title: translate(ticket? 'Pagamento realizado com sucesso!' :'Inscrição realizada com sucesso!'),
      type: 'success',
      description: (
        <>
          {translate(ticket? ' ' : 'Sua inscrição foi confirmada!')}
          <br />
          {category_id === 1 && <>{'Seus ingressos foram enviados por email. A partir do dia 01 de novembro, você poderá consultá-los na plataforma.'}<br/></>}
         {ticket ? <><br/>
  Seu código para retirada de inscrições é: <strong>{ticket}</strong><br/><br/>
  Este código pode ser utilizado para até <strong>{quantity} inscrições</strong>.<br/><br/>

   <a  href={`${urlWebsite}/usar-codigo/${ticket}`} style={{color: '#333'}} title="Utilizar código"><strong  >Clique aqui</strong></a> para utilizar seus ingressos.<br/><br/>
  </> : <></>}
         
          {translate('Para acessar a plataforma, clique no botão abaixo')}
          <Link style={{margin: '10px auto', display: 'flex', justifyContent:'center'}} to={hash_link && hash_link.indexOf('trilha') >= 0 ? '/trilhas' : '/login'}>
            <Button type="button">{translate('ACESSAR')} </Button>
          </Link>
        </>
      ),
    });

  }

  setLoading(false);

    setReady(true);

  } catch (err) {
    
    setLoading(false);
   setProductNotFound(true);
    addToast({type:"error", title: "Erro ao identificar compra."});
    return;
  }
  

}
  }

  useEffect(() => {

    if(!payment_hash){
       window.location.href = '/planos';
       return;
    }

   
    load(payment_hash);
    setLoading(false);

  },[payment_hash])






  if(!user){
    return         <div className="onlineMessage" style={{display:'flex', width:'auto', maxWidth: '400px',margin: '0px auto', flexDirection:'column',border: '2px solid #555', borderRadius: '20px',padding: '20px',marginTop:'100px', alignItems:'center', justifyContent: 'center'}}>
         
    <h2 className="defaultMarked"><div/>{translate('Falha ao completar ação')}</h2>
    <p style={{color: '#555', textAlign: 'center',marginTop: '20px'}}>
      {translate(
        'Você precisa estar logado para finalizar a incrição.',
      )}
    </p><br/><br/>
    <Link to="/planos">
      <button className="shadowGreyButton" type="button">
        {' '}
        {translate('Voltar')}
      </button>
    </Link>
  </div>
  }

  if(productNotFound){
   return <div className="onlineMessage" style={{display:'flex', width:'auto', maxWidth: '400px',margin: '0px auto', flexDirection:'column',border: '2px solid #555', borderRadius: '20px',padding: '20px',marginTop:'100px', alignItems:'center', justifyContent: 'center'}}>
         
    <h2 className="defaultMarked">{translate('Erro ao identificar sua inscrição')}</h2>
    <p style={{color: '#555', textAlign: 'center',marginTop: '20px'}}>
      {translate(
        'Tente recarregar a página, ou entre em contato com a organização.',
      )}
    </p><br/><br/>
    <Link to="/planos">
      <button className="shadowGreyButton" type="button">
        {' '}
        {translate('Voltar')}
      </button>
    </Link>
  </div>
  }

  return (
    <>
      {loading && <Loader message={`${translate('Carregando')}...`} />}
     {ready && <AnimatedDiv visible={true}>
     <aside style={{width: '100%', maxWidth: '350px', margin: '10px auto'}}>
            <ActiveButton step={4}/>

         </aside>
        {responseMessage.active === true && (
         
          <ResponseMessage
            active
            type={responseMessage.type}
            title={responseMessage.title}
            description={<>{responseMessage.description} <p style={{padding:'20px 10px', width: '100%', textAlign:'center'}}><br/>Sua inscrição foi recebida! As hospedagens solicitadas serão confirmadas dentro de 24h. <br/><br/>Outras informações contate: hospedagens@suergs2022.com.br</p>
 </>}
          />
        )}
        
      </AnimatedDiv>}
    </>
  );
};

export default PaymentConfirmation;

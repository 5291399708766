import styled from 'styled-components';

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;

  background: #fff;
  border-radius: 15px;
  max-width: 600px;
  padding: 120px 20px;
  color: #333;

  z-index: 1;
  > div {
    display: flex;
    flex-direction: row;

    @media (max-width: 750px) {
      flex-direction: column;
      padding: 10px;
    }

    > aside {
      display: flex;
      flex-direction: column;
      width: calc(100% - 20px);
      justify-content: center;
      flex-wrap: wrap;
      max-width: 600px;
      min-width: 400px;
      >button {
        margin: 10px auto;
      }
>form{
  
      >h1 {
        color: #333;
      }
}
      @media (max-width: 750px) {
        max-width: 100%;
        min-width: 100%;
        margin-top: 0px;
      }
    }
  }
`;


export const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;

  > div {
    > svg {
      margin-right: 5px;
    }
    padding: 5px 10px;
    width: 100%;
    background: rgb(150, 0, 0);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
  }
`;
import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../config';
import { countries } from './countries';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../hooks/Language';
interface IRecoverFormData {
  [key : string] : any;
}


interface IRead {
  [key: string]: any;
}

interface IOption{
  value: string;
  label: string;
}


interface ICaptcha {
  getValue(): string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
const siglas = readSigla();
  const {addToast} = useToast();
  const {translate,language} = useLanguage();

  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');
  const [otherCompany, setOtherCompany] = useState('');

  const [cities,setCities] = useState<Array<IOption>>([]);
  const [stateValue,setStateValue] = useState('');
  const callCityApi = async ( value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if(response){

      const values : Array<IOption> = [];

      response.data.map( city => {
        values.push( { value: city.nome, label : city.nome});
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  },[stateValue])



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required(translate('Nome obrigatório')),
       
        email: Yup.string()
          .required(translate('Email obrigatório'))
          .email(translate('Preencha um email válido')),
          
        phone: Yup.string()
          .required(translate('Telefone com DDD é obrigatório')),
         country: Yup.string()
          .required(translate('País é obrigatório')),
          term: Yup.string()
          .required(translate('Informe se deseja receber informativos e e-mails da Faculdade Moinhos de Vento')),
      
      });
      data.phone = data.phone.toString().replace(/[^0-9]/g, '');

    
    


      data.email = data.email ? data.email.trim().toLowerCase() : '';

    
      await schema.validate(data, { abortEarly: false });

      


      const captchaValue =
      getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
      false;




      const newUser = await api
        .post('/subscribe-lead', {
          ...data,
          project_id : 'hmv',
          url: '/',
          captcha : captchaValue,
          term : data?.term?.[0]
 
        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      setSuccess(true);
      setLoading(false);
     

      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: translate('Erro de validação'),
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  return (
    <>
      {loading === true && (
        <Loader message={translate('Preparando...')} position="fixed" />
      )}

      <aside style={{width: '100%', display:'flex'}}>
        {success ? (<>
           
          <div className="titleCapa2" style={{position: 'relative', color: '#593484', textAlign:'center', margin: '10px', marginTop:'20px', justifyContent:'center', alignItems: 'center' }}  >

          <h2 style={{fontSize:'20px', color: '#593484'}}>{translate('Obrigado!')}</h2>
<p style={{fontSize:'14px', color: '#593484'}}>{translate('Em breve você receberá mais informações sobre a Curso de Extensão: Pensando o AVC fora do Vaso')}</p>
 
<br />
<p style={{fontSize:'14px', color: '#593484'}}>{translate('Aproveite e divulgue para seus amigos!')}</p>

<aside style={{ width: '100%', display:'flex', justifyContent: 'center'}}>
<Whatsapp
                  text={`${translate('Olá! Quer saber mais sobre o')} ${authTitle}. ${translate('Acesse o link:')} ${urlWebsite}`}
                  number=""
                  message={translate('Compartilhe!')}
                />
                </aside>
</div>

           
             
               
               
            
       </>
       
        ) : (<>
           
   

                  <Form ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR'}}>
                  <h2 style={{fontSize:'20px', color: '#593484'}}>{translate('Preencha para receber notificações')}</h2>      
                  <SelectSimple setCallback={(value) => setCountryISO(value) } options={countries} name="country" label={'Pais'}/>

                    <Input name="name" placeholder="Nome completo" />
                    <Input name="email" placeholder="E-mail" />
 { countryISO === 'BR' ? <MaskedInput
                      mask="(99) 999999999"
                      name="phone"
                      placeholder="Telefone com DDD"
                    />  : <Input
                  
                    name="phone"
                    placeholder="Telefone"
                  />}
                 
                    
 
<CheckboxInput
              style={{ display: 'flex', maxWidth: '350px',  }}
              name="term"
              unique={true}
              preText={(
                <>
                  {' '}
                  {translate('Estou ciente de que estou fornecendo ao ')}{authTitle}{translate(' e seus parceiros meus contatos gerais, os quais são dados pessoais protegidos pela Lei Geral de Proteção de Dados brasileira, para gestão de relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.')}
{' '}
                 
                </>
              )}
              options={[
                {
                  id: `Concordo em receber informativos e e-mails da Faculdade Moinhos de Vento`,
                  label: translate('Concordo em receber informativos e e-mails da Faculdade Moinhos de Vento'),
                  value: `Concordo em receber informativos e e-mails da Faculdade Moinhos de Vento`,
                },
                {
                  id: `Não concordo em receber informativos e e-mails da Faculdade Moinhos de Vento`,
                  label: translate('Não concordo em receber informativos e e-mails da Faculdade Moinhos de Vento'),
                  value: `Não concordo em receber informativos e e-mails da Faculdade Moinhos de Vento`,
                },
              ]}
            />
            
     
                    <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

                    </aside>  <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <button className="defaultButton" type="submit">
                      {translate('Enviar')}

                  
                    </button>
                    </aside>
                  </Form>
        </>
        )}
      </aside>
    </>
  );
};

export default FormContainer;

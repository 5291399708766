import userEvent from '@testing-library/user-event';
import React, { useState, useEffect } from 'react';
import { IconType } from 'react-icons';
import { FaEye } from 'react-icons/fa';
import { FiLoader } from 'react-icons/fi';
import { useAuth } from '../../../../../hooks/Auth';
import { useModal } from '../../../../../hooks/Modal';
import { useSocket } from '../../../../../hooks/Socket';
import api from '../../../../../services/api';
import { Container } from './styles';

interface ICounterCard {
  room : number;
}

const CounterCardRoomSocket: React.FC<ICounterCard> = ({
  room

}) => {
  const [counter, setCounter] = useState('0');
  const [loading, setLoading] = useState(false);

  const {socket} = useSocket();

const {user} = useAuth();
const {addModal} = useModal();


  useEffect(() => {

    socket.on('usersRoomList',(data) => {


     addModal({ title: 'Lista de participantes', content : <> {data && Object.keys(data).map( key => <p>{data[key]}</p>)} </>});
    })
    
    socket.on('usersRoomCount',(data) => {

      setCounter(data);
    })
  }, []);

  useEffect(() => {
    const item = setInterval(() => {
       if(room > 0){
     socket.emit('getRoomReport',{room});
       }
     },2000)
 
     return () => clearInterval(item);
       },[room])


  const getRoomList = (roomNumber : Number) => {

    socket.emit('getRoomList',{room: roomNumber});
  }

  return (
    <>
      { user && (user.profile === 1 || user.profile > 2) && <div onClick={() => getRoomList(room)} style={{margin: '10px', padding: '5px',  display: 'flex', alignItems:'center', justifyContent: 'center'}}><div><FaEye/></div><label style={{marginLeft: '5px'}}>{counter || '0'}</label></div>}
    </>
  );
};
export default CounterCardRoomSocket;

import React, { Dispatch, useRef, useCallback, useState } from 'react';

import { Form } from '@unform/web';
import {
  FiMail,
  FiLock,
  FiUser,
  FiArrowRight,
  FiArrowLeft,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FaArrowLeft } from 'react-icons/fa';
import { ISigla, readSigla } from './siglas';
import { useAuth } from '../../hooks/Auth';
import Input from '../../components/Forms/Input';
import MaskedInput from '../../components/Forms/MaskedInput';
import { useToast } from '../../hooks/Toast';
import { AnimatedDiv, LoginContent } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';
import SelectSimple from '../../components/Forms/SelectSimple';

import Button from '../../components/Button';

import api from '../../services/api';
import TextareaInput from '../../components/Forms/TextareaInput';
import Loader from '../../components/Loader';
import {
  ResponseMessage,
  IResponseMessage,
} from '../../components/ResponseMessage';
import FormContainer from './FormContainer';

interface IRead {
  [key: string]: any;
}

interface SignInData {
  email: string;
  password: string;
}

interface LoginProps {
  [key: string]: string;
}

const SignupCortesiasAddCode: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  return (
    <AnimatedDiv visible>
      <LoginContent>
        {loading && <Loader message="Carregando" />}
        {success ? (
          <ResponseMessage
            active
            type="success"
            title="Sucesso"
            description={
              (<p>
                Cadastro realizado com sucesso!
                <br />
                <Link to="/login">
                  <Button style={{ width: '100%' }} type="button">
                    Acessar
                  </Button>
                </Link>
              </p>)
            }
          />
        ) : (
          <div>
            {' '}
            <aside>
              <FormContainer/>
            </aside>
          </div>
        )}
      </LoginContent>
    </AnimatedDiv>
  );
};

export default SignupCortesiasAddCode;

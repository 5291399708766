import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Container, CertificateLine ,Error} from './styles';
import Input from '../../components/Forms/Input';
import { useToast } from '../../hooks/Toast';
import { Button } from '../../components/Button/styles';
import api from '../../services/api';
import Loader from '../../components/Loader';
import { hash, urlWebsite } from '../../config';
import CheckboxInput from '../../components/Forms/CheckboxInput';
import * as Yup from 'yup';
import { useLanguage } from '../../hooks/Language';
import { useAuth } from '../../hooks/Auth';
import getValidationErrors from '../../utils/getValidationErrors';
import { MdError } from 'react-icons/md';
interface ICertificates {
  certificate_id_response: { title: string };
  hash: string;
}

interface IOptions {
id : string;
value: string;
label : string;


}




interface IAnswers {
  id : string;
  value: string;
  label : string;
}

interface IQuestion {
  title : string;
  options: Array<{ question : string, value : string}>;
  correct : string;
}

interface IQuestions {
  title : string;
  questions : Array<IQuestion>
}

interface IResponse {
  number : number;
  answers : Array<string>;
}

interface ICertificateUser {
  id : string;
  answers : Array<Record<string,any>>;
  hash : string;
  certificate_id_response : {
    proof : IQuestions
  }
}


const Certificados: React.FC = () => {
  const [certificates, setCertificates] = useState<Array<ICertificates>>([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const {translate} = useLanguage();
  const {user} = useAuth();
  const [success, setSuccess] = useState(false);
  const [getNumber, setGetNumber] = useState(0);
  const[emailInfo,setEmailInfo] = useState('');
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);

const [certificate,setCertificate] = useState<ICertificateUser>({} as ICertificateUser);

  const verify = async data => {
    setLoading(true);
    if (!data.email) {
      setLoading(false);
      return addToast({ title: 'Preencha o campo email', type: 'error' });
    }
    try {
    const { email } = data;

    const response = await api.post(`/verify-certificates`, { email });

    if (!response) {
      setLoading(false);
      return addToast({
        title: 'Nenhum certificado encontrado',
        type: 'error',
        description: 'Verifique se inseriu o email correto ou não há espaços em seu email'
      });
    }

    setEmailInfo(email);

    setCertificates(response.data.content);

      setSuccess(true);
  
    setActive(true);
    setLoading(false);
  } catch (err) {
    
    setLoading(false);
    return addToast({
      title: 'Nenhum certificado encontrado',
      type: 'error',
      description: 'Verifique se inseriu o email correto ou não há espaços em seu email'
    });
  }
  };

  const [questions,setQuestions] = useState<IQuestions>({} as IQuestions); 

  const resetVerify = () => {
    setCertificates([]);
    setActive(false);
  };

  const checkAnswers = (data) => {

    const response : IResponse = {
      number : 0,
      answers : []
    }

    let x = 1;
 
    certificate?.certificate_id_response?.proof?.questions.map((question) => {
      
      if(data[`p${x}`] && data[`p${x}`][0] === question.correct){response.number = response.number + 1;}
      response.answers.push(data[`p${x}`][0]);

      x = x+1;

    })

    return response;


  }

  const checkPerguntas = async (data) => {
  
   
    setLoading(true);

 

    try {
      formRef.current?.setErrors({});

   


     

      /* const response = await api.post(`/signup`, {
        ...data
      });*/

    
      setLoading(false);

      let answers = checkAnswers(data);


    
      if(answers.number >= 3){

        await api.post(`/confirmar-certificado`, {id : certificate?.id,  answers, email:emailInfo});
        setGetNumber(answers.number);
      setSuccess(true);
      window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificados/${certificate?.hash}`;
    }
    else{
      addToast({type:'error', title:'Você precisa acertar ao menos 3 das 4 perguntas. Tente novamente.'});
      return;
    }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);
        setLoading(false);
        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao acessar seu certificado',
      ]);
      setLoading(false);
    }
    setLoading(false);
  };

const readQuestions = useCallback(() => {
  const questions : Array<JSX.Element> = [];
  let x = 1;
  certificate.certificate_id_response.proof.questions.map(( question ) => {

    const answers : Array<IOptions> = [];

    question?.options?.map(( option ) => {
      answers.push({
        id: option.question,
        value: option.value,
        label: option.question,
      });

      
    });

    questions.push(<CheckboxInput unique name={`p${x}`} preText={<>{`${x}. `}<p dangerouslySetInnerHTML={{__html:question.title }}/></>} options={answers}/>);
    x = x+ 1;
  });

  return questions;
},[certificate]);
    

const handleCertificate = (e,cert) => {
e.preventDefault();



  window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificados/${cert.hash}`;


  

}

  return (
    <Container>
     
      {!active && (
        <Form style={{ textAlign: 'center' }} onSubmit={verify} ref={formRef}>
          <h2 style={{ marginBottom: '20px' }}>Visualizar meus certificados</h2>
          <Input placeholder="Insira seu email" name="email" type="text" />
          <button className="defaultButton" style={{ margin: '5px auto', width: '100%' }} type="submit">
            Verificar
          </button>
        </Form>
      )}
      {active && success && (
        <>
          <h2 style={{ marginBottom: '20px' }}>Meus Certificados</h2>
          <div>
            {certificates && certificates.length > 0 ? (
              certificates.map(cert => (
                <CertificateLine>
                  <a onClick={(e) => handleCertificate(e,cert)}
                    href={'#'}
                    target="_BLANK"
                  >
                    <button className="defaultButton"  type="button">Visualizar</button>
                  </a>
                  <p>{cert?.certificate_id_response?.title || ''}</p>
                </CertificateLine>
              ))
            ) : (
              <p>Nenhum certificado encontrado</p>
            )}
          </div>

          <button className="defaultButton"
            onClick={() => resetVerify()}
            style={{ margin: '5px auto', width: '100%' }}
            type="button"
          >
            Voltar
          </button>
        </>
      )}

      {loading && <Loader message="Verificando" />}
{active && !success && 
      <Form onSubmit={checkPerguntas} style={{maxWidth:'500px', width: '100%', background:'#333', textAlign:'center', padding: '50px 20px'}}>
<h2>QUESTIONÁRIO</h2>

{readQuestions()}

{errorMessage && (
              <Error>
                {errorMessage.map((item, index) => (
                  <div key={`error${index}`}>
                    <MdError />
                    {item}
                  </div>
                ))}
              </Error>
            )}
<button className="defaultButton" type="submit"> Enviar </button>


      </Form>}
    </Container>
  );
};
export default Certificados;

import React, { useEffect, useState } from 'react';
import { Wrapper, Background } from './styles';

import { Menu } from '../../../components/Menu';

import { useAuth } from '../../../hooks/Auth';
import FooterLayout from '../../Footer';
import PopupComponent from '../../../components/PopupContainer';
import { RoomChangeContainer } from '../../../components/RoomChange';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';
import Loader from '../../../components/Loader';
import api from '../../../services/api';
const menuList = [
  {
    title: 'Home',
    target: '/',
    icon: '',
    external: 2,
    fluid: 1,
    targetID: '',
    status: 2,
  },
  {
    title: 'Poster',
    target: '/poster',
    icon: '',
    fluid: 1,
    external: 1,
    targetID: '',
    status: 2,
  },
  {
    title: 'Palestras',
    target: '/dashboard',
    targetID: '',
    fluid: 1,
    icon: '',
    status: 2,
  },
  {
    title: 'Gerenciador',
    target: '/manager',
    icon: '',
    fluid: 1,
    targetID: '',
    status: 2,
  },
];

const DefaultLayout: React.FC = ({ children }) => {

  const {handleApiErrors} = useAuth();
  const [status,setStatus ] = useState(false);
  const [loading,setLoading] = useState(true);


  const load =async () => {
    try{
    const response = await api.get('/check-manager');
    if(response){
      setStatus(true)
    }
    setLoading(false);
  }
  catch(err){
 
    setStatus(false);
    setLoading(false);
    handleApiErrors(err);
  }
  }
    
  useEffect(() => {
    load();
  },[])

  return (
    <>
    {loading === true && <Loader message='Verificando permissões'/>}
    {status === false && loading === false && <h2 style={{margin: '50px 0px', padding: '20px', background: '#fff', color: '#333', fontSize:'18px', width:'100%', textAlign:'center'}}>
        Há algo de errado com suas permissões, pedimos que entre em contato com o desenvolvedor.
      </h2>}



      {status === true && <>
     <Wrapper> <Menu menuList={menuList} fixedMenu={false} />
    {children}
      <FooterLayout />
      <PopupComponent />
      <RoomChangeContainer />
      </Wrapper>
      <Particles
        params={{
          particles: {
            lineLinked: {
              enable: false,
              distance: 250
            },
            move: {
              speed: 15,
              direction: 'top-right',
              collisions: false,
              outModes: {
                default: 'out',
                left: 'out',
                right: 'out',
                bottom: 'out',
                top: 'out',
              },

             
            },
            opacity: {
              value: 0.4,
              random: false,
              animation: {
                enable: false
              }

            },
            shape: {
              type: 'circle',
              polygon: {
               
                nb_sides: 6,
                
              },
             
            },
     
            color: { value: 'rgb(171, 230, 255)' },

            number: {
              density: {
                enable: true,
                value_area: 2400,
              },
              value: 60,
            },
            size: {
              value: 2,
              random: true,
            },
          },
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 1
        }}
      /></>}
    </>
  );
};

export default DefaultLayout;

import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../config';
import { countries } from './countries';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../hooks/Language';
interface IRecoverFormData {
  [key : string] : any;
}


interface IRead {
  [key: string]: any;
}

interface IOption{
  value: string;
  label: string;
}


interface ICaptcha {
  getValue(): string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);

  const [category,setCategory] = useState('');

  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
const siglas = readSigla();
  const {addToast} = useToast();
  const {translate,language} = useLanguage();

  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');
  const [otherCompany, setOtherCompany] = useState('');

  const [cities,setCities] = useState<Array<IOption>>([]);
  const [stateValue,setStateValue] = useState('');
  const callCityApi = async ( value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if(response){

      const values : Array<IOption> = [];

      response.data.map( city => {
        values.push( { value: city.nome, label : city.nome});
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  },[stateValue])



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});

      const shape : Record<string,any> = {
        name: Yup.string().required('Nome obrigatório'),
       
        email: Yup.string()
          .required('Email obrigatório')
          .email('Preencha um email válido'),
          
        phone: Yup.string()
          .required('Telefone com DDD é obrigatório'),
          company: Yup.string().required('Informe sua instituição'),
        position: Yup.string().required('Informe seu cargo'),

        instituicao : Yup.string().required('Informe o seu Dia de Comparecimento'),
        facebook : Yup.string().required('Informe a sua Categoria de convite'),
      
      }

      if(category === 'Outro'){
        shape.extra_3 = Yup.string().required('Informe qual o seu Dia de Comparecimento');
      }
      if(position === 'Outro'){
        shape.extra_4 = Yup.string().required('Informe qual a sua Categoria de convite');
      }

      const schema = Yup.object().shape(shape);
      data.phone = data.phone.toString().replace(/[^0-9]/g, '');

    
    


      data.email = data.email ? data.email.trim().toLowerCase() : '';
      data.instituicao = data.instituicao?.[0] ? data.instituicao[0] : '';
      data.facebook = data.facebook?.[0] ? data.facebook[0] : '';
      await schema.validate(data, { abortEarly: false });

      


      const captchaValue =
      getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
      false;




      const newUser = await api
        .post('/signup', {
          ...data,
          category_id : 'Palestrante',
          term : ["Aceito"],
          product: 8,
 
          captcha : captchaValue,
 
        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      setSuccess(true);
      setLoading(false);
     

      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  return (
    <>
      {loading === true && (
        <Loader message={translate('Preparando...')} position="fixed" />
      )}

      <aside>
        {success ? (<>
       
          <p className='middleParagraphStrong'  style={{width: '100%', textAlign:'center'}} >Obrigado!<br/><br/>
Contamos com sua presença. <br/><br/>
OAB/RS
           </p>

           
             
               
               
            
       </>
       
        ) : (<>
           
  

                  <Form ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR'}}>
                   <p>
                   <strong style={{color: '#fff'}}>A OAB/RS lhe dá boas-vindas.</strong><br/><br/>

Para lhe recebermos da melhor forma, solicitamos que preencha com atenção o cadastro abaixo. <br/><br/>Lembramos que os dados coletados neste formulário são para registro da sua participação e envio de informações sobre o evento. 
<br/><br/><br/>
                   </p>

                    <Input name="name" placeholder="Nome completo" />
                    <Input name="email" placeholder="E-mail" />

                    <Input name="company" placeholder="Entidade / Empresa" />
                    <Input name="position" placeholder="Cargo" /> 
 <MaskedInput
                      mask="(99) 999999999"
                      name="phone"
                      placeholder="Telefone com DDD"
                    />  
<h3 style={{fontSize: '18px', color: '#fff', marginTop: '30px'}}>Dia de comparecimento</h3>
          <CheckboxInput unique setCallBack={(value) => setCategory(value)}  placeholder='Dia de comparecimento' name={"instituicao"} options={[
{ value: 'Todos os dias de evento (09 a 12 e agosto)', label: 'Todos os dias de evento (09 a 12 e agosto)', id: 'Todos os dias de evento (09 a 12 e agosto)'},

{ value: 'Prestação de Compromisso de novos(as) Advogados(as) (11.08 – tarde)', label: 'Prestação de Compromisso de novos(as) Advogados(as) (11.08 – tarde)', id: 'Prestação de Compromisso de novos(as) Advogados(as) (11.08 – tarde)'},
{ value: 'Sessão Magna em Comemoração ao Dia do Advogado(a) (11.08 – noite)', label: 'Sessão Magna em Comemoração ao Dia do Advogado(a) (11.08 – noite)', id: 'Sessão Magna em Comemoração ao Dia do Advogado(a) (11.08 – noite)'},
{ value: 'Área do expositor', label: 'Área do expositor', id: 'Área do expositor'},
{ value: 'Outro', id: 'Outro', label: 'Outro'},

          ]}/>

{ category === 'Outro' && <Input name="extra_3" placeholder="Qual?" />}

<h3 style={{fontSize: '18px', color: '#fff', marginTop: '30px'}}>Categoria de convite</h3>

<CheckboxInput unique setCallBack={(value) => setPosition(value)} placeholder='Categoria de convite' name={"facebook"} options={[

{ value: 'Autoridade', label: 'Autoridade', id: 'Autoridade'},
{ value: 'Conselheiro (a) da OAB/RS', label: 'Conselheiro (a) da OAB/RS', id: 'Conselheiro (a) da OAB/RS'},
{ value: 'Homenageado(a) / compromissando(a)', label: 'Homenageado(a) / compromissando(a)', id: 'Homenageado(a) / compromissando(a)'},
{ value: 'Amigo(a) ou familiar de homenageado(a) / compromissando(a)', label: 'Amigo(a) ou familiar de homenageado(a) / compromissando(a)', id: 'Amigo(a) ou familiar de homenageado(a) / compromissando(a)'},
{ value: 'Expositor', label: 'Expositor', id: 'Expositor'},
{ value: 'Imprensa', label: 'Imprensa', id: 'Imprensa'},
{ value: 'Outro', id: 'Outro', label: 'Outro'},

 

          ]}/>
 
 { position === 'Outro' && <Input name="extra_4" placeholder="Qual?" />}
<div style={{marginBottom: '10px', padding: '15px', borderRadius: '10px'}}>


<p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {translate('Ao me cadastrar, afirmo que li a')}
{' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {translate('Política de Privacidade')}
{' '}
                  </Link>
{' '}
                </strong>
                {translate('desta plataforma.')}
              </p>
              </div>
                    <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

                    </aside>  <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <button className="defaultButton" type="submit">
                      {translate('Enviar')}

                  
                    </button>
                    </aside>
                  </Form>
        </>
        )}
      </aside>
    </>
  );
};

export default FormContainer;

import React, { useCallback, useRef, useEffect, useState } from 'react';

import { Container, Content, Background, AnimatedDiv2 } from './styles';
import ValidateIntegration from '../../components/ValidateIntegration';

const Validate: React.FC = () => {
  return (
    <Container>
      <ValidateIntegration />
    </Container>
  );
};
export default Validate;

import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import { Link } from 'react-router-dom';
import { urlWebsite } from '../../config';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

const colorFrame = keyframes`
0% {
  font-size: 32px;
}
25% {
  font-size: 32px;
}
50%{
  font-size: 42px;
}
60%{
  font-size: 32px;
}
75%{
  font-size: 32px;
}
`;

const fadeFromRight = keyframes`
from {
  opacity: 0;
  transform:translateX(-50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

interface IBackground {
  background?: string;
}

export const Expositor = styled(Link)<IBackground>`
  width: 460px;
  height: 258px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${props => props.background || ''}) no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  transition: 0.4s all;


  > div.footerExpositor {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    z-index: 2;
    padding: 0 10px;

    > div.logoExpositor {
      width: 75px;

      background: none;

      position: relative;
      border-radius: 8px;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.7);
      transform: translateY(50%);
      border: 2px solid #fff;

      min-height: 40px;

      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      > img {
        width: 100%;
      }
    }
  }
`;

export const Feira = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: center;
    justify-content: center;
    border: 2px solid rgb(100, 0, 200);
    border-radius: 8px;
    padding: 10px;
    transition: 0.5s all;
    cursor: pointer;
    animation: ${fadeFromLeft} 0.5s;

    > img {
      width: 100%;
      max-width: 100%;
    }

    > svg {
      font-size: 45px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 15px;
    }
    > p {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }

    > .entrar {
      opacity: 0;
      background: rgba(0, 0, 0, 0.3);
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0px;
      right: 0;
      cursor: pointer;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      transform: rotateX(3deg) rotateY(3deg);
      border: 5px solid rgb(100, 0, 200);

      > .entrar {
        opacity: 1;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;

  z-index: 1;

  img {
    width: 200px;
    max-width: 300px;
    margin: 10px;
  }
  p {
    font-size: 24px;
    margin-bottom: 40px;
  }

  @media (max-width: 450px) {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }

  .blocks {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    > div {
      height: 70px;
      padding: 0 10px;
      width: auto;
      background: rgb(0, 150, 100);
      border-radius: 8px;
      margin: 10px;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 14px;
      font-weight: bold;
    }
  }
`;

export const Title = styled.h2`
  font-size: 60px;
  font-weight: bold;
  text-align: center;

  @media (max-width: 750px) {
    font-size: 42px;
  }
`;

export const Container = styled.div`
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 0 10%;

  .expositor_files {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    >a{
      display: flex;
      margin: 5px 0;
      padding: 10px;
      background: rgba(0,0,0,0.3);
      text-decoration: none;
      color: #fff;
      >svg{
        margin-right: 5px;
      }
    }
  }

  .separator {
    width: 80%;

    height: 2px;
    background: rgb(0, 102, 178);
    margin: 20px 0;
  }

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    padding: 0px;

    min-height: auto;
  }

  label {
    display: inline-block;
    background: rgb(88, 41, 93);
    padding: 10px;
    color: #fff;
    margin-bottom: 10px;
  }

  label.inverted {
    background: rgb(65, 193, 202);
    color: rgb(88, 41, 93);
  }

  > div.secondary {
    background: rgb(65, 193, 202);

    > div {
      width: 100%;
    }
  }

  > div.p50 {
    flex-direction: column;
    display: flex;
    width: 50%;
    min-height: 50vh;
    padding: 20px;
    justify-content: center;
    align-items: center;

    @media (max-width: 750px) {
      width: 100%;

      min-height: auto;
    }

    a {
      text-decoration: none !important;
      color: #fff !important;

      svg {
    color: #fff !important;
  }
    }

    h2 {
      font-size: 32px;
      margin-bottom: 20px;
      font-weight: bold;
      text-align: left;

      @media (max-width: 750px) {
        font-size: 32px;
      }
    }

    p {
      font-size: 14px;
      line-height: 1.4;

      text-align: justify;

      @media (max-width: 750px) {
        font-size: 14px;
      }

      > a {
        text-decoration: none !important;
        color: #fff !important;
      }

      > p {
        > a {
          text-decoration: none !important;
          color: #fff !important;
        }
      }
    }
  }

  div.size400 {
    max-width: 500px;
  }

  img {
    width: 100%;
  }

  .videoContainer {
    width: 100%;
  }
`;

export const ImageBackground = styled.div<IBackground>`
  position: relative;
  width: auto;
  background: url(${props => `${urlWebsite}/${props.background}`}) no-repeat;
  background-size: cover;
  background-attachment: fixed;

  width: 100%;
  min-height: 100vh;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 750px) {
  }
`;

export const ChatIcon = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  background: none;
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  font-size: 32px;
  cursor: pointer;
  z-index: 2000;

  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${colorFrame} 5s infinite;

  @media (max-width: 750px) {
  }
`;

export const Suport = styled.a`
  position: fixed;
  top: 30px;
  left: 30px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background: #fff;
  color: #333;
  font-size: 18px;
  cursor: pointer;
  z-index: 2000;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 750px) {
  }
`;

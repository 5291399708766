import styled, { keyframes } from 'styled-components';

interface IBackground {
  background?: string;
}

const fadeIn = keyframes`
  0% {
    opacity : 0;
    filter: blur(20deg);
    transform : translateY(30px);
  }

  20% {
    opacity: 1;
    filter: blur(0deg);
    transform : translateY(0px);
  }
  100% {
    opacity: 1;
    filter: blur(0deg);
    transform : translateY(0px);
  }


`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    filter: blur(0deg);
    transform : translateY(0px);
  }
  80% {
    opacity: 1;
    filter: blur(0deg);
    transform : translateY(0px);
  }
  100% {
    opacity: 0;
    filter: blur(20deg);
    transform : translateY(-30px);
  }

`;

export const Container = styled.div<IBackground>`
  min-height: 100vh;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
background: green;

.holder {
  position: absolute;
  bottom: 50px;
  left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}


.fadeIn {
  animation: ${fadeIn} 2.5s forwards;
}

.fadeOut {
  animation: ${fadeOut} 2.5s forwards;
}

.SimpleButton { 
 padding: 10px;
 margin: 10px auto;
 background: #fff;
 color: #333;
}

.lowerExample{
  background: #fff;
  margin: 5px;
  border-radius: 10px;
  color: #333;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
  padding: 10px;
}

.lower{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: rgba(255,255,255,0.9);
  color: #333;
  border-radius: 20px 20px 20px 0px;
  padding: 20px;
  min-height: 100px;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.3);



  >h2{
    font-size: 24px;
    color: #333;
    max-width: 500px;

  }

  >strong{
    margin: 3px 0px;
  }

  >strong, >p{
    font-size: 18px;
    color: #555;
    
  }
}

`;

export const Grid = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: none;

  >div {
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    border: 1px solid #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
  
  >svg{
    color: #fff;
    font-size: 24px;
  }
  }

`;

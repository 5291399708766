import React, { useCallback, useRef, useEffect, useState } from 'react';
import {format,utcToZonedTime} from "date-fns-tz";
import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../../../services/api';
import { date, hour, dateLanguage } from '../../../../utils/date';
import { Container, Sidebar, Body } from './styles';
import {addHours} from 'date-fns';
import { useToast } from '../../../../hooks/Toast';
import SelectApi from '../../core/components/Forms/SelectApi';
import { Form } from '@unform/web';
import DatePicker from '../../core/components/Forms/DatePicker';
import Button from '../../core/components/Forms/Button';
import SelectSimple from '../../core/components/Forms/SelectSimple';
import ChartBar from '../../core/components/Charts/Bar';

import {searchable} from '../Students/index';
import { apiUrl, getToken } from '../../../../config';
import downloadit from '../../../../utils/download';

interface IOptions {
  text?: string;
  label?: string;
  value: string;
}

interface IChartData {
  hora : string;
  usuarios : number;
}

const Reporter: React.FC = () => {
  const [percentage,setPercentage] = useState('0.00');
  const [active,setActive] = useState(false);
  const [lessons,setLessons] = useState<Array<IOptions>>([]);
  const [agruparPor,setAgruparPor] = useState<Array<IOptions>>([]);
  const [graphData, setGraphData] = useState<Array<IChartData>>([]);
  const [responsive,setResponsive] = useState('Sim');
  const [type,setType] = useState('students');
  const [exportType,setExportType] = useState('xlsx');

  const [indexChart,setIndexChart] = useState('hora');
  const [headerChart,setHeaderChart] = useState('usuarios'); 

  const [status,setStatus] = useState({
    room_id : 'a',
    date : new Date().toISOString(),
    period: '30',
    responsive : 'Sim'
  });

  const {addToast} = useToast();

  const callReport = async (data) => {
   
    setStatus(state => ({...state , ...data}));

    data.date = addHours(data.date, -3);
    if(data.room_id === 'a'){
       delete data.room_id;

    }
    const response = await api.post('/reporter',{...data});

    if(response){
      setIndexChart('hora');
      setHeaderChart('usuarios');

      if(status.period !== 'day'){
      response.data.map( (r,index)  => {
        const today =  new Date(response.data[index]._id);
        

        response.data[index].hora = format(today, "HH':'mm" )
      })
    }



      setGraphData(response.data);
    }


  }

  const changePeriod = (value) => {
    setStatus(state => ({...state , period : value}));
  }


  const loadLessons = async () => {

    const response = await api.get('/lessons?limitItems=100');
    if(response){

      const data : Array<IOptions> = [{ label: 'Todos', text: 'Todos', value: 'a'}];
        response.data.rows.map( m => {
          data.push({label : m.title, text : m.title, value : m.id});
        })

        setLessons(data);

    }


  }

const callGroupReport = async (data) => {




if(!data.group){
  addToast({title: 'Selecione ao menos um agrupamento', type: 'error'});
  return;

}
console.log('passou1');
  if(data.exportType === 'xlsx'){

  if(data.type === 'students'){
 
    downloadit({ url: `/getStudentsGroup/${data.group}?hash=${getToken()}&export_type=xlsx`}); 
  }
  else if(data.exportType === 'attendances'){
    if(data.room_id !== 'a'){
      downloadit({ url: `/getParticipantsGroup/${data.group}/${data.room_id}?hash=${getToken()}&export_type=xlsx`});
    
    }
    else{
      downloadit({ url: `/getParticipantsGroup/${data.group}?hash=${getToken()}&export_type=xlsx`});
                                                    
    }
 
  }

  }
  else{


    if(data.type === 'students'){
      console.log('passou2');
     const  response = await api.get(`/getStudentsGroup/${data.group}`); 
     setIndexChart(data.group);
     setHeaderChart('count');
     setGraphData(response.data)
    }
    else if(data.type === 'attendances'){
      if(data.room_id !== 'a'){
      const  response = await api.get(`/getParticipantsGroup/${data.group}/${data.room_id}`); 
      setIndexChart(data.group);
      setHeaderChart('count');
      setGraphData(response.data)
      }
      else{
       const response = await api.get(`/getParticipantsGroup/${data.group}`);   
       setIndexChart(data.group);
       setHeaderChart('count');
        setGraphData(response.data)
      }
   
    }

   

  }

}  

useEffect( () => { 
  
  loadLessons();
setTimeout(() => {
  callReport({...status, responsive, date: new Date()});
}, 3000)

const agrupar : Array<IOptions> = [];

Object.keys(searchable).map( key => {
  agrupar.push({ label: searchable[key].label, text : searchable[key].label, value: searchable[key].column})
}) 

setAgruparPor(agrupar)
  

},[])


const startTransfer = async () => {
  const response = await api.post('/getTransferData');

  const { total, current} = response.data;
  

  const distance = total - current;
  setActive(true);

  let x = current;

  for( let x = current; x <= total; x = x + 200){

    const r = await api.post('/setTransferData', { current });

    setPercentage((((x - current)/distance)*100).toFixed(2));
if(x >= total){
  addToast({title: 'Atualização finalizada', type: 'success'});
  setPercentage('100.00');
}
  }

  

}

  return (
    <Container>
      
 <Sidebar>

   <Form onSubmit={callReport} initialData={{...status, responsive}}>
   <h2 style={{color: "#333", padding: '15px'}}>Relatórios</h2>
   <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', padding: '15px'}}>
   <div style={{width: '100%', maxWidth: '300px'}}><label>Período</label><SelectSimple setCallback={(value) => value !== status.period ? changePeriod(value) : false} label="" options={[{ label : "Diário", value : 'day'},{ label : "6 horas", value : '360'}, { label : "4 horas", value : '240'}, { label : "3 horas", value : '180'}, { label : "60 minutos", value : '60'}, { label : "30 minutos", value : '30'}, { label : "15 minutos", value : '15'}, { label : "10 minutos", value : '10'} ]}  name="period" /></div>
   <div style={{width: '100%', maxWidth: '300px'}}><label>Sala</label><SelectSimple label="" options={lessons}  name="room_id" /></div>
   <div style={{width: '100%', maxWidth: '300px', display: status.period === 'day' ? 'none' : 'flex', flexDirection: 'column'}}><label>Data</label><DatePicker name="date"/></div>
   <div style={{width: '100%', maxWidth: '300px'}}><label>Responsivo?</label><SelectSimple setCallback={(value) => { setResponsive(value) }} label="" options={[{ label : "Sim", value : 'Sim'}, { label : "Não", value : 'Não'} ]}  name="responsive" /></div>
  <div style={{width: '100%', maxWidth: '300px'}}> <Button>
     Filtrar
   </Button></div>
  
   
   </div>
   </Form>

    <h2 style={{color: "#333", padding: '15px'}}> Pesquisar por Grupos</h2>
    <aside style={{display: 'flex', alignItems: 'center', background: '#fff', justifyContent: 'center', flexWrap: 'wrap', padding: '15px', width: '100%'}}>
   <Form key="searchGroup" onSubmit={callGroupReport} style={{width:'100%'}} >
   <aside style={{width: '100%', maxWidth: '300px'}}><label>Tipo</label>
   <SelectSimple name="type" setCallback={(value) => setType(value)} label="" options={[{ label:'Inscritos', text:'Inscritos', value: 'students'}, {label: 'Participantes', text: 'Participantes', value: 'attendances'}]} />
   </aside>
    <aside style={{width: '100%', maxWidth: '300px'}}><label>Agrupar por</label>
   <SelectSimple name="group" label="" options={agruparPor} />
   </aside>
   <aside style={{width: '100%', maxWidth: '300px', display: type === 'students' ? 'none' : 'flex', flexDirection: 'column'}}><label>Sala</label><SelectSimple label="" options={lessons}  name="room_id" /></aside>
   <aside style={{width: '100%', maxWidth: '300px'}}><label>Exportar como</label>
   <SelectSimple name="exportType" setCallback={(value) => setExportType(value)} label="" options={[{ label:'Excel', text:'Excel', value: 'xlsx'}, {label: 'Gráfico', text: 'Gráfico', value: 'chart'}]} />
   </aside>

   <aside style={{width: '100%', maxWidth: '300px',display: exportType === 'xlsx' ? 'none' : 'flex',flexDirection: 'column'}}><label>Responsivo?</label><SelectSimple setCallback={(value) => { setResponsive(value) }} label="" options={[{ label : "Sim", value : 'Sim'}, { label : "Não", value : 'Não'} ]}  name="responsive" /></aside>
  


   <aside style={{width: '100%', maxWidth: '300px'}}> <Button>
     Filtrar
   </Button></aside>

   </Form></aside>

   <Button onClick={() => startTransfer()}>Atualizar dados de participantes</Button>
   <><p style={{color: '#333', padding: '15px'}}>{percentage}%</p> 
      <div style={{width: '300px', margin: '10px auto'}}>
      <div style={{ height: '20px', background:'rgb(0,150,100)', margin: '10px', width: `${percentage}%`}}/></div> </>
      
 </Sidebar>
 
 <Body size={graphData.length} responsive={responsive}>
   
  {graphData && <div style={{width: '100%', height: '50vh'}}><ChartBar indexBy={indexChart} headers={[headerChart]} data={graphData} /></div>}

   </Body> 
    </Container>
  );
};
export default Reporter;

import styled from 'styled-components';
import theme from '../../../styles/ThemeComponents';

export const Container = styled.aside`
  display: flex;
  align-items: flex-start;
  justify-content: center ;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  background: none;
  color: ${theme.input.color};
  border-radius: 0;
  border-bottom: ${theme.input.border};
  padding: 10px 16px;
  position: relative;
background: none !important;
border: 0px !important;
  > div {
    width: 80%;
    max-width: 350px;
    font-size: 14px;
    padding: 16px;
    text-align:left;
>label{
  
}
  }

  > label {
 
    margin-top: 0px;
    border-radius: 0px;

    margin-right: 16px;
    display: flex;
    text-align:left;
    align-items: center;
    font-size: 14px;
    > input {
      width: 25px;
      height: 25px;
      margin: 5px;
    
    }
    > p {
      font-size: 14px;
      
    }
  }
`;

import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.png';

import { authTitle, logo } from '../../config';
import { Container, Content, Background, AnimatedDiv } from './styles';
import Input from '../../components/Forms/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import Whatsapp from '../../components/Whatsapp';

interface SignInData {
  email: string;
  password: string;
}

const SignInExpositor: React.FC = () => {

  const { signInAdmin } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: SignInData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Preencha um email válido'),
        });

        await schema.validate(data, { abortEarly: false });

        let { email, password } = data;
        email = email?.trim()?.toLowerCase();

        await signInAdmin({ email  , password });
        window.location.href = '/area-expositor';
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login',
        });
      }
    },
    [signInAdmin, addToast],
  );

  return (
    <Container>
      <Content>
        <AnimatedDiv>
          <img src={logo} alt="Login" style={{margin:'10px auto'}} />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input icon={FiMail} name="email" placeholder="E-mail" />
            <Input
              icon={FiLock}
              name="password"
              type="password"
              placeholder="senha"
            />
            <button style={{marginTop:'10px'}} className="defaultButton" type="submit">Entrar</button>
            <aside style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
  
  <Whatsapp number="555181476007" message="Suporte técnico" text={`Olá, estou no ${authTitle}`}/> 
  </aside>  
          </Form>
        </AnimatedDiv>
      </Content>
    </Container>
  );
};
export default SignInExpositor;

import { format, parseISO, addHours, getHours} from 'date-fns';
import {utcToZonedTime } from 'date-fns-tz';
import pt from 'date-fns/locale/pt-BR/index.js';
import en from 'date-fns/locale/en-US';
import { useLanguage } from '../hooks/Language';

export const date = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);

      const zonedDate = utcToZonedTime(dateValue, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, 'dd/MM/yyyy', { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};


export const simpleDotDate = (dateValue: string, language = 'ptBr'): string => {
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
      const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, language === 'en' ? 'MM.dd' : 'dd.MM', { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};


export const dateSimple = (dateValue: string): string => {
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
      const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, 'dd/MM', { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const hour = (dateValue: string, language = 'ptBr'): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
     

      const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, language === 'en' ? "HH':'mm" : "HH'h'mm", { locale: language === 'en' ? en : pt });

    if(formattedDate.indexOf('h00') >= 0){
      formattedDate = formattedDate.substring(0,3);

    }
  } catch (err) {
    return '';
  }
  return formattedDate;
};


export const dateLanguage = (dateValue: string, language = 'ptBr'): string => {

 


  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
      const zonedDate = utcToZonedTime(parsedDate,  'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd 'de' MMMM '-' EEEE", {
      locale: language === 'en' ? en : pt,
    });
  } catch (err) {
    return '';
  }

  formattedDate = formattedDate.replace('março','Março');

 


  return formattedDate;
};
export const simpleDate = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
      const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd'.'MM", {
      locale: pt,
    });
  } catch (err) {
    return '';
  }
  return formattedDate;
};
